import SortAndFilterEnemies from './SortAndFilterEnemies.jsx';

export default function EnemyRow (props) {
    let enemy = props.enemy;
    let enc = props.enc;
    let xp = enemy.entity?.xp || 0;
    let quantity = enemy.quantity || 0;
    return (
        <tr>
            <td>
                <select
                    data-encounterid={enc._id}
                    data-enemyid={enemy._id}
                    value={enemy.docId || ""}
                    onChange={props.setEnemyDoc}
                >
                    <option value="">none</option>
                    {props.campaign.docs.map(doc => {return(
                        <option
                            value={doc._id}
                            key={doc._id}
                        >
                            {doc.name}
                        </option>
                    )})}
                </select>
            </td>
            <td>
                <SortAndFilterEnemies
                    campaign={props.campaign}
                    enc={props.enc}
                    enemy={props.enemy}
                    setEnemyEntity={props.setEnemyEntity}
                />
            </td>
            <td>
                <input
                    type="number"
                    step={1}
                    min={0}
                    value={quantity}
                    data-encounterid={enc._id}
                    data-enemyid={enemy._id}
                    onChange={props.setEnemyQuantity}
                />
            </td>
            <td>
                {xp?.toLocaleString()}
            </td>
            <td>
                {enemy?.xp.toLocaleString()}
            </td>
            <td>
                <button
                    className="delete"
                    data-encounterid={enc._id}
                    data-enemyid={enemy._id}
                    onClick={props.deleteEnemy}
                >
                    x
                </button>
            </td>
        </tr>  
)}
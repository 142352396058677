import React from "react";
import RegisterBox from '../Components/RegisterBox.jsx';
import Footer from '../Components/Footer.jsx';

class Register extends React.Component {
    constructor (props){
        super(props)
        //console.log(props)
        this.outcome = this.props.match.params.Message
        //console.log(this.props.match.params.Message)
        this.state = {
            
        }
    }

    render() {
        document.title = ['Register', 'SGS'].join(' - ');
        return (
            <div className="container">
                <h1>Register</h1>
                <p>We recommend <a href='https://www.useapassphrase.com/' target='_blank'>using a passphrase.</a></p>
                <p>We also recommend using a password manager, such as Bitwarden or Lastpass.</p>
                <RegisterBox />
                <Footer/>
            </div>
        );
    }
};

export default Register;
import React from 'react'
import Footer from '../Footer.jsx';

class RulesTab extends React.Component{
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        
        return(<div>
            <Footer/>
        </div>)
    }
}

export default RulesTab;
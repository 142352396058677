import React from "react";

function IconSelector(props) {
    props = props.props;
    return(
        <>
            {props.type === 'draft' && '📝'}
            {props.type === 'mod' && '🎛️'}
            {props.type === 'game' && '🌎'}
            {props.type === 'gm' && '🔒'}
            {props.type === 'campaign' && '🧭'}
            {props.type === 'list' && '📃'}
            {props.type === 'player' && '👤'}
            {!props.iconOnly && props.type}
        </>
    )
}

function DocTypeButton(props) {
    let target = '/' + props.type + 's';
    if(props.iconOnly === true) return(
        <span>
            <IconSelector props={props} />
        </span>
    )
    return (
        <a href={target} title={props.type}>
            <IconSelector props={props} />
        </a>
    )
}

export default DocTypeButton
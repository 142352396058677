import React from 'react';
import UserInline from '../UserInline.jsx';
import UserSelector from '../UserSelector.jsx';
import UserDataCache from '../UserDataCache.jsx';

class EditorsTab extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            addUserModal: false,
            removeModal: false,
        }
    }

    confirmRemoveEditor = (editor) => {
        if(!window.confirm('Do you really want to remove '
            + editor.username + ' from this doc?')) return;
        this.removeEditor(editor._id);
    }

    removeEditor = (editorid) => {
        this.props.sendMessage({
            operation: 'remove doc editor',
            docid: this.props.doc._id,
            editorid: editorid,
        })
    }
    
    openModal = () => {
        this.setState({
            addUserModal: true
        });
    }
    
    addEditor = (user) => {
        // console.log('add ' + user._id);
        this.props.sendMessage({
            operation: 'add doc editor',
            docid: this.props.doc._id,
            editorid: user._id,
        })
    }

    closeModal = () => {
        this.setState({
            addUserModal: false,
        });
    }

    render() {
        let doc = this.props.doc;
        // console.log(doc.editors);
        let canEditUsers = !!this.props.doc.owner
            && !!UserDataCache.getTokenObject()?.user._id
            && this.props.doc.owner?._id
            === UserDataCache.getTokenObject()?.user._id;
        // console.log(doc?.owner);
        return(<div>
        <h3>Doc Owner</h3>
        {!!doc?.owner
            && <UserInline user={doc?.owner} />}
        <h3>Doc Editors</h3>
        <ul>
            {doc?.editors?.map((editor) => {
                return(
                    <li key={editor._id}>
                        <UserInline user={editor} key={editor._id}/>
                        {canEditUsers
                            && this.state.removeModal
                            && <button 
                            className="delete"
                            onClick={() => this.confirmRemoveEditor(editor)}>x</button>}
                    </li>
            )})}
            {canEditUsers && <li>
                <button onClick={this.openModal}>Add an Editor</button>
            </li>}
            {canEditUsers && <li>
                <button
                    onClick={() => this.setState({
                        removeModal: !this.state.removeModal
                    })}
                >
                    Remove an Editor
                </button>
            </li>}
        </ul>
        {canEditUsers &&
        <UserSelector 
            closeModal={this.closeModal}
            addUser={this.addEditor}
            doc={doc}
            state={this.state}
            setState={this.setState}
        />}
        </div>)
    }
}

export default EditorsTab;
/**
 * Shows a numerical input to adjust the index of the stat in the array.
 * @param {Object} doc the document
 * @param {String} groupName the name of the group the stat is in
 * @param {Object} stat the stat object we're editing
 * @param {Number} startingIndex the original index of the stat  
 * @param {Function} sendMessage the sendMessage method that allows us to call socket server API methods
 */
export default function SetStatIndex(props) {
    // let doc = props.doc;
    let stat = props.stat;
    let groupName = props.groupName;
    let startingIndex = props.startingIndex;
    const setIndex = (e) => {
        let statName = e.target.dataset.statname;
        let newIndex = e.target.dataset.newindex;
        let oldIndex = e.target.dataset.oldindex;
        // console.log(statName, oldIndex, newIndex);
        let statid = e.target.dataset.statid;
        props.sendMessage({
            operation: 'set stat index',
            statName,
            statGroup: groupName,
            statid,
            newIndex: newIndex,
            oldIndex: oldIndex,
        });
    }
    if(!stat.editable) return (
        <td></td>
    );
    return(
        <td>
            <button
                onClick={setIndex}
                data-newindex={startingIndex - 1}
                data-statname={stat.name}
                data-statid={stat._id}
                data-oldindex={startingIndex}
                >⯅</button>
            <button
                onClick={setIndex}
                data-newindex={startingIndex + 1}
                data-statname={stat.name}
                data-statid={stat._id}
                data-oldindex={startingIndex}
            >⯆</button>
        </td>
    )
}
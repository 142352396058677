import React from 'react'
import { capitalize } from '../TextUtils.js';
import DocSelector from './DocSelector.jsx';

class RelationshipsTab extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            addDocModal: false,
            removeParentModal: false,
        }
    }

    removeParentDoc = (doc) => {
        console.log('removeParentDoc', doc);
        this.props.sendMessage({
            operation: 'remove dependency',
            target: doc._id,
            targetName: doc.name,
        })
        // console.log('RPD 2');
        this.setState({
            removeParentModal: false,
        })
        // console.log('RPD 3');
    }

    confirmRemoveParentDoc = (doc) => {
        console.log('confirmRemoveParent');
        if(!window.confirm('Do you really want to remove the dependency '
            + doc.name + ' from this doc?')) return;
        // console.log('remove doc ' + doc._id);
        this.removeParentDoc(doc);
    }

    addParentDoc = (doc) => {
        console.log('add' + doc._id);
        // let id = doc._id;
        this.props.sendMessage({
            operation: 'add dependency',
            target: doc._id,
            targetName: doc.name,
        });
        this.setState({
            removeParentModal: false,

        })
    }

    openModal = (e) => {
        let target = e.target.dataset.target;
        this.setState({
            [target]: true,
        })
    }
    closeModal = () => {
        this.setState({
            addDocModal: false,
            removeParentModal: false,
        });
    }
    toggleaddDocModal = () => {
        this.setState({
            addDocModal: !this.state.addDocModal
        })
    }
    toggleRemoveMode = () => {
        this.setState({
            removeParentModal: !this.state.removeParentModal
        })
    }

    render() {
        // let doc = this.props.doc;
        // console.log(doc?.dependencies);
        const doc = this.props.doc;
        const children = this.props.children;
        const campaigns = this.props.campaigns;
        // console.log(campaigns);
        // console.log(children);
        return(<div>
        <h3>Parent Docs</h3>
        <ul>
            {doc?.dependencies?.map((dep) => {return(
               <li key={dep._id}>
                    <a href={'/doc/' + dep._id}>
                        {capitalize(dep.type)}: {dep.name}
                    </a>
                    &nbsp;
                    {this.state.removeParentModal && 
                    <button
                        className='delete'
                        onClick={() => this.confirmRemoveParentDoc(dep)}
                    >x</button>}
                </li>
            )})}
            {doc.editable && <li>
                <button
                    onClick={this.toggleaddDocModal}
                >
                Add Parent Doc
                </button>
            </li>}
            {doc.editable && <li>
                <button
                    onClick={this.toggleRemoveMode}
                >
                Remove a Parent Doc
                </button>
            </li>}
        </ul>
        <h3>Child Docs</h3>
        <ul>
            {children?.map((child) => {return(
               <li key={child._id}>
                    <a href={'/doc/' + child._id}>
                        {capitalize(child.type)}: {child.name}
                    </a>
                </li> 
            )})}
            {(!children || children.length === 0) && <li>none</li>}
        </ul>
        <h3>Campaigns</h3>
        <ul>
            {campaigns?.map((campaign) => {return(
               <li key={campaign._id}>
                    <a href={'/campaign/' + campaign._id}>
                        {campaign.name}
                    </a>
                </li> 
            )})}
            {(!campaigns  || campaigns.length === 0) && <li>none</li>}
        </ul>
        <DocSelector
            addParentDoc={this.addParentDoc}
            doc={doc}
            state={this.state}
            setState={this.setState}
            closeModal={this.closeModal}
        />
        </div>)
    }
}

export default RelationshipsTab;
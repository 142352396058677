// import { capitalize } from '../../TextUtils';
// import Sgs from 'sgs';
import EntityCards from './Outputs/EntityCards.js';
import ItemTable from './Outputs/ItemTable.js';
import StatBlocks from './Outputs/StatBlocks.js';
import Rules from './Outputs/Rules.js';
import jsonOutput from './Outputs/jsonOutput.js';
import HtmlFormat from './Formats/HtmlFormat.js';
import MdFormat from './Formats/MdFormat.js';

const generateOutput = (options, doc) => {
    // console.log(doc)
    if(!doc) return;
    if(Object.keys(doc).length === 0) return;

    let format = HtmlFormat;
    let contentFormat = options.contentformat?.toLowerCase() || options.contentFormat?.toLowerCase();
    if(contentFormat === 'markdown'
        || options.contentFormat === 'md') {
        format = MdFormat;
    }
    else if (contentFormat === 'json') {
        return jsonOutput(doc, options);
    }
    let contentType = options.contenttype?.replace(' ', '').toLowerCase() || options.contentType?.replace(' ', '').toLowerCase();
    // console.log(contentType)
    switch(contentType) {
        case 'entitycards':
            return EntityCards(doc, options, format);
        case 'itemtable':
            return ItemTable(doc, options, format);
        case 'statblocks':
            return StatBlocks(doc, options, format);
        case 'rules':
            return Rules(doc, options, format);
        default: 
            return 'incorrect content type: ' + contentType;
    }
}

export default generateOutput;
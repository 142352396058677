import addPlus from '../addPlus.js';

export default function StatFromPrototype (doc, prototype, entity, group, format) {
    if(!prototype) return '';
    let stat = entity[group].find(x => x.name === prototype.name);
    if(!stat) return '';
    let o = '';
    o += stat.name + ' ';
    if(!!stat.skillGroup) {
        o += '(' + stat.skillGroup + ') ';
    }
    if(stat.dieSize !== 'none') {
        o += '1' + stat.dieSize;
    }
    if(!!stat.dicePool) {
        o += stat.dicePool;
    }
    if(stat.valueType === 'formula') {
        o += stat.calculated;
    }
    if(stat.valueType === 'number') {
        let skillStatName = prototype?.skillStat;
        if(!!skillStatName) {
            let skillStat = entity.primaries.find(primary => 
                primary.name === skillStatName)
                if(!!skillStat) {
                    o += addPlus(stat.number + skillStat.number, group, stat);
                }
                else {
                    o += addPlus(stat.number, group, stat);
                }
        }
        else {
            o += addPlus(stat.number, group, stat);
        }
    }
    return o;
}
import React from "react";
// import { ReactComponent as Logo } from './sgs-logo-lightbg.svg';
import { Link } from "react-router-dom";

class HeadBranding extends React.Component {
    render(){
        return (
            <div className="HeadBranding">
                <img
                src={"/sgs-logo-darkbg.svg"}
                alt="Spiral Game System logo img"
                onClick={this.props.ToggleNav}
                className='mobile'
                />
                <Link to='/' className='desktop darkbg'>
                    <img
                    src={"/sgs-logo-darkbg.svg"}
                    alt="Spiral Game System logo 1"
                    />
                </Link>
                <Link to='/' className='desktop lightbg'>
                    <img
                    src={"/sgs-logo-lightbg.svg"}
                    alt="Spiral Game System logo 2"
                    />
                </Link>
            </div>
        );
    }
}

export default HeadBranding
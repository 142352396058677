import { capitalize } from '../../../TextUtils.js';
import Sgs from 'sgs';
import XpValueCalc from '../XpValueCalc.js';
import LimMultFormat from '../LimMultFormat.js';

export default function Rules (doc, options, format) {
    let output = ''
    output += format.h1.pre;
    output += doc.name;
    output += ' ';
    output += capitalize(doc.type);
    output += ' Rules';
    output += format.h1.suf;
    Sgs.groups.forEach(group => {
        output += format.h2.pre;
        output += capitalize(group);
        output += format.h2.suf;
        output += format.ul.pre;
        if(group !== 'skills') {
            doc[group]
            ?.sort((a, b) => {return a.skillGroup > b.skillGroup })
            .forEach(stat => {
                output += format.p.pre;
                output += format.bold.pre;
                output += stat.name;
                output += format.bold.suf;
                output += format.p.suf;
                output += format.ul.pre;
                output += format.li.pre;
                output += 'Type: ' + stat.valueType;
                output += format.li.suf;
                if(group === 'primaries') {
                    let skills = doc?.skills?.filter(
                        x => x.skillStat?.toLowerCase()
                            === stat.name.toLowerCase()
                        );
                    output += format.li.pre;
                    output += 'Skills: ';
                    output += skills.length;
                    output += format.li.suf;
                }
                if(stat.offset !== 0) {
                    output += format.li.pre;
                    output += 'Offset: ' + stat.offset;
                    output += format.li.suf;
                }
                if(!!stat.formula) {
                    output += format.li.pre;
                    output += 'Formula: '
                    output += format.code.pre;
                    output += stat.formulaText;
                    output += format.code.suf;
                    output += format.li.suf;
                }
                if(!!stat.displayFormat) {
                    output += format.li.pre;
                    output += 'Display Format: ';
                    output += format.code.pre;
                    output += stat.displayFormat;
                    output += format.code.suf;
                    output += format.li.suf;
                }
                if(!!stat.displayVisibility) {
                    output += format.li.pre;
                    output += 'Display Visibility: ';
                    output += stat.displayVisibility;
                    output += format.li.suf;
                }
                output += XpValueCalc(stat, format, group);
                output += format.ul.suf;
            })
        }
        else {
            if(!doc.skills || doc.skills.length === 0) return;
            let skills = doc.skills.sort((a, b) => {
                if(a.name > b.name) return 1;
                if(a.name < b.name) return -1;
                return 0;
            })
            doc.skillGroups.forEach(groupName => {
                output += format.h3.pre;
                output += capitalize(groupName);
                output += format.h3.suf;
                skills.forEach(skill => {
                    if(skill.skillGroup.toLowerCase()
                        !== groupName.toLowerCase()) return;
                    output += format.p.pre;
                    output += format.bold.pre;
                    output += skill.name;
                    output += format.bold.suf;
                    output += format.p.suf;
                    output += format.ul.pre;
                    if(!!skill.skillStat) {
                        output += format.li.pre;
                        output += 'Stat: ';
                        output += skill.skillStat;
                        output += format.li.suf;
                    }
                    if(!!skill.displayFormat) {
                        output += format.li.pre;
                        output += 'Display Format: ';
                        output += format.code.pre;
                        output += skill.displayFormat;
                        output += format.code.suf;
                        output += format.li.suf;
                    }
                    if(!!skill.displayVisibility) {
                        output += format.li.pre;
                        output += 'Display Visibility: ';
                        output += skill.displayVisibility;
                        output += format.li.suf;
                    }
                    output += XpValueCalc(skill, format, 'skills');
                    output += format.ul.suf;
                })
            })
        }
        output += format.ul.suf;
    })
    output += format.h2.pre;
    if(doc.limitations && doc.limitations.length > 0) {
        output += 'Limits';
        output += format.h2.suf;
        output += format.ul.pre;
        doc.limitations.forEach(limit => {
            output += format.li.pre;
            output += limit.name;
            output += ': ';
            output += LimMultFormat(limit.multiplier);
            output += format.li.suf;
        })
        output += format.ul.suf;
    }
    return output;
}
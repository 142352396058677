import React from "react";
import Modal from "./Modal.jsx";
import CompareProp from "./CompareProp.js";
import "./ItemSelector.css"

export default class ItemSelector extends React.Component {
    constructor(props) {
        super(props);
        let checkedTag = window.localStorage.getItem('checkedTag');
        let filterName = window.localStorage.getItem('filterName');
        let sortKey = window.localStorage.getItem('sortKey');
        let sortDir = window.localStorage.getItem('sortDir') === 'true';
        if(!sortKey) sortKey = 'name';
        if(!sortDir) sortDir = false;
        this.state = {
            checkedTag,
            filterName,
            sortKey,
            sortDir,
        }
    }

    changedSortTag = (e) => {
        // window.sessionStorage.setItem('itemListTagFilter', e.target.value);
        let v = e.target.value;
        this.setState({ checkedTag: v });
        window.localStorage.setItem('checkedTag', v);
    }
    changedFilterName = (e) => {
        let v = e.target.value;
        this.setState({ filterName: v });
        window.localStorage.setItem('filterName', v);
    }
    clearFilter = () => {
        this.setState({ filterName: '' });
        window.localStorage.setItem( 'filterName', '' );
        document.getElementById('item-selector-filter-name-box').value = '';
    }
    changedSortKey = (e) => {
        let v = e.target.value;
        this.setState({ sortKey: v });
        window.localStorage.setItem('sortKey', v);
    }
    changedSortDir = (e) => {
        let v = e.target.value;
        if(v === 'true') {
            v = true;
        }
        else {
            v = false;
        }
        this.setState({ sortDir: v });
        window.localStorage.setItem('sortDir', v);
    }

    addItem = (e) => {
        let entityid = e.target.dataset.entityid;
        let itemid = e.target.dataset.itemid;
        this.props.sendMessage({
            operation: 'add entity item',
            entityid,
            itemid,
        });
    }
    removeItem = (e) => {
        let entityid = e.target.dataset.entityid;
        let itemid = e.target.dataset.itemid;
        this.props.sendMessage({
            operation: 'remove entity item',
            entityid,
            itemid,
        });
    }
    
    render() {
        let parentState = this.props.parentState;
        let closeModal = this.props.closeModal;
        let doc = this.props.doc;
        let entity = this.props.entity;
        // let addItem = this.props.addItem;
        // let sendMessage = this.props.sendMessage;
        // let removeItem = this.props.removeItem;

        let available = doc.entities.filter(x =>
            !entity.equipment.includes(x._id));
        if(!!this.state.checkedTag) {
            available = available.filter(x => x.tags.includes(this.state.checkedTag));
        }
        if(!!this.state.filterName) {
            available = available.filter(x => x.name.toLowerCase().includes(this.state.filterName.toLowerCase()));
        }

        return(
            <Modal
                state={parentState}
                title='Select Items'
                target='itemModal'
                closeModal={closeModal}
                closeButtonText='close'
            >
                <h4>Filter by Tag</h4>
                <label>
                    <input
                        type='radio'
                        name='itemListTagFilter'
                        checked={'' === this.state.checkedTag}
                        value=''
                        onChange={this.changedSortTag}
                    />
                    &nbsp;
                    none
                </label>
                {doc.entityTags.map(x => {return(
                    <label key = {x}>
                        <input
                            type='radio'
                            name='itemListTagFilter'
                            value={x}
                            checked={x === this.state.checkedTag}
                            onChange={this.changedSortTag}
                        />&nbsp;{x}
                    </label>
                )})}
                <h4>Filter by Name</h4>
                <label>
                    Filter: 
                    <input 
                        type='text'
                        placeholder="sword, armor, etc"
                        onChange={this.changedFilterName}
                        defaultValue={this.state.filterName}
                        id='item-selector-filter-name-box'
                    />
                        <button 
                            className="delete"
                            onClick={this.clearFilter}
                            disabled={!this.state.filterName}
                        >x</button>
                </label>
                <h4>Sorting</h4>
                <div className="inline">
                    <label>
                        <input
                            type='radio'
                            name='sortKey'
                            value='name'
                            checked={this.state.sortKey === 'name'}
                            onChange={this.changedSortKey}
                        />
                        &nbsp;
                        Name
                    </label>
                    <label>
                        <input
                            type='radio'
                            name='sortKey'
                            value='xp'
                            checked={this.state.sortKey === 'xp'}
                            onChange={this.changedSortKey}
                        />
                        &nbsp;
                        XP
                    </label>
                </div>
                <div className="inline">
                    <label>
                        <input
                            type='radio'
                            name='sortDir'
                            value={false}
                            checked={!this.state.sortDir}
                            onChange={this.changedSortDir}
                        />
                        &nbsp;
                        Ascending
                    </label>
                    <label>
                        <input
                            type='radio'
                            name='sortDir'
                            value={true}
                            checked={this.state.sortDir}
                            onChange={this.changedSortDir}
                        />
                        &nbsp;
                        Descending
                    </label>
                </div>
                <h4>Add Items</h4>
                {available
                    .sort(CompareProp(this.state.sortKey, this.state.sortDir, true))
                    .map(x => {return(
                    <button
                        onClick={this.addItem}
                        data-entityid={entity._id}
                        data-itemid={x._id}
                        key={x._id}
                        className='inlineList'
                    >
                        ➕ {x.name} ({x.xp}xp)
                    </button>
                )})}
                <h4>Remove Items</h4>
                {entity.items?.map(x => {return(
                    <button
                        onClick={this.removeItem}
                        data-entityid={entity._id}
                        data-itemid={x._id}
                        key={x._id}
                        className='delete inlineList'
                    >
                        ❌ {x.name} ({x.xp}xp)
                    </button>
                )})}
            </Modal>
        )
    }
}
import "./Spinner.css";

export default function Spinner (props) {
    let small = props.small === true ? ' small' : '';
    let fullpage = props.fullpage === true ? ' fullpage' : '';
    return(
        <div className={'spinbox' + fullpage}>
            <img
                src={"/sgs-loading.svg"}
                alt="loading"
                className={"spinner" + small}
                />
        </div>
    )
}
import React from 'react';
import UserDataCache from "../UserDataCache.jsx";
import Modal from '../Modal.jsx';
import CompareProp from '../CompareProp.js';

class DocSelector extends React.Component {
    constructor (props){
        super(props)
        this.state = {
            docs: []
        }
    }
    
    componentDidMount = () => {
        this.getDocs();
    }

    getDocs = () => {
        let exclude = [];
        exclude.push(this.props.doc?._id);
        fetch('/api/listDocs', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': UserDataCache.getAuthHeader()
            },
            body: JSON.stringify({
                alldocs: true,
                exclude: exclude
            })
        })
        .then(res => res.json())
        .then(info  => {
            // console.log(info[0].email);
            // console.log(info);
            if(!info) return;
            this.setState({
                docs: info
            })
        })
    }

    render() {
        return (
        <Modal 
            state={this.props.state}
            title='Select Doc to add'
            target='addDocModal'
            closeModal={this.props.closeModal}
            closeButtonText='close'
        >
            {/* <label>
                Filter: 
                <input name="filter" />
            </label> */}
            <ol>
                {this.state.docs
                ?.sort(CompareProp('name', false, true))
                .map((doc) => {
                    return (
                        <li key={doc._id}>
                            <button
                                onClick={() => this.props.addParentDoc(doc)}
                            >
                                {doc.name}
                            </button>
                        </li>
                    )
                })}
            </ol>
        </Modal>
    )}
}

export default DocSelector;
function poolStatusValue (entity, stat, encounterid, index) {
    if(!entity || !stat) return '';
    // console.log(entity._id, stat._id, encounter, index)
    let b = getCheckboxValues(entity, stat, encounterid, index);
    // console.log('values', b)
    let max = 0;
    if(stat.valueType === 'formula') max = stat.calculated;
    if(stat.valueType === 'number') max = stat.number;
    let percent = max > 0 ? 100 - Math.round(b / max * 100) + '%': '';
    b = b * -1;
    if(b > 0) {
        b = '+' + b;
    }
    return b + ', ' + percent;
}

function poolStatus (entity, stat, encounterid, index) {
    return <span
        className='poolStatus'
        data-statid={stat._id}
        id={getKey(entity, stat, encounterid, index) + 'poolStatus'}
    >{poolStatusValue(entity, stat, encounterid, index)}</span>
}

function getKey (entity, stat, encounterid, index) {
    // console.log (index)
    // console.log (entity._id, stat._id, encounter, index)
    let array = [encounterid, index, entity._id, stat._id];
    let key = array.join('_');
    return key;
}

function getCheckboxValues (entity, stat, encounterid, index) {
    // console.log (entity, stat, encounterid, index)
    if(!entity || !stat) return 0;
    let key = getKey(entity, stat, encounterid, index);
    let val = parseInt(window.localStorage.getItem(key));
    // console.log(key, val, window.localStorage.getItem(key));
    if(isNaN(val) || val === "NaN") {
        return 0;
    }
    return val;
}

function deltaCheckboxValues (entity, stat, change, encounterid, index) {
    let b = getCheckboxValues(entity, stat, encounterid, index);
    // console.log(b, change, b + change);
    b = b + change;
    let key = getKey(entity, stat, encounterid, index);
    window.localStorage.setItem(key, b);
    let status = document.getElementById(key + 'poolStatus');
    // console.log(status);
    if(status) {
        status.textContent = poolStatusValue(entity, stat, encounterid, index);
    }
}

function setCheckboxValues (entity, stat, newValue, encounterid, index) {
    let b = getCheckboxValues(entity, stat, encounterid, index);
    // console.log(b, newValue)
    b = newValue;
    let key = getKey(entity, stat, encounterid, index);
    window.localStorage.setItem(key, b);
    let status = document.getElementById(key + 'poolStatus');
    // console.log(status)
    status.textContent = poolStatusValue(entity, stat, encounterid, index);
}

export {
    getCheckboxValues,
    deltaCheckboxValues,
    setCheckboxValues,
    poolStatus,
    poolStatusValue,
    getKey
}
import React from "react";
import Modal from './Modal.jsx';
import UserDataCache from "./UserDataCache.jsx";

class ListTags extends React.Component {
    constructor(props) {
        super(props);
        this.state ={
            availableTags: [],
            newTag: '',
        }
    }
    getTags = () => {
        fetch('/api/getDocTags', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': UserDataCache.getAuthHeader()
            },
        })
        .then(res => res.json())
        .then(info => {
            // console.log(info);
            if(!info) return;
            let unified = info;
            console.log(this.props.doc?.tags);
            this.props.doc?.tags.forEach(tag => {
                if(!info.includes(tag)) unified.push(tag);
            })
            unified.sort();
            this.setState({
                availableTags: info,
            })
        })
    }
    openModal = (e) => {
        let target = e.target.dataset.target;
        this.setState({
            [target]: true,
        })
    }
    closeModal = (e) => {
        let target = e.target.dataset.target;
        this.setState({
            [target]: false,
        })
    }
    toggleTag = (e) => {
        let checked = e.target.checked;
        let tag = e.target.name;
        if(checked) {
            this.props.addTag(tag);
        }
        else {
            this.props.removeTag(tag);
        }
    }
    addNewTag = (e) => {
        e.preventDefault();
        // let id = this.props.doc._id;
        // console.log(e.target);
        let tag = this.state.newTag;
        this.props.addTag(tag);
        this.setState({ newTag: ''/*, tagModal: false */})
        this.getTags();
    }
    changeNewTag = (e) => {
        this.setState({ newTag: e.target.value.toLowerCase() })
    }

    render() {
        let list = this.props.tags
            ?.filter(x => !!x)
            .filter( (value, index, self) => {return self.indexOf(value) === index})
        // console.log(list);
        if((!list || list?.length === 0) && this.props.inline === true) return('none');
        if(!list || list?.length === 0) return(<ul><li>none</li></ul>);
        if(this.props.inline === true){
            return (
                list
                .map( (x, index) => {
                    return(<a href={"/tag/" + x} key={index} className="tag">{x}</a>)
                })
                .reduce((prev, curr) => [prev, ', ', curr])
            )
        }
        return (
            <ul>
                {list.map( (tag, index) => {return (
                    <li key={index}>
                        <a href={"/tag/" + tag} className="tag">{tag}</a>
                    </li>
                )})}
                {this.props.doc.editable && !!this.props.addTag && !!this.props.removeTag && 
                <li><button
                    onClick={(e) => {this.getTags(); this.openModal(e);}}
                    data-target='tagModal'
                >
                    Edit Tags
                </button></li>}
                <Modal
                    state={this.state}
                    title='Add a Tag'
                    target='tagModal'
                    closeModal={this.closeModal}
                    closeButtonText='close'
                >
                    <table><tbody>
                        {this.state.availableTags.map(at => {
                            let dHasTag = this.props.doc?.tags?.includes(at);
                            return(
                            <tr key={at}>
                                <td>
                                    <label
                                        htmlFor={at}
                                        className={dHasTag ? 'checked' : ''}
                                    >
                                        {at}
                                    </label>
                                </td>
                                <td>
                                    <input 
                                        type='checkbox'
                                        name={at}
                                        id={at}
                                        checked={dHasTag ? true : false}
                                        onChange={this.toggleTag}
                                    />
                                </td>
                            </tr>
                        )})}
                        <tr>
                            <td>
                                <form
                                    onSubmit={this.addNewTag}
                                    id='newTagForm'
                                >
                                    <label>Add a Tag:&nbsp;
                                        <input
                                            placeholder='new tag'
                                            value={this.state.newTag}
                                            onChange={this.changeNewTag}
                                        />
                                    </label>
                                </form>
                            </td>
                            <td>
                                    <input
                                        form='newTagForm'
                                        type='submit'
                                    />
                            </td>
                        </tr>
                    </tbody></table>
                </Modal>
                {(!list || list.length === 0) && <li>none</li>}
            </ul>
        )
    }
}

export default ListTags
/**
 * Sorting function for arrays of objects, usually Entities.
 * @param {string} prop the property name on the object to sort by
 * @param {Boolean} reverse should the comparison be reversed
 * @param {Boolean} insensitive should the comparison be case insensitive
 */
export default function CompareProp(prop, reverse = false, insensitive = false){
    let sign = reverse ? -1 : 1;
    return function (a, b) {
        let numerical = typeof a[prop] === 'number';
        a = a[prop];
        b = b[prop];
        if(insensitive === true && !numerical) {
            a = a.toString().toLowerCase();
            b = b.toString().toLowerCase();
        }
        if(a < b){
            return -1 * sign;
        }
        if(a > b){
            return 1 * sign;
        }
        return 0;
    }
}
import DicePool from "./DicePool/DicePool.js";

function Xp (stat) {
    let vt = stat.valueType;
    let exponent = stat?.prototype?.exponent || 2;
    if(vt === 'number' && !!stat.number) {
        let n = parseInt(stat.number);
        let sign = Math.sign(n);
        if(!n) stat.number = 0;
        // console.log(stat.offset);
        return Math.round((n + (stat.offset || 0)) ** exponent * sign);
    }
    if(vt === 'dieSize' && !!stat.dieSize) {
        // return 1000;
        // console.log(stat.dieSize)
        if(stat.dieSize === 'none') return 0;
        let size = stat.dieSize.replace('d', '');
        size = parseInt(size);
        let value = Math.round(size / 2) + 1;
        return Math.round((value + (stat.offset || 0)) ** exponent);
    }
    if(vt === 'dicePool' && !!stat.dicePool) {
        let pool = new DicePool(stat.dicePool);
        let total = Math.round(pool.average());
        let sign = Math.sign(total);
        // console.log(stat.dicePool, total);
        return Math.round((total + (stat.offset || 0)) ** exponent * sign);
    }
    if(vt === 'formula' && !!stat.formula) {
        return 0;
    }
    return 0;
}

export default Xp;
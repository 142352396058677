import React from 'react';
import './EditContentName.css';

class EditContentName extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            name: props.name
        }
    }
    handleChange = (e) => {
        let newName = e.target.value;
        this.setState({
            name: newName
        })
    }
    submitNameChange = (e) => {
        e.preventDefault();
        let name = this.state.name;
        let id = this.props.id;
        let original = this.props.name;
        let target = this.props.target;
        // console.log(name, id);
        if(name === original || !name) return;
        this.props.renameCallback(id, name, target);
    }

    render() {
        return(
            <form
                onSubmit={this.submitNameChange}
                style={{display: 'inline'}}
                >
                <input
                    value={this.state.name}
                    placeholder={this.props.name}
                    onBlur={this.submitNameChange}
                    onChange={this.handleChange}
                    style={{display: 'inline'}}
                />
                <input type="submit" style={{display: 'none'}} />
            </form>
        )
    }
}

export default EditContentName;
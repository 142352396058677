import React from 'react';
import { capitalize } from '../../TextUtils.js';
// const Sgs = require('../../../sgs');
import Sgs from 'sgs';

function statOptions (doc, groupName) {
    let label = capitalize(groupName);
    let group = groupName.toLowerCase();
    return (
        <optgroup label={label}>
            {doc[group].map((stat) => {return(
                <option key={stat._id}>{stat.name}</option>
            )})}
        </optgroup>
    )
}

function opOptions (doc, strategy){
    let label = capitalize(strategy);
    let group = Object.keys(Sgs.operations)
        .filter((v, i, s) => {
            return Sgs.operations[v].strategy === strategy
        });
    // console.log(group);
    return (
        <optgroup label={label} key={label}>
            {group.map((op) => {return(
                <option
                    key={op}
                    title={Sgs.operations[op].desc}
                    data-op={op}
                >
                    {op}
                </option>
            )})}
        </optgroup>
    )
}

export default function ExpressionNode(props){
    // console.log(props.node);
    const doc = props.doc;
    const statname = props.statname;
    const statgroup = props.statgroup;
    const op = props.node?.op || 'none';
    // const parentStrat = !!props.parent
    //     ? Sgs.operations[props.parent].strategy : '';
    const strategy = Sgs.operations[op].strategy;
    const desc = Sgs.operations[op].desc;
    const index = props.index || 0;
    const children = props.node?.children;
    const value = props.node?.value;
    const classes = 'expressionNode';
    const numerator =
        (typeof value === 'string' || value instanceof String)
        && value?.includes('/')
        ? value.split('/')[0] : undefined;
    const denominator =
        (typeof value === 'string' || value instanceof String)
        && value?.includes('/')
        ? value.split('/')[1] : undefined;
    const strategies = Object.keys(Sgs.operations)
        .map((key) => {return Sgs.operations[key].strategy})
        .filter((v, i, s) => {return s.indexOf(v) === i});
    // console.log(strategies);
    return(
        <span
            className={classes}
            data-op={op}
            >
            {!props.editable && <span>
                {op}
            </span>}
            {props.editable && <select
                value={op}
                data-index={index}
                data-field='operation'
                data-statname={statname}
                data-statgroup={statgroup}
                title={desc}
                className='opDropdown'
                onChange={props.changeFormula}
                style={{width: op.toString().length + 4 + 'ch'}}
            >
                <optgroup label='Modify'>
                    <option
                        title='wrap this operator in another'
                        data-op='wrap'>wrap</option>
                    {strategy !== 'number'
                        && strategy !== 'select'
                        && <option title='remove this operator
                            &amp; leave children'
                            data-op='unwrap'>unwrap</option>}
                    {strategy === 'array' && <option
                        title='add a child operator to this one'
                        data-op='push'
                        value='push'>add child</option>}
                    <option title='remove this operator'
                        data-op='remove'>remove</option>
                </optgroup>
                {strategies.map(x => opOptions(doc, x))}
            </select>}
            {strategy === 'binary' && 
                <span>(
                    <ExpressionNode
                        doc={props.doc}
                        statname={statname}
                        statgroup={statgroup}
                        node={children?.[0]}
                        index={index + '.' + 0}
                        parent={op}
                        changeFormula={props.changeFormula}
                        editable={props.editable}
                        />
                    {Sgs.operations[op].sep}
                    <ExpressionNode
                        doc={props.doc}
                        statname={statname}
                        statgroup={statgroup}
                        node={children?.[1]}
                        index={index + '.' + 1}
                        parent={op}
                        changeFormula={props.changeFormula}
                        editable={props.editable}
                        />
                )</span>
            }
            {strategy === 'array' &&
                <span className='opArray'>(
                    {children.map((child, i) => {
                        let level = index + '.' + i
                        return(
                        <ExpressionNode
                            doc={props.doc}
                            statname={statname}
                            statgroup={statgroup}
                            node={child}
                            index={level}
                            key={level}
                            parent={op}
                            changeFormula={props.changeFormula}
                            editable={props.editable}
                        />
                    )})}
                )</span>
            }
            {strategy === 'select' &&
                <span>(
                    {!props.editable && <span>
                        {value}    
                    </span>}
                    {props.editable && <select
                        value={value}
                        onChange={props.changeFormula}
                        data-index={index}
                        data-field='statname'
                        data-statname={statname}
                        data-statgroup={statgroup}
                        style={{width: (value?.toString().length || 5) + 4 + 'ch'}}
                    >
                        <option>none</option>
                        {statOptions(doc, 'Primaries')}
                        {statOptions(doc, 'Skills')}
                        {statOptions(doc, 'Tacticals')}
                        {statOptions(doc, 'Thresholds')}
                        {statOptions(doc, 'Pools')}
                    </select>}
                )</span>
            }
            {op === 'integer' &&
                <span>(
                    {!props.editable && <span>
                        {value}
                    </span>}
                    {props.editable && <input
                        type='number'
                        step='1'
                        style={{width: value?.toString().length
                            + 2 + 'rem'}}
                        value={value}
                        onChange={props.changeFormula}
                        data-index={index}
                        data-field='number'
                        data-statname={statname}
                        data-statgroup={statgroup}
                    />}
                )</span>
            }
            {op === 'decimal' &&
                <span>(
                    {!props.editable && <span>
                        {value}
                    </span>}
                    {props.editable && <input
                        type='number'
                        style={{width: value?.toString().length
                            + 2 +'rem'}}
                        value={value}
                        onChange={props.changeFormula}
                        data-index={index}
                        data-field='number'
                        data-statname={statname}
                        data-statgroup={statgroup}
                    />}
                )</span>
            }
            {op === 'fraction' && 
                <span>(
                    {!props.editable && <span>
                        {numerator + '/' + denominator}
                    </span>}
                    {props.editable && <input
                        type='number'
                        step='1'
                        style={{width:
                            numerator?.toString().length
                            + 2 +'rem'}}
                        value={numerator || 1}
                        onChange={props.changeFormula}
                        data-index={index}
                        data-field='numerator'
                        data-statname={statname}
                        data-statgroup={statgroup}
                    />}
                    {props.editable && '/'}
                    {props.editable && <input
                        type='number'
                        step='1'
                        style={{width:
                            denominator?.toString().length
                            + 2 +'rem'}}
                        value={denominator || 1}
                        onChange={props.changeFormula}
                        data-index={index}
                        data-field='denominator'
                        data-statname={statname}
                        data-statgroup={statgroup}
                    />}
                )</span>
            }
        </span>
    )
}
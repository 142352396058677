// import { capitalize } from '../../TextUtils';
// import Sgs from 'sgs';
import SortEntities from '../SortEntities.js';
import FilterEntities from '../FilterEntities.js';
import LimMultFormat from '../LimMultFormat.js';
import addPlus from '../addPlus.js';

export default function ItemTable (doc, options, format) {
    let cols = [
        {
            title: 'Name',
            align: ':-',
            content: e => e.name
        },
        {
            title: 'XP',
            align: '-:',
            content: e => e.xp.toLocaleString()
        },
        {
            title: 'Tags',
            align: '-',
            content: e => e.tags?.sort().join(', ')
        },
        {
            title: 'Limits, Aspects, & Flaws',
            align: '-',
            content: e => {
                let limits = e.limitations?.map(l => {
                    let lim = '🛇 ' + l.name + ' ' + LimMultFormat(l.multiplier);
                    if(!!l.notes) {
                        lim += ' (' + l.notes + ')';
                    }
                    return lim;
                })
                let aspects = e.aspects.map(a => '▲ ' + a);
                let flaws = e.flaws.map(a => '▼ ' + a);
                return limits.concat(aspects).concat(flaws).join(', ');
            }
        },
        {
            title: 'Tacticals',
            align: '-',
            content: e => {
                return e.tacticals.map(stat => {
                    let value = '';
                    switch(stat.valueType.toLowerCase()) {
                        case 'number':
                            value = stat.number;
                            break;
                        case 'diesize':
                            value = stat.dieSize;
                            break;
                        case 'dicepool': 
                            value = stat.dicePool;
                            break;
                        case 'formula':
                            value = stat.calculated;
                            break;
                        default:
                            break;
                    }
                    return stat.name + ": " + addPlus(value, 'tacticals', stat);
                }).join(', ');
            }
        },
        {
            title: 'Skills',
            align: '-',
            content: e => {
                return e.skills.map(stat => {
                    let value = '';
                    switch(stat.valueType.toLowerCase()) {
                        case 'number':
                            value = stat.number;
                            break;
                        case 'diesize':
                            value = stat.dieSize;
                            break;
                        case 'dicepool': 
                            value = stat.dicePool;
                            break;
                        case 'formula':
                            value = stat.calculated;
                            break;
                        default:
                            break;
                    }
                    return stat.name + ": " + addPlus(value, 'skills', stat);
                }).join(', ');
            }
        },
    ];
    let entities = SortEntities(options, doc.entities);
    entities = FilterEntities(options, entities);
    let output = '';
    output += format.table.pre;
    output += format.thead.pre;
    output += format.tr.pre;
    cols.forEach(col => {
        output += format.td.pre;
        output += col.title;
        output += format.td.suf;
    })
    output += format.tr.suf;
    output += format.thead.suf;
    if(options.contentFormat === 'Markdown') {
        output += format.tr.pre;
        cols.forEach(col => {
            output += format.td.pre;
            output += col.align;
            output += format.td.suf;
        })
        output += format.tr.suf;
    }
    output += format.tbody.pre;
    entities?.forEach(entity => {
        output += format.tr.pre;
        cols.forEach(col => {
            output += format.td.pre;
            output += col.content(entity);
            output += format.td.suf;
        })
        output += format.tr.suf;
    })
    output += format.tbody.suf;
    output += format.table.suf;
    return output;
}
import React from "react";
import Footer from '../Components/Footer.jsx';

class TagListPage extends React.Component{
    constructor (props){
        super(props)
        this.state = {

        }
    }
    
    render() {
        document.title = ['Tags','SGS'].join(' - ');
        return (
            <div className="container">
                <h1>Tags</h1>
                <a href="https://gitlab.com/voidspiral/sgs-web/-/issues/69">task #69</a>
                <Footer/>
            </div>
        );
    }
};

export default TagListPage;
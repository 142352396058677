import AttemptChangePortrait from "../EntityEditTab/AttemptPortraitChange.js";

export default function EntityIcon(props) {
    let entity = props.entity;
    let editable = entity.editable;
    let sendMessage = props.sendMessage;
    let url = entity.imageurl;
    // url = 'https://imgur.com/WpMjj3S.png';
    const imgurPattern = /^https:\/\/imgur.com\/\w+$/gm;
    if(url && url.match(imgurPattern)) {
        url += '.png';
    }
    if(!editable && !url) {
        return '';
    }
    if(!editable) {
        return (
            <img
                src={url}
                alt='entity portrait'
                className='portrait icon'
            />
        )
    }
    return(
        <button
            onClick={() => AttemptChangePortrait(entity, sendMessage)}
            className='icon'
        >
            {url && <img
                src={url}
                alt='entity portrait'
                className='portrait icon'
            />}
            {!url && '📸'}
        </button>
    )
}
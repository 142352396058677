import React from "react";
import Spinner from "../Components/Spinner.jsx";
import TableOfLists from "../Components/TableOfLists.jsx";
import UserDataCache from "../Components/UserDataCache.jsx";

export default class ListListPage extends React.Component {
    constructor (props){
        super(props)
        this.state = {
            lists: [],
            gettingLists: true,
        }
    }
    // initializer
    componentDidMount() {
        this.getLists();
    }
    getLists = () => {
        fetch('/api/list/getLists', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': UserDataCache.getAuthHeader()
            },
            method: "GET"
        })
        .then(res => res.json())
        .then(lists => this.setState({
            lists,
            gettingLists: false,
        }))
    }

    render() {
        document.title = ['Army Lists', 'SGS'].join(' - ');
        return(
            <div className="container">
                <h1>Army Lists</h1>
                <TableOfLists lists={this.state.lists} />
                {this.state.gettingLists && <Spinner />}
            </div>
        )
    }
}
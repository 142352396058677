import './PatronList.css'

function list(instring){
    return (
        <ul>
            {instring
                .split('\n')
                .filter(x => !!x)
                .map(x => <li key={x}>{x}</li>)}
        </ul>
    )
}

export default function PatronList (props){
    let champions = `
    Twila, Mathew 
    `;
    let galaxies = ``;
    let cyclones = ``;
    let helixes = `
    Baylen
    `;
    let spirals = ``;
    return (
        <div className='patronList'>
            <h1>Patrons</h1>
            <p>
                We need your support to keep the servers running!
                Join us at <a href="https://www.patreon.com/voidspiral" target="_blank" rel="noreferrer">https://www.patreon.com/voidspiral</a>
            </p>
            {!!galaxies && <div className="galaxy">
                <h2>Galaxy Patrons</h2>
                {list(galaxies)}
            </div>}
            {!!cyclones && <div className="cyclone">
                <h2>Cyclone Patrons</h2>
                {list(cyclones)}
            </div>}
            {!!helixes && <div className="helix">
                <h2>Helix Patrons</h2>
                {list(helixes)}
            </div>}
            {!!spirals && <div className="spiral">
                <h2>Spiral Patrons</h2>
                {list(spirals)}
            </div>}
            {!!champions && <div className="eldritch">
                <h2>Eldritch Patrons of the Dark Past</h2>
                {list(champions)}
            </div>}
            <br />
            <p>
            Join us at <a href="https://www.patreon.com/voidspiral" target="_blank" rel="noreferrer">https://www.patreon.com/voidspiral</a> 
            </p>
        </div>
    )
}
export default function OffsetCell(props) {
    const changeTacOffset = props.changeTacOffset;
    const stat = props.stat;
    const group = props.group;
    const rightType = stat.valueType !== 'formula';
    if(!stat.editable) {
        if(rightType) {
            return(<td>{stat.offset}</td>);
        }
        return(<td></td>);
    }
    if(stat.editable) {
        if(rightType) {
            return(<td>
                <input
                    type='number'
                    min='-10'
                    max='10'
                    step='1'
                    data-skillname={stat.name}
                    data-statgroup={group}
                    value={stat.offset ? stat.offset : 0}
                    onChange={changeTacOffset}
                />
            </td>);
        }
        return(<td>
            <span title="A value offset is only available on stats with a value, IE not Formulas.">none</span>
        </td>);

    }
}
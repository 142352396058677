import React from 'react';
import UserDataCache from "./UserDataCache.jsx";
import Modal from './Modal.jsx';

class UserSelector extends React.Component {
    constructor (props){
        super(props)
        this.state = {
            users: []
        }
    }
    
    componentDidMount = () => {
        // console.log('this.componentDidMount')
        this.getUsers();
    }

    getUsers = () => {
        let exclude = [];
        // console.log(this.props.doc?.editors);
        exclude = exclude.concat(this.props.campaign?.allPlayers);
        exclude.push(this.props.campaign?.gm);
        exclude = exclude.concat(this.props.doc?.editors);
        exclude.push(this.props.doc?.owner);
        exclude = exclude.filter(x => x !== undefined);
        // console.log(exclude);
        fetch('/api/listUsers', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': UserDataCache.getAuthHeader()
            },
            body: JSON.stringify({
                allusers: true,
                includeemail: true,
                exclude: exclude
            })
        })
        .then(res => res.json())
        .then(info  => {
            // console.log(info[0].email);
            // console.log(info);
            if(!info) return;
            this.setState({
                users: info
            })
        })
    }

    render() {
        // this.getUsers();
        // console.log('render')
        return (
            <Modal
                state={this.props.state}
                title='Select Doc to add'
                target='addUserModal'
                closeModal={this.props.closeModal}
                closeButtonText='close'
            >
                <h2>Select User</h2>
                <label>
                    Filter: 
                    <input name="filter" />
                </label>
                <ol>
                    {this.state.users?.map((user) => {
                        return (
                            <li key={user._id}>
                                <button onClick={() => {
                                    this.props.addUser(user);
                                    this.getUsers();
                                }} >
                                    {user.username}
                                </button>
                                &nbsp;
                                ({user.email})
                            </li>
                        )
                    })}
                </ol>
            </Modal>
        )
    }
}

export default UserSelector;
import React from 'react';
import './SaveButton.css';

export default function SaveButton(props){
    // console.log(props.isModified);
    let states = {
        'noStatus': '💾❌',
        'saved': '💾✅',
        'unsaved': '💾⚠️',
    }
    let current = 'noStatus';
    if(props.dirty === false) current = 'saved';
    if(props.dirty === true) current = 'unsaved';
    let icon = states[current];
    return (
        <div className='docSave'>
            <button onClick={() => props.sendMessage({
                operation: 'save doc',
            })}
            title={current + '\nNote: documents are automatically\nsaved on exit.'}
            >
                {icon}
            </button>
        </div>
    );
}
import React from 'react';
import UserDataCache from './UserDataCache.jsx';

const newDoc = (type, getMyDocs) => {
    let owner = UserDataCache.getTokenObject().user._id;
    fetch('/api/newDoc',
    {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': UserDataCache.getAuthHeader()
        },
        method: "POST",
        body: JSON.stringify({
            name: 'new game',
            // tags: this.getTags(),
            rules: {},
            type: type,
            owner,
        })
    })
    .then(res => res.json())
    .then(info => {
        getMyDocs();
        console.log(info);
    })
}

const newCampaign = (getGMCampaigns) => {
    let owner = UserDataCache.getTokenObject().user._id;
    fetch('/api/newCampaign',
    {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': UserDataCache.getAuthHeader()
        },
        method: "POST",
        body: JSON.stringify({
            name: 'new campaign',
            gm: owner,
        })
    })
    .then(res => res.json())
    .then(info => {
        getGMCampaigns();
        console.log(info);
    })
}

const newList = (getMyLists) => {
    let user = UserDataCache.getTokenObject().user;
    let owner = user._id;
    let ownerName = user.username;
    fetch('/api/list/newList',
    {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': UserDataCache.getAuthHeader()
        },
        method: "POST",
        body: JSON.stringify({
            owner,
            ownername: ownerName,
        })
    })
    .then(res => res.json())
    .then(info => {
        getMyLists();
        console.log(info);
    })
}

export function CreateConsole(props) {
    return (
        <div>
            <h2>Create</h2>
            <button onClick={() => newDoc('draft', props.getMyDocs)}>
                🌎New Game Doc
            </button> <button onClick={() => newCampaign(props.getGMCampaigns)}>
                🧭New Campaign
            </button> <button onClick={() => newList(props.getMyLists)}>
                📃New List
            </button>
        </div>
    )
}


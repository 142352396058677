import React from "react";
import HeadBranding from "./HeadBranding.jsx";
import UserDataCache from "./UserDataCache.jsx";
import { Link } from "react-router-dom";

const ToggleNav = () => {
    let nav = document.getElementById('nav');
    let hasShow = nav.classList.contains('show');
    if(hasShow) {
        nav.classList.remove('show');
    }
    else {
        nav.classList.add('show');
    }
}

export default function NavMenu (props) {
    let token = UserDataCache.getTokenObject();
    if (!!token) {
        return (
            <div>
                <HeadBranding ToggleNav={ToggleNav}/>
                <ul id='nav'>
                    <li><Link to="/">Dashboard</Link>
                        <ul>
                            <li><Link to={"/user/" + token.user._id}>My Account</Link></li>
                            <li><Link to="/" onClick={UserDataCache.removeToken}>logout</Link></li>
                        </ul>
                    </li>
                    <li><Link to="/docs">Docs</Link></li>
                    <li><Link to="/campaigns">Campaigns</Link></li>
                    <li><Link to="/lists">Lists</Link></li>
                    <li><Link to="/users">Users</Link></li>
                    {/* <li><Link to="/tags">Tags</Link></li> */}
                    <li><a href="https://voidspiral.gitlab.io/sgs-apps/docs/" target='_blank' rel='noreferrer'>System Rules</a></li>
                    <li><a href="https://gitlab.com/voidspiral/sgs-web" target='_blank' rel='noreferrer'>Bugs</a></li>
                    {/* <li><Link to="/status">Status</Link></li> */}
                    {UserDataCache.getTokenObject().user.userLevel === 'ADMIN' && 
                        <li><Link to="/admin">Admin</Link></li>}
                    <li><a href='/ResetRequest'>Reset Password</a></li>
                </ul>
            </div>
        );
    }
    else {
        return (
            <div>
                <HeadBranding ToggleNav={ToggleNav}/>
                <ul id='nav'>
                    <li><Link to="/register">register</Link></li>
                    <li><Link to="/login">login</Link></li>
                    <li><a href='/ResetRequest'>Reset Password</a></li>
                    <li><Link to="/Docs">Docs</Link></li>
                    <li><Link to="/campaigns">Campaigns</Link></li>
                    <li><Link to="/tags">tags</Link></li>
                </ul>
            </div>
        );
    }
}
import React from 'react'

class DocEditTab extends React.Component{
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    listDoc = () => {
        this.props.sendMessage({
            operation: 'set state',
            stateName: 'unlisted',
            stateValue: 'false',
        })
    }
    unlistDoc = () => {
        this.props.sendMessage({
            operation: 'set state',
            stateName: 'unlisted',
            stateValue: 'true',
        })
    }

    trashDoc = () => {
        this.props.sendMessage({
            operation: 'set state',
            stateName: 'trashed',
            stateValue: 'true',
        })
    }
    untrashDoc = () => {
        this.props.sendMessage({
            operation: 'set state',
            stateName: 'trashed',
            stateValue: 'false',
        })
    }
    changeDocType = (e) => {
        let docType = e.target.value;
        console.log(docType);
        this.props.sendMessage({
            operation: 'set doc type',
            docType,
        })
    }

    render() {
        let doc = this.props.doc;
        document.title = ['Edit Doc', doc.name, 'SGS'].join(' - ');
        return(
        <div>
            <h3>Edit Doc</h3>
            <ul>
                <li>
                    {!doc.editable && <span>
                        Doc Type: {doc.type}    
                    </span>}
                    {doc.editable && <label className='transparent'>
                        Doc Type: 
                        <select
                            value={doc.type}
                            onChange={this.changeDocType}
                        >
                            <option>draft</option>
                            <option>game</option>
                            <option>mod</option>
                            <option>list</option>
                            <option>gm</option>
                            <option>player</option>
                            <option>campaign</option>
                            
                        </select>
                    </label>}
                </li>
                {doc.unlisted &&
                    <li>
                        doc is unlisted.
                        <br/>
                        {doc.editable && <button onClick={this.listDoc}>
                            list doc
                        </button>}
                    </li>}
                {!doc.unlisted &&
                    <li>
                        doc is publicly listed.
                        <br/>
                        {doc.editable && <button onClick={this.unlistDoc}>
                            unlist doc
                        </button>}
                    </li>}
                {!doc.trashed &&
                    <li>
                        doc is not in the trash.
                        <br/>
                        {doc.editable && <button onClick={this.trashDoc}>
                            trash doc
                        </button>}
                    </li>}
                {doc.trashed &&
                    <li>
                        doc is in the trash.
                        <br/>
                        {doc.editable && <button onClick={this.untrashDoc}>
                            untrash doc
                        </button>}
                    </li>}
            </ul>
        </div>
        )
    }
}

export default DocEditTab;
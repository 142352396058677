import React, { createRef } from 'react'
import socketIOClient from "socket.io-client";
import UserDataCache from './UserDataCache.jsx';
import './SocketDocEdit.css'
import {
    Switch,
    Route,
    Link,
} from 'react-router-dom';
import EditContentName from "./EditContentName.jsx";
import { capitalize } from './TextUtils.js';

import SummaryTab from './SocketDoc/SummaryTab.jsx';
import RelationshipsTab from './SocketDoc/RelationshipsTab.jsx';
import StatsTab from './SocketDoc/StatsTab.jsx';
import RulesTab from './SocketDoc/RulesTab.jsx';
import EntityListTab from './SocketDoc/EntityListTab.jsx';
import EntityEditTab from './SocketDoc/EntityEditTab.jsx';
import TagsTab from './SocketDoc/TagsTab.jsx';
import EditorsTab from './SocketDoc/EditorsTab.jsx';
import DocEditTab from './SocketDoc/DocEditTab.jsx';
import PrintTab from './SocketDoc/PrintTab.jsx';

import SaveButton from './SocketDoc/SaveButton.jsx';
import Sgs from 'sgs';
import ApiTab from './SocketDoc/ApiTab.jsx';
import beep from './beep.js';

const EDIT_MESSAGE_EVENT = 'editMessageEvent';
const CHANGE_PUBLISH_EVENT = 'changePublishEvent';
const INITIAL_PAYLOAD_EVENT = 'initialPayloadEvent';

class SocketDocEdit extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            doc: {},
            activeTab: 'RelationshipsTab',
            header: 'Docs',
            hlink: '/docs',
            validated: false,
            apiKey: false,
        }
        this.docId = this.props.docId;
        this.socketRef = createRef();
    }
    
    componentDidMount() {
        // console.log("component did mount")
        const docId = this.docId;
        const connectionType = 'document';
        const hostname = window.location.hostname;
        const hostport = window.location.port;
        // console.log(hostname, hostport);
        if(hostname === "localhost") {
            this.socketRef.current = socketIOClient("http://localhost:5000", {
                query: {
                    docId,
                    connectionType,
                    token: UserDataCache.getTokenText(),
                }
            })
        }
        else {
            this.socketRef.current = socketIOClient({
                query: {
                    docId,
                    connectionType,
                    token: UserDataCache.getTokenText(),
                }
            })
        }
        // console.log(this.socketRef.current);
        // get document on initial connection
        this.socketRef.current.on(INITIAL_PAYLOAD_EVENT,
            (message) => {
                // beep(10, 440, 1, 'sawtooth')
                // console.log('initial', message);
                this.setState({ doc: Sgs.calculateAllXp(message.doc, message.validated, this.state.doc, true), validated: message.validated },
                this.determineType);
            });
        // listens for incoming messages
        this.socketRef.current.on(CHANGE_PUBLISH_EVENT,
            (message) => {
                // beep(10, 440, 1, 'sawtooth')
                // console.log('change', message);
                this.setState({ doc: Sgs.calculateAllXp(message.doc, this.state.validated, this.state.doc)},
                this.determineType);
            });
        this.GetApiKey();
    }

    componentWillUnmount() {
        // destroys the socket reference when connection closed
        this.socketRef.current?.disconnect();
    }

    // sends a message to the server that forwards it to all users in the room
    sendMessage = (request, callback = () => {}) => {
        // console.log('sendMessage');
        // beep(50, 220, 1, 'sine');
        this.socketRef.current.emit(EDIT_MESSAGE_EVENT, {
            senderId: this.socketRef.current.id,
            request: request,
        }, callback());
    };

    getTabName = (dir) => {
        dir = dir.replace(/\/+$/, "");
        // console.log(dir);
        const loc = this.props.location.pathname;
        let tabName = loc.replace(dir, '');
        if(tabName === ''){
            tabName = 'Summary';
        }
        else{
            tabName = tabName.replace('/', '');
        }
        // console.log(tabName);
        return tabName;
    }
    renameDoc = (id, name, target) => {
        this.sendMessage({
            operation: 'set doc name',
            name,
        })
    }

    determineType = () => {
        // beep(10, 880, 1, 'sawtooth');
        let doc = this.state.doc;
        // console.log('determine type', doc.type);
        if(doc && doc.type){
            let header = capitalize(doc.type);
            let hlink = '/' + doc.type + 's';
            // let docLink = `/${doc.type}/${doc._id}/`;
            // this.props.history.push(docLink);
            this.setState({
                header,
                hlink,
            })
        }
    }

    apiCallOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': UserDataCache.getAuthHeader(),
        },
        body: JSON.stringify({
            docId: this.props.docId,
        })
    }

    GetApiKey = () => {
        // console.log('doc id', this.props.docId)
        fetch('/api/getapikey', this.apiCallOptions)
        .then(res => res.json())
        .then(info => {
            // console.log('api key', info)
            this.setState({
                apiKey: info,
            })
        })
    }

    RegenerateApiKey = () => {
        fetch('/api/regenerateapikey', this.apiCallOptions)
        .then(res => res.json())
        .then(info => {
            // console.log(info)
            this.setState({
                apiKey: info,
            })
        })
    }
    
    RemoveApiKey = () => {
        fetch('/api/removeapikey', this.apiCallOptions)
        .then(res => res.json())
        .then(info => {
            // console.log(info)
            this.setState({
                apiKey: info,
            })
        })
    }

    render() {
        const doc = this.state.doc;
        const tabs = [
            'Summary',
            'Edit',
            'Stats',
            'Entities',
            // 'Entity',
            'Print',
        ]
        const dir = `/doc/${this.docId}/`;
        const tabName = this.getTabName(dir);
        return (<div className="SocketDocEdit">
            <h1><a href={this.state.hlink}>{this.state.header}</a>:&nbsp;
                    {!doc.editable && doc.name}
                    {doc.editable && <EditContentName
                        name={doc.name || this.props.initialName}
                        id={doc._id}
                        renameCallback={this.renameDoc}
                        target={doc}
                    />}
                </h1>
            <div className='docEditor'>
                <div className='docNav'>
                    {tabs.map((key) => {
                    let classes = 'button';
                    // console.log(tabName + ' vs ' + key);
                    if((tabName.toLowerCase() === key.toLowerCase())
                    || (tabName.toLowerCase() === '' && key === 'Summary')){
                        // console.log('active!');
                        classes += ' active';
                    }
                    return(
                        <Link
                            className={classes}
                            to={dir + key}
                            key={key}>
                                {key}
                        </Link>
                    )})}
                </div>
                <div className='docTab'>
                    <Switch>
                        <Route exact path={dir+'Edit'}>
                            <div>
                                <EditorsTab
                                    sendMessage={this.sendMessage}
                                    doc={doc}
                                    />
                                <hr />
                                <DocEditTab
                                    sendMessage={this.sendMessage}
                                    doc={doc}
                                    />
                                <hr />
                                <RelationshipsTab
                                    sendMessage={this.sendMessage}
                                    doc={doc}
                                    children={this.props.children}
                                    campaigns={this.props.campaigns}
                                    />
                                <hr />
                                <TagsTab
                                    sendMessage={this.sendMessage}
                                    doc={doc}
                                    />
                                <hr />
                                <ApiTab
                                    docId={this.docId}
                                    doc={doc}
                                    apiKey={this.state.apiKey}
                                    RegenerateApiKey={this.RegenerateApiKey}
                                    RemoveApiKey={this.RemoveApiKey}
                                    />
                            </div>
                        </Route>
                        <Route exact path={dir+'Stats'}>
                            <StatsTab
                                sendMessage={this.sendMessage}
                                doc={doc}
                                />
                        </Route>
                        <Route exact path={dir+'Rules'}>
                            <RulesTab
                                sendMessage={this.sendMessage}
                                doc={doc}
                                />
                        </Route>
                        <Route exact path={dir+'Entities'}>
                            <EntityListTab
                                sendMessage={this.sendMessage}
                                doc={doc}
                                />
                        </Route>
                        <Route path={dir+'Entity/:EntityId'}
                            render={(props) => 
                                <EntityEditTab
                                    {...props}
                                    doc={doc}
                                    sendMessage={this.sendMessage}
                                />}
                        />
                        <Route exact path={dir+'Print'}>
                            <PrintTab
                                sendMessage={this.sendMessage}
                                doc={doc}
                                apiKey={this.state.apiKey}
                                />
                        </Route>
                        <Route path='/'>
                            <SummaryTab
                                doc={doc}
                                children={this.props.children}
                                campaigns={this.props.campaigns}
                            />
                        </Route>
                    </Switch>
                </div>
                {doc.editable && <SaveButton
                    dirty={doc.dirty}
                    sendMessage={this.sendMessage}
                />}
            </div>
        </div>)
    }
}

export default SocketDocEdit
import React from "react";
import UserDataCache from "../Components/UserDataCache.jsx";
import "./AdminPage.css";
import prettyBytes from 'pretty-bytes';
import Footer from "../Components/Footer.jsx";

class AdminPage extends React.Component {
    constructor (props){
        super(props)
        this.state = {
            data: {},
            admin: false,
        }
    }
    
    componentDidMount(){
        const token = UserDataCache.getTokenObject();
        const isAdmin = token?.user.userLevel === 'ADMIN';
        this.setState({admin: isAdmin});
        if(isAdmin) {
            this.getAdminData();
        }
    }
    getAdminData = () => {
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': UserDataCache.getAuthHeader(),
            },
            body: JSON.stringify({
                userId: this.id,
            })
        }
        fetch('/api/getAdminData', options)
        .then(res => res.json())
        .then(info  => {
            console.log(info);
            this.setState({data: info})
        })
    }
    deleteAllEventLogs = () => {
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': UserDataCache.getAuthHeader(),
            },
            body: JSON.stringify({
                userId: this.id,
            })
        }
        fetch('/api/deleteAllEventLogs', options)
        .then(res => res.json())
        .then(info  => {
            console.log(info);
            this.getAdminData();
        })
    }

    render () {
        const token = UserDataCache.getTokenObject();
        const data = this.state.data;
        // determine editing privelages
        const isAdmin = token?.user.userLevel === 'ADMIN';
        document.title = ['Admin', 'SGS'].join(' - ');
        if (!isAdmin){ return (
            <div className="container">
                <h1>Unauthorized</h1>
            </div>
        )}
        else {
            let totalSize = 0;
            return (
            <div className="container">
                <h1>Site Administration</h1>
                <h2>General Stats</h2>
                <ul>
                    <li>Total Users: {data.users}</li>
                    <li>Logged In Users: {data.loggedInUsers}</li>
                    <li>Documents: {data.docs}</li>
                    <li>Open Documents: {data.openDocs}</li>
                    <li>Unsaved Documents: {data.unsavedDocs}</li>
                </ul>
                <h2>Doc Event Logs</h2>
                {!!data.eventStacks &&
                <div className='eventList'>
                    <button className='delete' onClick={this.deleteAllEventLogs}>Delete All Doc Event Logs</button>
                    <ul>
                        {Object.keys(data.eventStacks)
                        .sort((a,b) => {return data.eventStacks[a].size - data.eventStacks[b].size})
                        .map( key => {
                            let eventStack = data.eventStacks[key];
                            totalSize += eventStack.filesize;
                            return(
                                <li key={eventStack.docId}>{eventStack.docName}: {prettyBytes(eventStack.filesize)}</li>
                        )})}
                        <li>Total Log Size: {prettyBytes(totalSize)}</li>
                    </ul>
                    {Object.keys(data.eventStacks).map( key => {
                        let eventStack = data.eventStacks[key];
                        return(
                        <div key={eventStack.docId}>
                            <h3>{eventStack.docName} ({prettyBytes(eventStack.filesize)})</h3>
                            <pre>{eventStack.content}</pre>
                        </div>
                    )})}
                </div>
                }
                <Footer/>
            </div>
        )}
    }
};

export default AdminPage;
export default function PowerGraph (props) {
    let enc = props.enc;
    return(
    <div className="graph">
        <div
            className="good"
            style={{width: enc.percentages?.pcs + '%'}}
        >
            PCs
        </div>
        <div
            className="evil"
            style={{width: enc.percentages?.enemy + '%'}}
        >
            Enemy
        </div>
        {!!enc.percentages.diff && <>
        <div
            className="line trivial"
            style={{width: enc.percentages.trivial + '%'}}
        >Trivial</div>
        <div
            className="line easy"
            style={{width: enc.percentages.easy + '%'}}
        >Easy</div>
        <div
            className="line normal"
            style={{width: enc.percentages.normal + '%'}}
        >Normal</div>
        <div
            className="line danger"
            style={{width: enc.percentages.danger + '%'}}
        >Danger</div>
        <div
            className="line lethal"
            style={{width: enc.percentages.lethal + '%'}}
        >Lethal</div>
        
        </>}
    </div>
)}
import React from "react";

export default class TracksTable extends React.Component {
    addNewTrack = (e) => {
        this.props.sendMessage({
            operation: 'add new track',
        })
    }
    attemptRemoveTrack = (e) => {
        let trackid = e.target.dataset.trackid;
        let trackname = e.target.dataset.trackname;
        if(window.confirm('Are you sure you want to delete the track: ' + trackname + '?')) {
            this.removeTrack(trackid);
        }
    } 
    removeTrack = (trackid) => {
        this.props.sendMessage({
            operation: 'remove track',
            trackid,
        })
    }
    attemptRenameTrack = (e) => {
        let trackid = e.target.dataset.trackid;
        let trackname = window.prompt("New Track Name");
        // console.log(trackid, trackname);
        this.renameTrack(trackid, trackname)
    }
    renameTrack = (trackid, trackname) => {
        this.props.sendMessage({
            operation: 'rename track',
            trackid,
            trackname,
        })
    }

    addNewLayer = (e) => {
        let trackid = e.target.dataset.trackid;
        this.props.sendMessage({
            operation: 'add new layer',
            trackid,
        })
    }
    removeLayer = (e) => {
        let trackid = e.target.dataset.trackid;
        let layerid = e.target.dataset.layerid;
        this.props.sendMessage({
            operation: 'remove layer',
            trackid,
            layerid,
        })
    }
    setLayerStat = (e) => {
        let trackid = e.target.dataset.trackid;
        let layerid = e.target.dataset.layerid;
        let stat = e.target.value;
        this.props.sendMessage({
            operation: 'set layer stat',
            trackid,
            layerid,
            stat,
        })
    }
    setLayerEffect = (e) => {
        let trackid = e.target.dataset.trackid;
        let layerid = e.target.dataset.layerid;
        let effect = e.target.value;
        this.props.sendMessage({
            operation: 'set layer effect',
            trackid,
            layerid,
            effect,
        })
    }

    setTargetStat = (e) => {
        let trackid = e.target.dataset.trackid;
        let stat = e.target.value;
        this.props.sendMessage({
            operation: 'set target stat',
            trackid,
            stat,
        })
    }
    setTargetEffect = (e) => {
        let trackid = e.target.dataset.trackid;
        let effect = e.target.value;
        this.props.sendMessage({
            operation: 'set target effect',
            trackid,
            effect,
        })
    }

    thresholdCell = (p) => {
        let track = p.track;
        let index = p.index;
        let layer = '';
        let stat = '';

        if(index <= track.layers.length) {
            layer = track.layers[index];
            stat = layer.stat;
        }
        if(!track) return(<td></td>)
        return(
        <td>
            <select
                value={stat}
                data-trackid={track._id}
                data-layerid={layer._id}
                onChange={this.setLayerStat}
            >
                <option value=''>none</option>
                {this.props.doc.thresholds.map(thresh => {
                    return(
                        <option
                            value={thresh._id}
                            key={thresh._id}
                        >
                            {thresh.name}
                        </option>
                    )
                })}
            </select>
        </td>
    )}

    render() {return(<div className="panel">
        <h4>Damage Tracks</h4>
        {this.props.doc.tracks?.map((track, index) => {return(<div key={index}>
            <h5>
                {track.name || 'unnamed track'}
                &nbsp;
                <button
                    onClick={this.attemptRenameTrack}
                    data-trackid={track._id}
                >
                    rename track
                </button>
                &nbsp;
                <button
                    className="delete"
                    data-trackid={track._id}
                    data-trackname={track.name}
                    onClick={this.attemptRemoveTrack}
                >
                    x
                </button>
            </h5>
            <table>
                <thead>
                    <tr>
                        <th>Layer</th>
                        <th>Stat</th>
                        <th>Effect</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {track.layers.map((layer, index) => {return(
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <this.thresholdCell
                                track={track}
                                index={index}
                            />
                            <td>
                                <input 
                                    type='text'
                                    placeholder={index + 1 + " damage"}
                                    defaultValue={layer.effect || ''}
                                    data-trackid={track._id}
                                    data-layerid={layer._id}
                                    onBlur={this.setLayerEffect}
                                />
                            </td>
                            <td>
                                <button
                                    className="delete"
                                    data-trackid={track._id}
                                    data-layerid={layer._id}
                                    onClick={this.removeLayer}
                                >
                                    x
                                </button>
                            </td>
                        </tr>
                    )})}
                    <tr>
                        <td>Target Pool</td>
                        <td>
                            <select
                                value={track.targetStat}
                                data-trackid={track._id}
                                onChange={this.setTargetStat}
                            >
                                <option value=''>none</option>
                                {this.props.doc.pools.map(pool => {return(
                                    <option
                                        value={pool._id}
                                        key={pool._id}
                                    >
                                        {pool.name}
                                    </option>
                                )})}
                            </select>
                        </td>
                        <td>
                            <input 
                                type='text'
                                defaultValue={track.targetEffect}
                                data-trackid={track._id}
                                onBlur={this.setTargetEffect}
                                placeholder='Damage'
                            />
                        </td>
                        <td></td>
                    </tr>
                    <tr>
                        <td colSpan={99}>
                            <button
                                onClick={this.addNewLayer}
                                data-trackid={track._id}
                            >
                                Add New Layer
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>)})}
        {this.props.doc.editable && <button
            onClick={this.addNewTrack}
        >
            Add New Damage Track
        </button>}

        </div>)}
}
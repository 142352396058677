import { capitalize } from '../../../TextUtils.js';
import Sgs from 'sgs';
import SortEntities from '../SortEntities.js';
import FilterEntities from '../FilterEntities.js';
import LimMultFormat from '../LimMultFormat.js';
import GetPrototypes from '../GetPrototypes.js';
import ThresholdTracks from './ThresholdTracks.js';
import StatFromPrototype from './StatFromPrototype.js';
import ItemStatList from './ItemStatList.js';

export default function StatBlocks (doc, options, format) {
    let entities = SortEntities(options, doc.entities);
    entities = FilterEntities(options, entities);
    let prototypes = GetPrototypes(doc);

    if(!prototypes) return '';
    if(!entities) return '';

    let output = '';
    let hideHeader = !options.hideHeader === true
        && !options.hideHeader === 'true'
        && !options.hideheader === true
        && !options.hideheader === 'true'
    if(hideHeader) {
        output += format.h1.pre;
        output += doc.name;
        output += ' ';
        output += doc.type;
        output += ' Entities';
        output += format.h1.suf;
    }
    entities.forEach(entity => {
        output += entityCard(entity, doc, prototypes, format);
    })
    return output;
}

const entityCard = (entity, doc, prototypes, format) => {
    let output = '';
    output += format.h2.pre;
    output += entity.name;
    output += ' (' + entity.xp.toLocaleString() + 'xp)';
    output += format.h2.suf;
    output += format.ul.pre;
    if(entity.limitations.length > 0) {
        output += format.li.pre;
        output += format.bold.pre;
        output += 'Limits';
        output += format.bold.suf;
        output += ': ';
        output += entity.limitations.map(limit => {
            let o = '';
            o += limit.name;
            o += ' (' + LimMultFormat(limit.multiplier) + ')';
            if(!!limit.notes) {
                o += ' ' + limit.notes;
            }
            return o;
        })
        .join(', ');
        output += format.li.suf;
    }
    if(entity.tags.length > 0) {
        output += format.li.pre;
        output += format.bold.pre;
        output += 'Tags';
        output += format.bold.suf;
        output += ': ';
        output += entity.tags.map(x => '#' + x).join(', ');
        output += format.li.suf;
    }
    if(entity.aspects.length > 0) {
        output += format.li.pre;
        output += format.bold.pre;
        output += 'Aspects';
        output += format.bold.suf;
        output += ': ';
        output += entity.aspects.join(', ');
        output += format.li.suf;
    }
    if(entity.flaws.length > 0) {
        output += format.li.pre;
        output += format.bold.pre;
        output += 'Flaws';
        output += format.bold.suf;
        output += ':+ ';
        output += entity.flaws.join(', ');
        output += format.li.suf;
    }
    Sgs.groups.forEach(group => {
        if(entity[group].length === 0) return;
        let shown = entity[group].filter(x => {
            if(!x.prototype) {
                return null;
            }
            return !x.prototype.displayVisibility || x.prototype.displayVisibility === 'owners'
        });
        if(shown.length === 0 && entity.items.length > 0) return;
        output += format.li.pre;
        output += format.bold.pre;
        output += capitalize(group);
        if(group === 'thresholds' && !!doc.tracks && doc.tracks.length > 0) {
            output += ThresholdTracks(doc, format, entity, group);
        }
        else {
            output += format.bold.suf;
            output += ': ';
            let prototypes = doc[group];
            output += prototypes
            .filter(proto => entity[group].map(x => x.name).includes(proto.name))
            .map(prototype => {
                return(
                    StatFromPrototype(doc, prototype, entity, group, format)
                )
            })
            .join(', ');
            output += '.';
        }
        output += format.li.suf;
    })
    // console.log(entity.equipment.length, entity.items.length);
    if(entity.items.length !== 0) {
        // output += 'Equipment\n';
        entity.items.forEach(item => {
            output += format.li.pre;
            output += format.bold.pre;
            output += item.name;
            output += format.bold.suf;
            output += ' (' + item.xp + 'xp) ';
            if(item.limitations.length > 0) {
                output += 'Limits: ';
                output += item.limitations.map(limit => {
                    let o = limit.name;
                    if(limit.note) {
                        o += ' ' + limit.note;
                    }
                    return o;
                })
                .join(', ');
                output += '. ';
            }
            if(item.aspects.length > 0) {
                output += 'Aspects: ' + item.aspects.join(', ');
                output += '. ';
            }
            if(item.flaws.length > 0) {
                output += 'Flaws: ' + item.flaws.join(', ');
                output += '. ';
            }
            Sgs.groups.forEach(group => {
                if(item[group].length === 0) return;
                output += capitalize(group) + ': ';
                output += ItemStatList(doc, entity, group, item).join(', ');
                output += '. ';
            })
            output += format.li.suf;
        })
    }
    output += format.ul.suf;
    return output;
}
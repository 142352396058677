import React from "react";
// import { useParams } from "react-router";
import TableOfUsers from '../Components/TableOfUsers.jsx'
import UserDataCache from "../Components/UserDataCache.jsx";
import Footer from '../Components/Footer.jsx';

class UserListPage extends React.Component {
    constructor(props){
        super(props)
        this.id = this.props.match.params.SessionId
        this.state = {
            users: []
        }
        // this.getSession()
    }
    componentDidMount(){
        this.getUsers()
    }
    getUsers = () => {
        fetch('/api/listUsers', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': UserDataCache.getAuthHeader()
            }
        })
        .then(res => res.json())
        .then(info  => {
            // console.log(info);
            this.setState({
                users: info
            })
        })
    }
    render () {
        document.title = ['Users', 'SGS'].join(' - ');
        return (
            <div className="container">
                <h1>Users</h1>
                <TableOfUsers users={this.state.users} />
                <Footer/>
            </div>
        )
    }
}

export default UserListPage;
import DicePool from "./DicePool/DicePool.js";
import SnapshotObject from "./SnapshotObject.js";
import calcNode from "./calcNode.js";
import groups from "./groups.js";

function recurseItems(entity, search, values){
    groups.forEach(group => {
        let found = entity[group].find(x => {
            let statname = x.name.toLowerCase();
            return search === statname;
        });
        if(!!found) {
            // console.log(found)
            values.push(found);
        }
    });
    // console.log(entity.name, entity.items);
    if(!entity.items) return;
    entity.items.forEach(item => 
        recurseItems(item, search, values));
}

function returnType(obj){
    if(obj instanceof DicePool) return 'DicePool';
    else if(typeof obj === 'string') return 'string';
    else if(typeof obj === 'number') return 'number';
}

const operations = {
    'none': {
        strategy: 'none'  ,
        sep: '' ,
        desc:'pick an operation',
        calc: (doc, entity, node) => {
            // console.log(none);
            return 0;
        }
    },
    'average': {
        strategy: 'array' ,
        sep: ',',
        desc:'takes the average of all children',
        calc: (doc, entity, node) => {
            if(!node.children || node.children.length === 0) {
                return 0;
            }
            let total = 0;
            let count = node.children.length;
            node.children.forEach(child => {
                let calc = calcNode(doc, entity, child);
                // console.log(calc)
                if(calc instanceof DicePool) {
                    total += calc.average();
                }
                else if(typeof calc === 'number') {
                    total += calc;
                }
            });
            return total / count;
        }
    },
    'max': {
        strategy: 'array' ,
        sep: ',',
        desc:'takes the maximum of all children',
        calc: (doc, entity, node) => {
            // console.log('max', node)
            if(!node.children || node.children.length === 0) {
                return 0;
            }
            let max = Number.NEGATIVE_INFINITY;
            node.children.forEach(child => {
                // console.log(child);
                let calc = calcNode(doc, entity, child);
                if(calc instanceof DicePool && max instanceof DicePool) {
                    if(calc.average() > max.average()) max = calc;
                }
                else if(calc instanceof DicePool && typeof max === 'number') {
                    if(calc.average() > max) max = calc;
                }
                else if(typeof calc === 'number' && max instanceof DicePool) {
                    if(calc > max.average()) max = calc;
                }
                else if(typeof calc === 'number' && typeof max === 'number') {
                    if(calc > max) max = calc;
                }
            });
            return max;
        }
    },
    'min': {
        strategy: 'array' ,
        sep: ',',
        desc:'takes the minimum of all children',
        calc: (doc, entity, node) => {
            if(!node.children || node.children.length === 0) {
                return 0;
            }
            let min = Number.POSITIVE_INFINITY;
            node.children.forEach(child => {
                let calc = calcNode(doc, entity, child);
                // console.log(calc)
                if(calc instanceof DicePool && min instanceof DicePool) {
                    if(calc.average() < min.average()) min = calc;
                }
                else if(calc instanceof DicePool && typeof min === 'number') {
                    if(calc.average() < min) min = calc;
                }
                else if(typeof calc === 'number' && min instanceof DicePool) {
                    if(calc < min.average()) min = calc;
                }
                else if(typeof calc === 'number' && typeof min === 'number') {
                    if(calc < min) min = calc;
                }
            });
            return min;
        }
    },
    'sum': {
        strategy: 'array' ,
        sep: ',',
        desc:'adds up all children',
        calc: (doc, entity, node) => {
            let calcedChildren = node.children.map(child => {
                let n = calcNode(doc, entity, child);
                // console.log('\tchild', child, n);
                return n;
            });
            // console.log('sum', calcedChildren);
            // console.log(calcedChildren.join('+'));
            let pool = new DicePool(calcedChildren.join('+'));
            return pool.toObjectOrNumber();
        }
    },
    'add': {
        strategy: 'binary',
        sep: '+',
        desc:'adds two numbers',
        calc: (doc, entity, node) => {
            let calcedChildren = node.children.map(child => calcNode(doc, entity, child));
            // console.log(calcedChildren);
            let pool = new DicePool(calcedChildren.join('+'));
            return pool.toObjectOrNumber();
        }
    },
    'subtract': {
        strategy: 'binary',
        sep: '-',
        desc:'subtracts b from a',
        calc: (doc, entity, node) => {
            let calcedChildren = node.children.map(child => calcNode(doc, entity, child));
            // console.log(calcedChildren);
            // console.log(calcedChildren.join('-'));
            let pool = new DicePool(calcedChildren.join('-'));
            // console.log(pool);
            return pool.toObjectOrNumber();
        }
    },
    'multiply': {
        strategy: 'binary',
        sep: '×',
        desc:'multiplies two numbers',
        calc: (doc, entity, node) => {
            let calcedChildren = node.children.map(child => calcNode(doc, entity, child));
            if(calcedChildren.includes(undefined)) return 0;
            // console.log(calcedChildren)
            let pool = new DicePool(calcedChildren.join('*'));
            return pool.toObjectOrNumber();
        }
    },
    'divide': {
        strategy: 'binary',
        sep: '÷',
        desc:'divides a by b',
        calc: (doc, entity, node) => {
            let calcedChildren = node.children.map(child => calcNode(doc, entity, child));
            if(calcedChildren.includes(undefined)) return 0;
            let pool = new DicePool(calcedChildren.join('/'));
            return pool.toObjectOrNumber();
        }
    },
    'integer': {
        strategy: 'number',
        sep: '' ,
        desc:'a round number',
        calc: (doc, entity, node) => {
            let int = parseInt(node.value);
            return int;
        }
    },
    'fraction': {
        strategy: 'number',
        sep: '' ,
        desc:'a fraction of two round numbers',
        calc: (doc, entity, node) => {
            let split = node.value.split('/');
            let num = split[0];
            let den = split[1];
            let frac = num / den;
            return frac;
        }
    },
    'decimal': {
        strategy: 'number',
        sep: '' ,
        desc:'a number with a decimal point',
        calc: (doc, entity, node) => {
            let float = parseFloat(node.value);
            return float;
        }
    },
    'best': {
        strategy: 'select',
        sep: '' ,
        desc:'picks the best stat value from an'
            + 'entity and all its items',
        calc: (doc, entity, node) => {
            let values = [];
            if(!node.value) return 0;
            let search = node.value.toLowerCase();
            recurseItems(entity, search, values);
            // console.log(entity.name, search, values);
            if(values.length === 0) {
                return 0;
            }
            let max = Number.NEGATIVE_INFINITY;
            values.forEach(child => {
                if(!child) return;
                // console.log(child);
                let newValue = null;
                if(!!child.dicePool) newValue = new DicePool(child.dicePool);
                else newValue = child;
                if(!newValue) return;
                // console.log(calc)
                if(newValue instanceof DicePool && max instanceof DicePool) {
                    if(newValue.average() > max.average()) max = newValue;
                }
                else if(newValue instanceof DicePool && typeof max === 'number') {
                    if(newValue.average() > max) max = newValue;
                }
                else if((typeof newValue === 'number' || newValue instanceof Number)
                    && max instanceof DicePool) {
                    if(newValue > max.average()) max = newValue;
                }
                else if((typeof newValue === 'number' || newValue instanceof Number) 
                    && (typeof max === 'number' || max instanceof Number)) {
                    if(newValue > max) max = newValue;
                }
                else if(newValue.valueType === 'dieSize' && max instanceof DicePool) {
                    let pool = new DicePool('1' + newValue.dieSize);
                    if(pool.average() > max.average()) max = pool;
                }
                else if(newValue.valueType === 'dieSize' && typeof max === 'number') {
                    let pool = new DicePool('1' + newValue.dieSize);
                    if(pool.average() > max) max = pool;
                }
                else if(newValue.valueType === 'number'
                    && (typeof max === 'number' || max instanceof Number)) {
                    if(newValue.number > max) max = newValue.number;
                }
                else if(newValue.valueType === 'formula' && (typeof max === 'number' || max instanceof Number)) {
                    // console.log('formula in best')
                    if(newValue.calculated > max) max = newValue.calculated;
                }
                else if(newValue.valueType === 'formula' && max instanceof DicePool) {
                    // console.log('formula in best')
                    let pool = new DicePool('1' + max.dieSize);
                    if(newValue.calculated > pool.average()) max = newValue.calculated;
                }
                else if(newValue.valueType === 'formula' && max.valueType === 'formula') {
                    // console.log('formula in best')
                    if(newValue.calculated > newValue.calculated) max = newValue.calculated;
                }
                else {
                    console.error('best fell through', newValue, newValue instanceof DicePool, max, typeof max);
                }
            });
            if(max === Number.NEGATIVE_INFINITY) max = 0;
            return max;
        }
    },
    'statref': {
        strategy: 'select',
        sep: '' ,
        desc:'picks the value of the owner\'s stat',
        calc: (doc, entity, node) => {
            // console.log('statref');
            if(!node || !node.value) return 0;
            let value = 0;
            /** the name of the target stat to be found on the entity and replaced in the formula */
            let search = node.value.toLowerCase();
            let f = null;
            groups.forEach(group => {
                /** stat instance on the entity, no formula text */
                let found = entity[group].find(x => {
                    let statname = x.name.toLowerCase();
                    let matched = search === statname;
                    return matched;
                });
                /** stat prototype from the doc, with formula text */
                let prototype = doc[group].find(x => {
                    let statname = x.name.toLowerCase();
                    let matched = search === statname;
                    return matched;
                });
                if(!!found) {
                    f = found;
                    // console.log('found', f);
                    switch(found.valueType) {
                        case "number": {
                            // console.log(entity.name, found.name, 'number');
                            value = found.number;
                            break;
                        } 
                        case "formula": {
                            // if the target stat is a formula, then we need to calculate that formula.
                            // that means that we have to get the formula text and parse it.
                            // console.log(entity.name, found.name, 'formula');
                            if(found.calculated === undefined) {
                                // console.log('node', SnapshotObject(node));
                                // console.log('found', SnapshotObject(found));
                                // console.log('prototype', SnapshotObject(prototype));
                                let formula = prototype.formula;
                                // found.calculated = 0;
                                let newNode = calcNode(doc, entity, formula);
                                // value = newNode;
                                found.calculated = newNode;
                            }
                            else {
                            }
                            value = found.calculated;
                            // console.log(value);
                            // console.log(found.name, value, found, found.calculated, JSON.stringify(found));
                            break;
                        }
                        case "dieSize": {
                            // console.log(entity.name, found.name, 'dieSize');
                            if(found.dieSize === 'none') {
                                value = 0;
                            }
                            else {
                                value = new DicePool('1' + found.dieSize);
                            }
                            break;
                        }
                        case "dicePool": {
                            // console.log(entity.name, found.name, 'dicePool');
                            value = new DicePool(found.dicePool);
                            break;
                        }
                        default: {
                            console.error(entity.name, found.name, 'statref FELL THROUGH');
                            break;
                        }
                    }
                }
            })
            if(!f) {
                // console.log('!!!did not find', search, 'on', entity.name, value, f);
            }
            return value;
        }
    },
    'flatten': {
        strategy: 'select',
        sep: '' ,
        desc:'sums the same stat on the parent and all child items',
        calc: (doc, entity, node) => {
            let values = [];
            if(!node.value) return 0;
            let search = node.value.toLowerCase();
            recurseItems(entity, search, values);
            // console.log(entity.name, search, values);
            if(values.length === 0) {
                return 0;
            }
            let sum = 0;
            let temps = [];
            values.forEach(child => {
                if(!child) return;
                // console.log(child);
                let calc = null;
                if(!!child.dicePool) calc = new DicePool(child.dicePool);
                else calc = child;
                if(!calc) return;
                // console.log(calc)
                if(calc instanceof DicePool && sum instanceof DicePool) {
                    temps.push(calc);
                }
                else if(calc instanceof DicePool && typeof sum === 'number') {
                    temps.push(calc);
                }
                else if((typeof calc === 'number' || calc instanceof Number)
                && max instanceof DicePool) {
                    temps.push(calc);
                }
                else if((typeof calc === 'number' || calc instanceof Number) 
                && (typeof sum === 'number' || sum instanceof Number)) {
                    temps.push(calc);
                }
                else if(calc.valueType === 'dieSize' && sum instanceof DicePool) {
                    let pool = new DicePool('1' + calc.dieSize);
                    temps.push(pool);
                }
                else if(calc.valueType === 'dieSize' && typeof sum === 'number') {
                    let pool = new DicePool('1' + calc.dieSize);
                    temps.push(pool);
                }
                else if(calc.valueType === 'number'
                && (typeof sum === 'number' || sum instanceof Number)) {
                    temps.push(calc.number);
                }
                else {
                    // console.error('best fell through', calc, max);
                }
            });
            let megaPool = new DicePool(temps.join('+'));
            sum = megaPool.toStringOrNumber();

            return sum;
        }
    },
}

export default operations;
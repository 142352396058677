export default function StatDisplayVisibilityEditor(props) {
    const changeDisplayVisibility = (e) => {
        let newVis = e.target.value;
        props.sendMessage({
            operation: 'change stat display visibility',
            statId: props.stat._id,
            statGroup: props.group,
            displayVisibility: newVis,
        })
    }

    return(
        <select
            value={props.stat.displayVisibility || ''}
            onChange={changeDisplayVisibility}
        >
            <option value=''>Always</option>
            <option value='items' >Only On Items</option>
            <option value='owners' >Only On Owners</option>
        </select>
    );
}
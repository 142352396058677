import React from "react";
import { Link, Route, Switch } from "react-router-dom";
import Sgs from 'sgs';
// let Sgs = await import('sgs');
import UserInline from '../Components/UserInline.jsx';
import UserDataCache from '../Components/UserDataCache.jsx';
import UserSelector from '../Components/UserSelector.jsx';
import EditContentName from "../Components/EditContentName.jsx";
import DocSelector from "../Components/SocketDoc/DocSelector.jsx";
import Spinner from "../Components/Spinner.jsx";
import EncounterPage from "../Components/CampaignPage/EncounterPage.jsx";
import processCampaign from "../Components/CampaignPage/processCampaign.js";
import Footer from "../Components/Footer.jsx";

class CampaignPage extends React.Component {
    constructor(props){
        super(props)
        this.id = this.props.match.params.CampaignId
        this.state = {
            addUserModal: false,
            addDocModal: false,
            gettingCampaign: true,
        }
    }

    componentDidMount(){
        this.getCampaign();
    }
    
    getCampaign = () => {
        fetch('/api/getCampaign', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                campaignid: this.id
            })
        })
        .then(res => res.json())
        .then(campaign  => {
            if(campaign) this.setStateFromChild(campaign)
        })
    }

    setStateFromChild = (campaign) => {
        let c = campaign;
        c.docs?.forEach((doc, index) => {
            c.docs[index] = Sgs.calculateAllXp(doc);
        })
        c = processCampaign(c);
        this.setState({
            campaign: c,
            gettingCampaign: false,
        })
    }

    changeGm = () => {
        alert('not implemented');
    }

    setListing = (listed) => {
        fetch('/api/updateCampaign', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': UserDataCache.getAuthHeader()
            },
            body: JSON.stringify({
                campaignid: this.id,
                unlisted: !listed
            })
        })
        .then(res => res.json())
        .then(info  => {
            if(info) this.setStateFromChild(info);
        })
    }

    setTrash = (trashed) => {
        fetch('/api/updateCampaign', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': UserDataCache.getAuthHeader(),
            },
            body: JSON.stringify({
                campaignid: this.id,
                trashed: trashed
            })
        })
        .then(res => res.json())
        .then(info  => {
            if(info) this.setStateFromChild(info);
        })
    }

    removePlayer = (id) => {
        fetch('/api/removePlayerFromCampaign', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': UserDataCache.getAuthHeader()
            },
            body: JSON.stringify({
                campaignid: this.id,
                removeuser: id
            })
        })
        .then(res => res.json())
        .then(info  => {
            if(!info) return;
            // console.log(info);
            if(info) this.setStateFromChild(info);
            this.setState({
                removeUsers: false
            })
        })
    }

    confirmRemovePlayer = (player) => {
        // console.log(player);
        if(!window.confirm('Do you really want to remove '
            + player.username + ' from this campaign?')) return;
        // console.log('remove player: ' + player.username);
        this.removePlayer(player._id);
    }

    addUser = (user) => {
        // console.log('add ' + user._id);
        let id = user._id;
        fetch('/api/addPlayerToCampaign', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': UserDataCache.getAuthHeader()
            },
            body: JSON.stringify({
                campaignid: this.id,
                adduser: id
            })
        })
        .then(res => res.json())
        .then(info  => {
            if(info) this.setStateFromChild(info);
            this.setState({
                removeUsers: false,
                addUserModal: false,
            })
        })
    }
    renameCampaign = (id, name, target) => {
        fetch('/api/renameCampaign', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': UserDataCache.getAuthHeader()
            },
            method: "POST",
            body: JSON.stringify({
                id,
                name,
                // campaign: target,
            })
        })
        .then(res => res.json())
        .then(info  => {
            if(info) this.setStateFromChild(info);
        })
    }
    toggleAddDocModal = () => {
        this.setState({
            addDocModal: !this.state.addDocModal
        })
    }
    addDoc = (doc) => {
        console.log(doc);
        fetch('/api/addDocToCampaign', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': UserDataCache.getAuthHeader()
            },
            method: "POST",
            body: JSON.stringify({
                id: this.state.campaign._id,
                docid: doc._id,
            })
        })
        .then(res => res.json())
        .then(info  => {
            if(info) this.setStateFromChild(info);
        })
    }
    attemptDeleteDoc = (e) => {
        let docid = e.target.dataset.docid;
        let docname = e.target.dataset.docname;
        if(window.confirm("WARNING!\n\nAre you sure you want to delete the doc " + docname + "?\n\nThis might remove entities used in your encounters!")) {
            this.deleteDoc(docid);
        }
    }
    deleteDoc = (docid) => {
        fetch('/api/deleteDocFromCampaign', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': UserDataCache.getAuthHeader()
            },
            method: "POST",
            body: JSON.stringify({
                id: this.state.campaign._id,
                docid,
            })
        })
        .then(res => res.json())
        .then(info  => {
            if(info) this.setStateFromChild(info);
        })
    }
    
    closeModal = (e) => {
        console.log('closeModal')
        let target = e.target.dataset.target;
        this.setState({
            addUserModal: false,
            addDocModal: false,
            [target]: false,
        })
    }

    addEncounter = (e) => {
        fetch('/api/addEncounter', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': UserDataCache.getAuthHeader()
            },
            method: "POST",
            body: JSON.stringify({
                id: this.state.campaign._id,
            })
        })
        .then(res => res.json())
        .then(campaign  => {
            // console.log(info)
            if(campaign) this.setStateFromChild(campaign);
        })
    }

    
    setEnemyFilter = (e) => {
        let enemyfilter = e.target.value;
        fetch('/api/updateCampaign', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': UserDataCache.getAuthHeader(),
            },
            body: JSON.stringify({
                campaignid: this.id,
                enemyfilter,
            })
        })
        .then(res => res.json())
        .then(info  => {
            if(info) this.setStateFromChild(info);
        })
    }

    setSortOrder = (e) => {
        let enemysortorder = e.target.value;
        fetch('/api/updateCampaign', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': UserDataCache.getAuthHeader(),
            },
            body: JSON.stringify({
                campaignid: this.id,
                enemysortorder,
            })
        })
        .then(res => res.json())
        .then(info  => {
            if(info) this.setStateFromChild(info);
        })
    }

    setIncludeParent = (e) => {
        let includeparententities = e.target.checked;
        fetch('/api/updateCampaign', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': UserDataCache.getAuthHeader(),
            },
            body: JSON.stringify({
                campaignid: this.id,
                includeparententities,
            })
        })
        .then(res => res.json())
        .then(info  => {
            if(info) this.setStateFromChild(info);
        })
    }

    render () {
        const campaign = this.state.campaign;
        // console.log(campaign);
        const token = UserDataCache.getTokenObject();
        const canEdit =
            (!!token &&
            !!this.state.campaign
            && !!this.state.campaign.gm
            && this.state.campaign?.gm === token?.user._id)
            || token?.user.userLevel === 'MOD'
            || token?.user.userLevel === 'ADMIN';
        const canEditUsers = 
            (token?.user.userLevel === 'MOD'
            || token?.user.userLevel === 'ADMIN')
            // console.log(this.state.campaign?.docs);
            // console.log(this.state.campaign?.unlisted);
            // console.log(this.state.campaign?.trashed);
        if (campaign) {
            const dir = `/campaign/${campaign._id}/`;
            document.title = [campaign.name, 'SGS'].join(' - ');
            return (
            <div className="container">
                <Switch>
                    {(token?.user?._id === campaign?.gm?._id  || token?.user?.userLevel === 'admin') &&
                    <Route path={dir+'Encounter/:EncounterId'}
                    component={(props) => <>
                            <h1>
                                <Link to="/campaigns">Campaign</Link>:&nbsp;
                                <Link to={dir}>
                                    {campaign.name} (back)
                                </Link>
                            </h1>
                            <EncounterPage 
                                campaign={campaign}
                                {...props}
                                setStateFromChild={this.setStateFromChild}
                                // enc={enc}
                                // key={enc._id}
                            />
                        </>}
                    />}
                    <Route path='*'>
                        <h1>
                            <a href="/campaigns">Campaign</a>:&nbsp;
                                <EditContentName
                                    name={campaign.name}
                                    id={campaign._id}
                                    renameCallback={this.renameCampaign}
                                    target={campaign}
                                />    
                        </h1>
                        <p>
                            Campaign id: {campaign._id}
                            {!!campaign.unlisted ? ', 💬 unlisted' : ''}
                            {!!campaign.trashed ? ', ❌ trashed' : ''}
                        </p>
                        <h2>Players</h2>
                        <ul>
                            {campaign.gm &&
                                <li><UserInline user={campaign.gm} />(GM)</li>
                            }
                            {campaign.allPlayers.map((player, index) => (
                                <li key={index}>
                                    <UserInline user={player} />&nbsp;
                                    {canEditUsers
                                    && this.state.removeUsers
                                    && <button 
                                    className="delete"
                                    onClick={() => this.confirmRemovePlayer(player)}>x</button>}
                                </li>
                            ))}
                            {canEdit && <li><button
                            onClick={() => this.setState({addUserModal: !this.state.addUserModal})}
                            >➕ Add User</button></li>}
                            {canEdit && <li><button
                            onClick={() => this.setState(
                                {removeUsers: !this.state.removeUsers})}>remove users
                            </button></li>}
                        </ul>
                        <h2>Docs</h2>
                        <ul>
                            {campaign.docs.map(doc => {return(
                                <li key={doc._id}>
                                    <Link to={'/doc/' + doc._id}>
                                        {doc.name}
                                    </Link>
                                    &nbsp;
                                    ({doc.type})
                                    &nbsp;
                                    {canEdit && <button
                                        className="delete"
                                        onClick={this.attemptDeleteDoc}
                                        data-docid={doc._id}
                                        data-docname={doc.name}
                                    >
                                        x
                                    </button>}
                                </li>
                            )})}
                            {canEdit && <li>
                                <button
                                    onClick={this.toggleAddDocModal}
                                >
                                    ➕Add Doc
                                </button>
                            </li>}
                        </ul>
                        {canEdit && <div>
                            <h2>Enemy Selection</h2>
                            <label>
                                Entity Tag Filter:&nbsp;
                                <input
                                    type='text'
                                    defaultValue={campaign.enemyfilter}
                                    onBlur={this.setEnemyFilter}
                                />
                            </label>
                            <br />
                            <label>
                                Sort Order:&nbsp;
                                <select
                                    onChange={this.setSortOrder}
                                    defaultValue={campaign.enemysortorder}
                                >
                                    <option>Name</option>
                                    <option>Name, Reversed</option>
                                    <option>XP Ascending</option>
                                    <option>XP Descending</option>
                                </select>
                            </label>
                            <br />
                            <label>
                                Include Parent Doc Entities:&nbsp;
                                <input
                                    type='checkbox' 
                                    onChange={this.setIncludeParent}
                                    defaultChecked={campaign.includeparententities}
                                />
                            </label>
                        </div>}
                        {canEdit && 
                            <div>
                                <h2>Edit Campaign</h2>
                                <ul>
                                    <li><button onClick={this.changeGm}>change GM</button></li>
                                    <li><button onClick={() => this.setListing(true)}>list campaign</button></li>
                                    <li><button onClick={() => this.setListing(false)}>unlist campaign</button></li>
                                    <li><button onClick={() => this.setTrash(true)}>trash campaign</button></li>
                                    <li><button onClick={() => this.setTrash(false)}>untrash campaign</button></li>
                                </ul>
                            </div>
                        }
                    </Route>
                </Switch>
                {(token?.user?._id === campaign?.gm?._id  || token?.user?.userLevel === 'admin') && <>
                    <h2>Encounters</h2>
                    <ul>
                        <li>
                            <button onClick={this.addEncounter}>
                                ➕ Add new encounter
                            </button>
                        </li>
                        {campaign.encounters.map(enc => {return(
                            <li key={enc._id}>
                                <Link to={dir+'Encounter/'+enc._id}>
                                    {enc.percentages.stars}
                                    {enc.name}
                                </Link>
                            </li>
                        )})}
                    </ul>
                </>}
                <br />
                {canEdit && <div>
                <UserSelector
                    closeModal={this.closeModal}
                    addUser={this.addUser}
                    campaign={this.state.campaign}
                    state={this.state}
                    setState={this.setState}
                />
                <DocSelector
                    addParentDoc={this.addDoc}
                    // doc={null}
                    state={this.state}
                    setState={this.setState}
                    closeModal={this.closeModal}
                />
                </div>}
                <Footer/>
            </div>
        )}
        else { return(
            <div className="container">
                <h1><a href="/campaigns">Campaign</a>:&nbsp;
                    {this.props.match.params.CampaignId}
                </h1>
                <Spinner 
                    fullpage={true}
                />
                <Footer/>
            </div>
        )}
    }
}

export default CampaignPage;
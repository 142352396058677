import React from 'react';
import './TagList.css';
import Modal from '../../Modal.jsx';

class LimitList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            limitpicker: false,
            doctags: [],
            note: '',
        }
    }
    openLimitPicker = () => {
        let dt = []
        let rt = this.props.entity.tags || [];
        this.props.doc.entities.forEach(entity => {
            entity.tags?.forEach(tag => {
                if(!dt.includes(tag) && !rt.includes(tag)) {
                    dt.push(tag);
                }
            })
        });
        dt.sort();
        this.setState({
            limitpicker: true,
            doctags: dt,
        });
    }
    closeItemPicker = () => {
        this.setState({ limitpicker: false })
    }
    addLimit = (entityId, name, multiplier) => {
        // let entityId = this.props.entity._id;
        // let name = e.target.dataset.name;
        // let multiplier = e.target.dataset.multiplier;
        // let note = this.state.note;
        this.props.addLimit(entityId, name, multiplier, '');
        // this.setState({ limitpicker: false })
    }
    removeLimit = (entityId, name) => {
        // let entityId = this.props.entity._id;
        // let name = e.target.dataset.name;
        this.props.removeLimit(entityId, name);
    }
    onNoteChange = (e) => {
        this.setState({ note: e.target.value });
    }
    toggleLimit = (e) => {
        let checked = e.target.checked;
        let entityId = this.props.entity._id;
        let name = e.target.dataset.name;
        let multiplier = e.target.dataset.multiplier;
        // let note = this.state.note;
        if(checked) {
            this.addLimit(entityId, name, multiplier)
        }
        else {
            this.removeLimit(entityId, name)
        }
    }

    openModal = (e) => {
        let target = e.target.dataset.target;
        this.setState({
            [target]: true,
        })
    }
    closeModal = (e) => {
        let target = e.target.dataset.target;
        this.setState({
            [target]: false,
        })
    }
    renderStatic () {
        let entity = this.props.entity;
        let limits = entity?.limitations;
        return(
            <td className='listCell'>
                {limits?.map(limit => {return(
                    <span
                        key={limit.name}
                        data-target="limitModal"
                        onClick={this.openModal}
                    >
                        {limit.name}
                        &nbsp;×
                        {limit.multiplier}
                        {!!limit.note && ' ' + limit.note}
                    </span>
                )})}
                {(!limits || (limits.length === 0)) && '-'}
            </td>
        )
    }

    renderEditable () {
        let entity = this.props.entity;
        let limits = entity?.limitations;
        let doc = this.props.doc;
        return(
            <td className='listCell'>
                <button
                    className='tagList'
                    onClick={this.openModal}
                    data-target="limitModal"
                    >
                    {limits?.map(limit => {return(
                            limit.name + 
                            ' (×' + 
                            limit.multiplier + 
                            ')' +
                            (!!limit.note ? ' ' + limit.note : '')
                    )}).join(', ')}
                    {(!limits || (limits.length === 0)) && '+'}

                </button>
                <Modal
                    state={this.state}
                    title='Select Limtations'
                    target='limitModal'
                    closeModal={this.closeModal}
                    closeButtonText='close'
                >
                    <table>
                        <thead>
                            <tr>
                                <td>Limit</td>
                                <td>Multiplier</td>
                                <td>Enabled</td>
                                <td>Note</td>
                            </tr>
                        </thead>
                        <tbody>
                            {doc.limitations.map(limit => {
                            let entityLimit = entity.limitations
                                ?.find(x => x.name === limit.name);
                            return(<tr key={limit.name}>
                                <td>
                                    <label
                                        htmlFor={limit.name}
                                        className={!!entityLimit ? 'checked' : ''}
                                    >
                                        {limit.name}
                                    </label>
                                </td>
                                <td>{limit.multiplier}</td>
                                <td>
                                    <input
                                        id={limit.name}
                                        type='checkbox'
                                        checked={!!entityLimit}
                                        onChange={this.toggleLimit}
                                        data-name={limit.name}
                                        data-multiplier={limit.multiplier}
                                    />
                                </td>
                                <td>
                                    <input
                                        type='text'
                                        data-limitname={limit.name}
                                        value={limit.notes}
                                    />
                                </td>
                            </tr>)})}
                        </tbody>
                    </table>
                    <input
                        value={this.state.note}
                        onChange={this.onNoteChange}
                        placeholder='optional limitation note'
                    />
                    <div>
                        {this.props.doc.limitations?.map(limit => {return(
                            <button
                                onClick={this.addLimit}
                                data-name={limit.name}
                                data-multiplier={limit.multiplier}
                                key={limit.name}
                            >
                                {limit.name} (×{limit.multiplier})
                            </button>
                        )})}
                    </div>
                </Modal>
            </td>
        )
    }

    render () {
        let entity = this.props.entity;
        if(entity.editable) return this.renderEditable();
        return this.renderStatic();
    }
}

export default LimitList;
export default function AttemptChangePortrait(entity, sendMessage) {
    let url = window.prompt('Enter a new image URL for ' + entity.name);
    console.log(url)
    if(url !== null && url !== undefined) {
        sendMessage({
            operation: 'change entity image url',
            entityid: entity._id,
            url,
        });
    }
}
import textFormula from "./textFormula.js";
import groups from "./groups.js";

function prototypeFormulas(doc) {
    groups.forEach(group => {
        doc[group].forEach(stat => {
            if(stat.valueType === 'formula' && !!stat.formula) {
                stat.formulaText = textFormula(stat.formula);
            }
        })
    })
    return doc;
}
export default prototypeFormulas;
import UniformGroup from "./UniformGroup.js";

class Parenthetical {
    constructor(string) {
        this.parent = null;
        this.children = [];
        this.opType = '';
        this.max = 0;
        this.med = 0;
        this.min = 0;
        // this.id = (Math.random() + 1).toString(36).substring(7);
    }
    collapse() {
        if(!this.children) return;
        if(this.children.length === 1
            && this.children[0] instanceof Parenthetical
            && !!this.children[0].children) {
                this.children = this.children[0].children;
        }
        if(!this.children) return;
        for(let i = 0; i < this.children.length; i++) {
            let child = this.children[i];
            if(child instanceof Parenthetical) {
                child.collapse();
                if(!!child.children
                    && child.children.length === 1) {
                    this.children[i] =
                        this.children[i].children[0];
                }
            }
        }
    }
    segment() {
        if(!this.children) return;
        // find breaks
        let lastType = '';
        let breaks = [];
        let types = [];
        let debug = '';
        for(let i = 0; i < this.children.length; i++) {
            let child = this.children[i];
            let type = '';
            if(child === '+' || child === '-') {
                type = 'a';
            }
            else if(child === '*' || child === '/') {
                type = 's';
            }
            if(lastType === '' && type !== '') {
                types.push(type);
            }
            if(lastType !== ''
                && type !== ''
                && type !== lastType) {
                    breaks.push(i);
                    debug += i + ': (' + child + ') `' + lastType + '` -> `' + type + '` BREAK\n';
                    lastType = '';
            }
            else {
                debug += i + ': (' + child + ') `' + lastType + '` -> `' + type + '`\n';
                if(type !== '') {
                    lastType = type;
                }
            }
        }
        // console.log(debug)
        // package children
        if(breaks === []) {
            this.opType = lastType;
            return;
        }
        breaks.push(this.children.length);
        // console.log(breaks);
        // console.log(types);
        let newChildren = [];
        let lastBreak = 0;
        for(let i = 0; i < breaks.length; i++) {
            let b = breaks[i];
            let n = new Parenthetical();
            n.parent = this;
            n.opType = types[i];

            if(i === 0) {
                n.children = this.children.slice(lastBreak, b);
            }
            else {
                n.children = this.children.slice(lastBreak + 1, b);
            }
            if(i !== 0) {
                newChildren.push(this.children[lastBreak]);
            }
            newChildren.push(n);
            lastBreak = b;
        }
        this.children = newChildren;
    }
    reduce() {
        if(!this.children) return;
        if(this.children.length === 1) {
            let child = this.children[0];
            if(child instanceof Parenthetical) {
                child.reduce();
                this.min = child.min;
                this.max = child.max;
            }
            else if (child instanceof UniformGroup) {
                this.min = child.min;
                this.max = child.max;
            }
            return;
        };
        let newChildren = [];
        let prev = null;
        let lastValue = 0;
        for(let i = 0; i < this.children.length; i++) {
            let child = this.children[i];
            if(child instanceof Parenthetical) {
                child.reduce();
            }
            // for negative starting values
            if(i === 0 && child === '-') {
                newChildren.push(child);
            }
            // skip normal items, only proceed on operators
            if(
                i === 0
                || i === (this.children.length - 1)
                || !'+-*/'.includes(child)
            ) {
                prev = child;
                // console.log('skip child', child, prev);
                continue;
            }
            // only check operators when we can have Prev and Next
            let next =  this.children[i + 1];
            let combo = prev.constructor.name + child + next.constructor.name;
            // console.log('combo: ', combo);
            
            // situations we CAN calculate
            if(combo === 'Number+Number') {
                prev = prev + next;
                i++;
            }
            else if(combo === 'Number-Number') {
                prev = prev - next;
                i++;
            }
            else if(combo === 'Number*Number') {
                prev = prev * next;
                i++;
            }
            else if(combo === 'Number/Number') {
                if(next == 0) {
                    prev = 0;
                }
                else {
                    prev = prev / next;
                }
                i++;
            }
            else if(combo === 'UniformGroup*Number') {
                prev = prev.timesNumber(next);
                i++;
            }
            else if(combo === 'Number*UniformGroup') {
                prev = next.timesNumber(prev);
                i++;
            }
            else if(combo === 'UniformGroup+UniformGroup'
                && prev.isCompatableWith(next)) {
                    prev = prev.plusGroup(next);
                    i++;
            }
            else if(combo === 'UniformGroup-UniformGroup'
                && prev.isCompatableWith(next)) {
                    prev = prev.minusGroup(next);
                    i++;
            }
            
            // situations we need to push
            else {
                newChildren.push(prev);
                newChildren.push(child);
                prev = child;
            }
        }
        newChildren.push(prev);
        // console.log(newChildren);
        this.children = newChildren;
    }
    calculate() {
        if(!this.children || this.children.length === 0) return;
        let len = this.children.length;
        let max = undefined;
        let med = undefined;
        let min = undefined;
        let first = this.children[0];
        if(first instanceof Parenthetical) {
            first.calculate();
            max = first.max;
            med = first.med;
            min = first.min;
        }
        if(first instanceof UniformGroup) {
            max = first.max;
            med = first.med;
            min = first.min;
        }
        if(first instanceof Number) {
            max = first;
            med = first;
            min = first;
        }
        for(let i = 0; i < len; i++) {
            let current = this.children[i];
            let next = (i + 1) < len ? this.children[i + 1] : undefined;
            if(current === '+') {
                if(next instanceof Parenthetical) {
                    next.calculate();
                    max += next.max;
                    med += next.med;
                    min += next.min;
                }
                if(next instanceof UniformGroup) {
                    max += next.max;
                    med += next.med;
                    min += next.min;
                }
                if(next instanceof Number) {
                    max += next;
                    med += next;
                    min += next;
                }
            }
            else if(current === '-') {
                if(next instanceof Parenthetical) {
                    next.calculate();
                    max -= next.max;
                    med -= next.med;
                    min -= next.min;
                }
                if(next instanceof UniformGroup) {
                    max -= next.max;
                    med -= next.med;
                    min -= next.min;
                }
                if(next instanceof Number) {
                    max -= next;
                    med -= next;
                    min -= next;
                }
            }
            else if(current === '*') {
                if(next instanceof Parenthetical) {
                    next.calculate();
                    max *= next.max;
                    med *= next.med;
                    min *= next.min;
                }
                if(next instanceof UniformGroup) {
                    max *= next.max;
                    med *= next.med;
                    min *= next.min;
                }
                if(next instanceof Number) {
                    max *= next;
                    med *= next;
                    min *= next;
                }
            }
            else if(current === '/') {
                if(next instanceof Parenthetical) {
                    next.calculate();
                    max /= next.max;
                    med /= next.med;
                    min /= next.min;
                }
                if(next instanceof UniformGroup) {
                    max /= next.max;
                    med /= next.med;
                    min /= next.min;
                }
                if(next instanceof Number) {
                    max /= next;
                    med /= next;
                    min /= next;
                }
            }
        }
        this.min = min;
        this.med = med;
        this.max = max;
    }
    toString() {
        // console.log(this.debugChildren());
        if(!this.children || this.children.length === 0) {
            return 0;
        }
        if(this.children.length === 1) {
            return String(this.children[0]);
        }
        let multiple = this.children.map(child =>
            child.toString()).join('');
        if(this.parent === null || this.top === true) {
            return multiple;
        }
        return '(' + multiple + ')';
    }
    isNumber() {
        if(!!this.children
            && this.children.length === 1
            && this.children[0] instanceof Number) {
                return this.children[0];
        }
        return null;
    }
    debugChildren(level = 0){
        let out = '';
        out += step.repeat(level);
        // out += this.id;
        out += '(\n';
        if(!!this.children) {
            this.children.forEach(child => {
                if(child instanceof Parenthetical) {
                    out += child.debugChildren(level + 1);
                }
                else {
                    out += step.repeat(level + 1);
                    out += '`'
                    out += child;
                    out += '`'
                    out += '\n';
                }
            })
        }
        out += step.repeat(level);
        out += ')\n';
        return out;
    }
}
const step = '  ';

export default Parenthetical;
import React from "react"

/**
 * @param {Object} props.user the user object (not ID) to build a link for
 */
class UserInline extends React.Component{
    render(){
        var style = {
            display: 'inline-block',
            padding: '3px'
        }
        const user = this.props.user;
        if (user){
            let emailhash = user.emailhash;
            return (
                <span
                className="UserInline"
                style={style}>
                    <a href={'/user/' + user._id}>
                        <img
                            src={'https://www.gravatar.com/avatar/'
                            + emailhash
                            + '?s=15&d=retro'}
                            alt={user.username + ' avatar'}
                        />
                        <span> {user.username}</span>
                    </a>
                </span>
            )
        }
        else {
            return ('')
        }
    }
}

export default UserInline
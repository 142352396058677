import SortAndFilterEnemies from './SortAndFilterEnemies.jsx';

export default function EnemyListItem (props) {
    let enemy = props.enemy;
    let enc = props.enc;
    let xp = enemy.entity?.xp || 0;
    let quantity = enemy.quantity || 0;
    return (
        <li className="enli"><ul>
            <li>
                Enemy Type&nbsp;
                <button
                    className="delete"
                    data-encounterid={enc._id}
                    data-enemyid={enemy._id}
                    onClick={props.deleteEnemy}
                >
                    x
                </button>
            </li>
            <li>
                <label>
                    Doc: 
                    <select
                    data-encounterid={enc._id}
                    data-enemyid={enemy._id}
                    value={enemy.docId || ""}
                    onChange={props.setEnemyDoc}
                >
                    <option value="">none</option>
                    {props.campaign.docs.map(doc => {return(
                        <option
                            value={doc._id}
                            key={doc._id}
                        >
                            {doc.name}
                        </option>
                    )})}
                </select>
                </label>
            </li>
            <li>
                <label>
                    Entity: 
                    <SortAndFilterEnemies
                        campaign={props.campaign}
                        enc={props.enc}
                        enemy={props.enemy}
                        setEnemyEntity={props.setEnemyEntity}
                    />
                </label>
            </li>
            <li>
                <label>
                    Quantity:&nbsp;
                    <input
                        type="number"
                        step={1}
                        min={0}
                        value={quantity}
                        data-encounterid={enc._id}
                        data-enemyid={enemy._id}
                        onChange={props.setEnemyQuantity}
                    />
                </label>
            </li>
            <li>
                XP Each: {xp?.toLocaleString()}
            </li>
            <li>
                XP Subtotal: {enemy.xp?.toLocaleString()}
            </li>
        </ul></li>  
)}
import operations from "./Operations.js";

function textFormula(formula) {
    let operation = operations[formula.op];
    let contents = '';
    if(operation.strategy === 'binary') {
        contents += textFormula(formula.children[0]);
        contents += ' ';
        contents += operation.sep;
        contents += ' ';
        contents += textFormula(formula.children[1]);
    }
    else if(operation.strategy === 'array') {
        contents += formula.children.map(x =>
            textFormula(x)).join(', ');
    }
    else if(operation.strategy === 'select') {
        contents += formula.value;
    }
    else if(operation.strategy === 'number') {
        contents += formula.value;
    }
    return formula.op + '(' + contents + ')';
}

export default textFormula;
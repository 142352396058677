import { useState } from 'react';
import Footer from '../Components/Footer.jsx';
import './reset.css';
import crypto from 'crypto-browserify';

function validateMatch(
    e,
    setMatch,
    setTimeValid, 
    setEmailValid, 
    setPassLong, 
    setEnableButton,
    expirationDate,
    emailHash,
    ) {
    let passwordA = document.getElementById('passworda').value;
    let passwordB = document.getElementById('passwordb').value;
    let email = document.getElementById('email').value;
    let now = new Date();
    console.log(document.getElementById('email').validity)
    let ready = true;
    let enteredEmailHash = crypto.createHash('sha256').update(email).digest('hex');
    let emailHashesMatch = emailHash === enteredEmailHash;
    if(passwordA === passwordB && passwordA.length > 0) {
        setMatch(true);
    }
    else {
        setMatch(false);
        ready = false;
    }
    if(expirationDate >= now) {
        setTimeValid(true);
    }
    else {
        setTimeValid(false);
        ready = false;
    }
    if(email.includes('.') && email.includes('@') && emailHashesMatch) {
        setEmailValid(true);
    }
    else {
        setEmailValid(false);
        ready = false;
    }
    if(passwordA.length >= 10 && passwordA.length <= 64) {
        setPassLong(true);
    }
    else {
        setPassLong(false);
        ready = false;
    }
    setEnableButton(ready);
}

function submit(setSubmitted, setCompletionMessage, token, email) {
    let password = document.getElementById('passworda').value;
    setSubmitted(true);
    password = btoa(password);
    email = btoa(email);
    console.log();
    fetch('/api/performpasswordchange', {
        headers: {
            'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({
            email,
            password,
            token,
        })
    })
    .then(res => res.json())
    .then(res => {
        console.log(res);
        setCompletionMessage(res);
    })
}

export default function ResetExecution (props) {
    const urlsearch = window.location.search;
    // console.log(urlsearch);
    const params = new URLSearchParams(urlsearch);
    // console.log(params);
    const emailHash = params.get('etoken');
    const tokenText = params.get('ttoken');
    let now = new Date();
    const expirationDate = Date.parse(params.get('exp'));
    let expired = now > expirationDate;
    let email = document.getElementById('email')?.value;

    const [match, setMatch] = useState(false);
    const [timeValid, setTimeValid] = useState(!expired);
    const [emailValid, setEmailValid] = useState(false);
    const [passLong, setPassLong] = useState(false);
    const [enableButton, setEnableButton] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [completionMessage, setCompletionMessage] = useState(null);



    // console.log(emailHash, tokenHash, expirationDate);
    if(!emailHash || !tokenText) {
        return (
            <div className='container'>
                <h1>Malformed Request</h1>
                <p>There was an error with your request. Please try again: <a href='/ResetRequest'>Reset Password</a></p>
            </div>
        )
    }
    if(!expirationDate || expired) {
        return (
            <div className='container'>
                <h1>Password Reset</h1>
                <p>This reset token has expired. Please try again: <a href='/ResetRequest'>Reset Password</a></p>
            </div>
        )
    }
    if(completionMessage) {
        if(completionMessage.completion === true) {
            return(
                <div className='container'>
                    <h1>Password Reset Completed</h1>
                    <p>Password Reset.</p>
                </div>
            )
        }
        else {
            return(
                <div className='container'>
                    <h1>Password Reset Failed</h1>
                    <p>Please try again: <a href='/ResetRequest'>Reset Password</a></p>
                </div>
            )
        }
    }
    if(submitted) {
        return(
            <div className='container'>
                <h1>Password Reset Submitted</h1>
                <p>Waiting for server confirmation.</p>
            </div>
        )
    }
    return(
        <div className='container'>
            <h1>Reset Password</h1>
            <p>We recommend <a href='https://www.useapassphrase.com/' target='_blank'>using a passphrase.</a></p>
            <p>We also recommend using a password manager, such as Bitwarden or Lastpass.</p>
            <form className='reset' onSubmit={() => submit(setSubmitted, setCompletionMessage, tokenText, email)}>
                <label>Email&nbsp;
                    <input
                        id='email'
                        type='email'
                        required={true}
                        onChange={(e) => validateMatch(e, setMatch, setTimeValid, setEmailValid, setPassLong, setEnableButton, expirationDate, emailHash)}
                    />
                </label>
                <label>New Password&nbsp;
                    <input
                        id='passworda'
                        type='text'
                        required={true}
                        onChange={(e) => validateMatch(e, setMatch, setTimeValid, setEmailValid, setPassLong, setEnableButton,expirationDate, emailHash)}
                    />
                </label>
                <label>Confirm New Password&nbsp;
                    <input
                        id='passwordb'
                        type='text'
                        required={true}
                        onChange={(e) => validateMatch(e, setMatch, setTimeValid, setEmailValid, setPassLong, setEnableButton,expirationDate, emailHash)}
                    />
                </label>

                {emailValid && <span>✔️ Email is Valid</span>}
                {!emailValid && <span>❌ Email is Invalid</span>}
                
                {match && <span>✔️ Passwords Match</span>}
                {!match && <span>❌ Passwords Don't Match</span>}
                
                {passLong && <span>✔️ Password is is between 10 and 64</span>}
                {!passLong && <span>❌ Password Length not between 10 and 64</span>}
                
                {timeValid && <span>✔️ Token Hasn't Expired</span>}
                {!timeValid && <span>❌ Passwords Has Expired</span>}
                
                <button type='submit' disabled={!enableButton} >Submit Password Change</button>
            </form>
            <Footer/>
        </div>
    )
}
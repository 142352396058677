import Sgs from 'sgs';
import { capitalize } from '../../TextUtils.js';
// import Modal from '../../Modal';
import { Link } from 'react-router-dom';
// import CompareProp from '../../CompareProp';
// import { useState } from 'react';
import ItemSelector from '../../ItemSelector.jsx';
import getStatValue from 'sgs/src/getStatValue.js';
import addPlus from '../PrintTab/addPlus.js';
import EquipSkillStack from '../EquipSkillStack.mjs';

function newEntity (e, sendMessage) {
    let entityid = e.target.dataset.entityid;
    sendMessage({
        operation: 'add new entity as item',
        entityid,
    });
}

function removeItem (e, sendMessage) {
    let entityid = e.target.dataset.entityid;
    let itemid = e.target.dataset.itemid;
    sendMessage({
        operation: 'remove entity item',
        entityid,
        itemid,
    });
}

function moveItem (e, sendMessage) {
    let entityid = e.target.dataset.entityid;
    let itemid = e.target.dataset.itemid;
    let index = parseInt(e.target.dataset.index);
    let delta = parseInt(e.target.dataset.delta);
    let max = parseInt(e.target.dataset.max) - 1;
    let oldindex = index;
    let newindex = index + delta;
    newindex = Math.min(newindex, max);
    newindex = Math.max(newindex, 0);
    // return;
    sendMessage({
        operation: 'move entity item',
        entityid,
        itemid,
        oldindex,
        newindex
    });
}

function StatLi(props) {
    let groupName = props.groupName;
    let group = props.group;
    let owner = props.owner;
    let doc = props.doc;

    return (
        <li key={groupName}>
            {capitalize(groupName)}:
            {group.map(stat => {
                let value = getStatValue(stat);
                if(groupName.toLowerCase() === 'skills') {
                    value = EquipSkillStack(doc, owner, stat, groupName);
                    value = addPlus(value, groupName, stat);
                }
                value = addPlus(value, groupName, stat)
                return(
                <span key={stat._id}>
                    &nbsp;
                    {value}
                    &nbsp;
                    {stat.name}
                </span>
            )})}
        </li>
    )
}

export default function EquipmentPanel(props) {
    // let [checkedTag, setCheckedTag] = useState('');
    let entity = props.entity;
    let doc = props.doc;
    let editable = entity.editable;
    let sendMessage = props.sendMessage;
    let openModal = props.openModal;
    let closeModal = props.closeModal;
    let state = props.state;
    // console.log(entity.equipment)

    return (
        <div className='panel equipmentpanel'>
            <h3>Equipment ({entity.xpGroups['items'].toLocaleString()} xp)</h3>
            {entity.items.length === 0 && <p className='muted'>none</p>}
            <ul className='equipment'>
                {entity.items.map((item, index) => {
                    let path = '/doc/'
                    + doc._id
                    + '/Entity/'
                    + item._id;
                    return(
                    <li key={item._id}>
                        <Link to={path}>
                            {item.name}
                        </Link>
                        &nbsp;
                        ({item.xp.toLocaleString()}xp)
                        <ul>
                            {editable && <li className='modify'>
                                {editable && index !== 0 && <button 
                            onClick={e => moveItem(e, sendMessage)}
                            data-entityid={entity._id}
                            data-itemid={item._id}
                            data-index={index}
                            data-delta={-1}
                            data-max={entity.items.length}
                            title="move item up"
                        >
                            ▲
                        </button>}
                        &nbsp;
                        {editable && index !== (entity.items.length -1) && <button 
                            onClick={e => moveItem(e, sendMessage)}
                            data-entityid={entity._id}
                            data-itemid={item._id}
                            data-index={index}
                            data-delta={1}
                            data-max={entity.items.length}
                            title="move item down"
                        >
                            ▼
                        </button>}
                        &nbsp;
                        {editable && <button
                            className='delete'
                            onClick={e => removeItem(e, sendMessage)}
                            data-entityid={entity._id}
                            data-itemid={item._id}
                            title="delete item"
                        >
                            x
                        </button>}
                            </li>}
                            
                            {item.limitations.length > 0 && <li>
                                Limits: {item.limitations.map(x => x.name).join(', ')}
                            </li>}
                            {item.aspects.length > 0 && <li>
                                Aspects: {item.aspects.join(', ')}
                            </li>}
                            {item.flaws.length > 0 && <li>
                                Flaws: {item.flaws.join(', ')}
                            </li>}
                            {Sgs.groups.map(groupName => {
                                let group = item[groupName];
                                if(group.length === 0) return('');
                                return(
                                    <StatLi
                                        doc={doc}
                                        key={groupName}
                                        group={group}
                                        groupName={groupName}
                                        owner={entity}
                                    />
                            )})}
                        </ul>
                        <br />
                    </li>
                )})}
                {editable && <li>
                    <button
                        onClick={openModal}
                        data-target='itemModal'
                    >
                        ➕Add Existing Item
                    </button>
                    <button
                        onClick={(e) => newEntity(e, sendMessage)}
                        data-entityid={entity._id}
                    >
                        ➕Add New Entity
                    </button>
                    <ItemSelector
                        parentState={state}
                        sendMessage={sendMessage}
                        closeModal={closeModal}
                        doc={doc}
                        entity={entity}
                    />
                </li>}
            </ul>
        </div>
    )
}

import React from 'react'

import PrimaryStatsTable from './StatsTab/PrimaryStatsTable.jsx';
import TacticalStatsTable from './StatsTab/TacticalStatsTable.jsx';
import ThresholdsTable from './StatsTab/ThresholdsTable.jsx';
import PoolsTable from './StatsTab/PoolsTable.jsx';
import SkillsTable from './StatsTab/SkillsTable.jsx';
import LimitationsTable from './StatsTab/LimitationsTable.jsx';
import Footer from '../Footer.jsx';
import TracksTable from './StatsTab/TracksTable.jsx';

class StatsTab extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            skillSortProp: 'Skill'
        }
    }

    addStat = (statGroup, singular) => {
        let name = prompt('add ' + singular);
        if(!name) return;
        this.props.sendMessage({
            operation: 'add stat',
            statGroup: statGroup,
            statName: name,
        });
    }
    removeStat = (statGroup, statName) => {
        const confirm = window.confirm('Are you sure you want to delete '
        + statName + ' from ' + statGroup + '?');
        if(confirm){
            this.props.sendMessage({
                operation: 'remove stat',
                statGroup: statGroup,
                statName: statName,
            });
        }
    }
    renameStat = (statGroup, statName) => {
        let newName = prompt('rename ' + statName + ' to... ');
        //console.log(statName + '->' + newName);
        if(!newName) return;
        this.props.sendMessage({
            operation: 'rename stat',
            statGroup: statGroup,
            statName: statName,
            newName: newName
        });
    }
    changeSkillGroup = (e) => {
        const statName = e.target.dataset.skillname;
        // console.log(statName);
        const skillGroup = e.target.value;
        this.props.sendMessage({
            operation: 'set skill group',
            statGroup: 'skills',
            statName: statName,
            skillGroup: skillGroup,
        })
    }
    changeSkillStat = (e) => {
        const statName = e.target.dataset.skillname;
        // console.log(statName);
        const skillStat = e.target.value;
        this.props.sendMessage({
            operation: 'set skill stat',
            statGroup: 'skills',
            statName: statName,
            skillStat: skillStat,
        })
    }
    changeLimitMult = (e) => {
        const statName = e.target.dataset.skillname;
        // console.log(statName);
        const mult = e.target.value;
        this.props.sendMessage({
            operation: 'set limit mult',
            statGroup: 'limitations',
            statName: statName,
            mult: mult,
        })
    }
    changeTacOffset = (e) => {
        const statName = e.target.dataset.skillname;
        const offset = e.target.value;
        const statGroup = e.target.dataset.statgroup;
        console.log(offset);
        this.props.sendMessage({
            operation: 'set tac offset',
            statGroup: statGroup,
            statName: statName,
            offset: offset,
        })
    }
    changeFormula = (e) => {
        const value = e.target.value;
        const index = e.target.dataset.index;
        const field = e.target.dataset.field;
        const statName = e.target.dataset.statname;
        const statGroup = e.target.dataset.statgroup;
        this.props.sendMessage({
            operation: 'modify formula',
            statName,
            statGroup,
            opindex: index,
            opfield: field,
            opvalue: value,
        })
    }
    sortSkills = (e) => {
        const prop = e.target.dataset.prop;
        // console.log(prop);
        this.setState({ skillSortProp: prop });

    }
    changeStatValueType = (e) => {
        const statGroup = e.target.dataset.statgroup;
        const statId = e.target.dataset.statid;
        const statName = e.target.dataset.statname;
        const valueType = e.target.value;
        this.props.sendMessage({
            operation: 'change stat value type',
            statGroup,
            statId,
            valueType,
            statName,
        })
    }

    render() {
        let doc = this.props.doc;
        document.title = ['Stats', doc.name, 'SGS'].join(' - ');
        return(<div>            
            <PrimaryStatsTable
                doc={doc}
                addStat={this.addStat}
                removeStat={this.removeStat}
                renameStat={this.renameStat}
                changeTacOffset={this.changeTacOffset}
                changeStatValueType={this.changeStatValueType}
                changeFormula={this.changeFormula}
                sendMessage={this.props.sendMessage}
            />
            <TacticalStatsTable
                doc={doc}
                addStat={this.addStat}
                removeStat={this.removeStat}
                renameStat={this.renameStat}
                changeFormula={this.changeFormula}
                changeTacOffset={this.changeTacOffset}
                changeStatValueType={this.changeStatValueType}
                sendMessage={this.props.sendMessage}
            />
            <ThresholdsTable
                doc={doc}
                addStat={this.addStat}
                removeStat={this.removeStat}
                renameStat={this.renameStat}
                changeType={this.changeType}
                changeValue={this.changeValue}
                changeTacOffset={this.changeTacOffset}
                changeStatValueType={this.changeStatValueType}
                changeFormula={this.changeFormula}
                sendMessage={this.props.sendMessage}
            />
            <TracksTable
                doc={doc}
                sendMessage={this.props.sendMessage}
            />
            <PoolsTable
                doc={doc}
                addStat={this.addStat}
                removeStat={this.removeStat}
                renameStat={this.renameStat}
                changeFormula={this.changeFormula}
                changeType={this.changeType}
                changeValue={this.changeValue}
                changeTacOffset={this.changeTacOffset}
                changeStatValueType={this.changeStatValueType}
                sendMessage={this.props.sendMessage}
                />
            <SkillsTable
                doc={doc}
                addStat={this.addStat}
                removeStat={this.removeStat}
                renameStat={this.renameStat}
                changeSkillGroup={this.changeSkillGroup}
                changeSkillStat={this.changeSkillStat}
                skillSortProp={this.state.skillSortProp}
                sortSkills={this.sortSkills}
                changeTacOffset={this.changeTacOffset}
                changeStatValueType={this.changeStatValueType}
                changeFormula={this.changeFormula}
                sendMessage={this.props.sendMessage}
            />
            <LimitationsTable
                doc={doc}
                addStat={this.addStat}
                removeStat={this.removeStat}
                renameStat={this.renameStat}
                changeLimitMult={this.changeLimitMult}
            />
            <Footer/>
        </div>)
    }
}

export default StatsTab;
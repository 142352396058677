import React from 'react';
import ExpressionNode from './ExpressionNode.jsx';
import './FormulaEditBox.css';

export default function FormulaEditBox(props){
    return(
        <div className='formulaEditBox'>
            <ExpressionNode
                node={props.formula}
                doc={props.doc}
                changeFormula={props.changeFormula}
                statname={props.statname}
                statgroup={props.statgroup}
                editable={props.editable}
            />
        </div>
    )
}
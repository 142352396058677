/**
 * shows UI to change the filter tag lists.
 * @param {Object} list
 * @param {Function} api
 * @param {Functin} performLocalChange
 */
export default function ListEntityFilter(props) {
    const updateUnitFilter = (e) => {
        let newFilter = e.target.value;
        props.api('updateListUnitFilter', {
            newfilter: newFilter,
        })
        let list = props.list;
        list.unitFilter = newFilter;
        props.performLocalChange(list);
    }

    const updateUnitSortOrder = (e) => {
        let newSort = e.target.value;
        props.api('updateListUnitSorting', {
            newsort: newSort,
        })
        let list = props.list;
        list.unitSortOrder = newSort;
        props.performLocalChange(list);
    }

    return(<div>
        <h2>Sort &amp; Filter Entities</h2>
        <label>
            Whitelist Tags
            <input
                defaultValue={props.list.unitFilter}
                placeholder="unit, model"
                onBlur={updateUnitFilter}
            />
        </label>
        <br />
        <br />
        <label>Sort Order
            <select
                value={props.list.unitSortOrder}
                onChange={updateUnitSortOrder}
            >
                <option value=''>none</option>
                <option>name</option>
                <option>name, reversed</option>
                <option>xp descending</option>
                <option>xp ascending</option>
            </select>
        </label>
    </div>)
}
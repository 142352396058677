import React from "react";
import { Link } from 'react-router-dom';
import ListTags from './ListTags.jsx';
import DocTypeButton from './DocTypeButton.jsx';
import Spinner from "./Spinner.jsx";
import CompareProp from "./CompareProp.js";

class TableOfDocs extends React.Component {

    render(){
        if(!this.props.docs) return <Spinner />;
        return (
        <div>
        <ul className='mobile'>
        {this.props.docs?.sort().map((doc) => {
                let classes = [
                    doc.unlisted ? 'unlisted' : '',
                    doc.trashed ? 'trashed' : ''
                ]
                let url = '/doc/' + doc._id;
                return(
                    <li key={doc._id} className={classes.join(' ')}>
                        <Link to={url}>
                            <DocTypeButton
                                type={doc.type}
                                iconOnly={true}
                            />
                            &nbsp;
                            {doc.name}
                            &nbsp;
                            {doc.trashed ? <span title="trashed">❌</span> : ''}
                            {doc.unlisted ? <span title="unlisted">💬</span> : ''}
                        </Link>
                    </li>
                )
        })}
        </ul>

        <table className='desktop'>
            <thead>
                <tr>
                    <th>Doc</th>
                    <th>Type</th>
                    <th>Tags</th>
                </tr>
            </thead>                    
            <tbody>
            {this.props.docs?.sort(CompareProp('name', false, true)).map((doc) => {
                let classes = [
                    doc.unlisted ? 'unlisted' : '',
                    doc.trashed ? 'trashed' : ''
                ]
                let url = '/doc/' + doc._id;
                return (<tr key={doc._id} className={classes.join(' ')}>
                    <td>
                        {doc.trashed ? <span title="trashed">❌</span> : ''}
                        {doc.unlisted ? <span title="unlisted">💬</span> : ''}
                        <a href={url}>{doc.name}</a>
                    </td>
                    <td><DocTypeButton type={doc.type} /></td>
                    <td>
                        <ListTags tags={doc.tags} inline={true}/>
                    </td>
                </tr>)
            })}
            </tbody>
        </table>
        </div>
    )}
}

export default TableOfDocs
const HtmlFormat = {
    h1:     {pre: "<h1>", suf: "</h1>\n"},
    h2:     {pre: "<h2>", suf: "</h2>\n"},
    h3:     {pre: "<h3>", suf: "</h3>\n"},
    h4:     {pre: "<h4>", suf: "</h4>\n"},
    h5:     {pre: "<h5>", suf: "</h5>\n"},
    h6:     {pre: "<h6>", suf: "</h6>\n"},
    ul:     {pre: "<ul>\n", suf: "</ul>\n"},
    li:     {pre: "<li>", suf: "</li>\n"},
    li2:     {pre: "<li>", suf: "</li>\n"},
    table:  {pre: "<table>", suf: "</table>\n"},
    thead:  {pre: "<thead>", suf: "</thead>\n"},
    tbody:  {pre: "<tbody>", suf: "</tbody>\n"},
    tr:     {pre: "<tr>", suf: "</tr>\n"},
    td:     {pre: "<td>", suf: "</td>\n"},
    th:     {pre: "<th>", suf: "</th>\n"},
    p:      {pre: "<p>", suf: "</p>\n"},
    card:   {pre: "<div class='card'>\n", suf: "</div>\n"},
    code:   {pre: "<code>", suf: "</code>"},
    bold:   {pre: "<strong>", suf: "</strong>"},
    italic: {pre: "<em>", suf: "</em>"},
    break:  {pre: "<br />\n", suf: ""},
    line:   {pre: "<hr /\n>", suf: ""},
    wrap:   {pre: "<wrap class='name'>", suf: "</wrap>"},
    span:   {pre: "<span>", suf: "</span>"},
}

export default HtmlFormat;
import React from "react";
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import "./App.css";
import "./dark-style.css";
// import {useHistory} from 'react-router';

// meta pages
import HomePage from "./Pages/HomePage.jsx";
import LoginPage from "./Pages/LoginPage.jsx";
import RegisterPage from "./Pages/RegisterPage.jsx";
import NotFoundPage from "./Pages/NotFoundPage.jsx";
// content pages
import StatusPage from "./Pages/StatusPage.jsx";
import UserPage from "./Pages/UserPage.jsx";
import UserListPage from "./Pages/UserListPage.jsx";
import CampaignPage from "./Pages/CampaignPage.jsx";
import CampaignListPage from "./Pages/CampaignListPage.jsx";
import ListPage from "./Pages/ListPage.jsx";
import ListListPage from "./Pages/ListListPage.jsx";
import TagListPage from "./Pages/TagListPage.jsx";
import TagPage from "./Pages/TagPage.jsx";
import DocListPage from "./Pages/DocListPage.jsx";
import DocPage from "./Pages/DocPage.jsx";
// page chrome stuff
import NavMenu from "./Components/NavMenu.jsx";
import AdminPage from "./Pages/AdminPage.jsx";
import ResetRequest from "./Pages/ResetRequestPage.jsx";
import ResetExecution from "./Pages/ResetExecutionPage.jsx";

function App() {
  // redirect to secure HTTPS connection if insecure
  let isHttps = document.location.protocol === 'https:';
  if(!isHttps
    && !window.location.href.startsWith('http://localhost')
    && !window.location.href.startsWith('http://192.168')
    ) {
    let newUrl = window.location.href.replace('http:', 'https:');
    console.log('connection insecure. Redirecting to ' + newUrl);
    window.location.replace(newUrl);
    return(
      <div>
        <div>
          <h1>Connection Insecure ⚠️</h1>
          <br />
          <p>You are connected by HTTP instead of HTTPS. We're redirecting you for security.</p>
        </div>
      </div>
    )
  }
  return (
    <div>

      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={HomePage}/>
          <Route exact path="/Login" component={LoginPage}/>
          <Route exact path="/Register" component={RegisterPage}/>
          
          <Route exact path="/Users" component={UserListPage}/>
          <Route path="/User/:UserId" component={UserPage}/>
          
          <Route exact path="/Campaigns" component={CampaignListPage}/>
          <Route path="/Campaign/:CampaignId" component={CampaignPage}/>

          <Route exact path="/Lists" component={ListListPage}/>
          <Route path="/List/:ListId" component={ListPage}/>
          
          <Route exact path="/Docs" render={(props) => <DocListPage {...props} type='' />}/>
          <Route path="/Doc/:DocId" component={DocPage}/>

          <Route exact path="/Games" render={(props) => <DocListPage {...props} type='game' />}/>
          <Route path={["/Game/:DocId", "/Doc/:DocId"]} component={DocPage}/>

          <Route exact path="/Mods" render={(props) => <DocListPage {...props} type='mod' />}/>
          <Route path={["/Mod/:DocId", "/Doc/:DocId"]}  component={DocPage}/>

          <Route exact path="/Drafts" render={(props) => <DocListPage {...props} type='draft' />}/>
          <Route path={["/Draft/:DocId", "/Doc/:DocId"]}  component={DocPage}/>

          <Route exact path="/Tags" component={TagListPage}/>
          <Route path="/Tag/:TagName" component={TagPage}/>
          
          <Route exact path="/Status" component={StatusPage}/>
          <Route exact path="/Admin" component={AdminPage}/>
          <Route exact path="/ResetRequest" component={ResetRequest}/>
          <Route exact path="/ResetExecution" component={ResetExecution}/>

          <Route component={NotFoundPage}/>
        </Switch>
        <div className="navpane">
          <NavMenu/>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;

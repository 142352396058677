import React from "react";
import { Link } from 'react-router-dom';
import Spinner from "../Components/Spinner.jsx";
import TableOfCampaigns from '../Components/TableOfCampaigns.jsx';
import UserDataCache from '../Components/UserDataCache.jsx';
import Footer from '../Components/Footer.jsx';

class CampaignListPage extends React.Component{
    constructor (props){
        super(props)
        this.state = {
            campaigns: [],
            gettingCampaigns: true,
        }
    }
    // initializer
    componentDidMount() {
        this.listCampaigns()
    }
    
    // class method
    listCampaigns = () => {
        fetch('/api/listCampaigns', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': UserDataCache.getAuthHeader()
            },
            method: "GET"
        })
        .then(res => res.json())
        .then(campaigns  => this.setState({
            campaigns,
            gettingCampaigns: false,
        }))
    }

    makelink = (session) => {
        return (
            <Link to={{
                pathname: '/session',
                sessionid: session._id,
            }}>
                {session.name}
            </Link>
        )
    }

    render() {
        document.title = ['Campaigns', 'SGS'].join(' - ');
        return (
            <div className="container">
                <h1>Campaigns</h1>
                <TableOfCampaigns campaigns={this.state.campaigns}/>
                {this.state.gettingCampaigns && <Spinner />}
                <Footer />
            </div>
        );
    }
};

export default CampaignListPage;
import React, {useState} from 'react';
import Footer from '../Components/Footer.jsx';
import ReCAPTCHA from 'react-google-recaptcha';
import './reset.css';

const captchaKey = '6LdDpN0pAAAAADkNidzIco4X8lWzI4ZH7MmY_AWY';
const captchaRef = React.createRef();

function onChange(value, setCompleted) {
    console.log('captcha:', value);
    let email = document.getElementById('emailAddress').value;
    console.log(email);
    setCompleted(true);
    fetch('/api/resetpasswordrequest', {
        headers: {
            'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({
            email,
            captcha: value
        })
    })
    .then(res => {
        console.log('completed');
        res.json();
    })
}

export default function ResetRequest (props) {
    const [completed, setCompleted] = useState(false);
    if(completed) {return (
        <div className='container'>
            <h1>Password Reset Request Sent</h1>
            <p>You should receive an email from Voidspiral.com in a few minutes with instructions to reset your password.</p>
            <Footer />
        </div>
    )}
    else { return(
        <div className='container'>
            <h1>Request Password Reset</h1>
            <ReCAPTCHA
                ref={captchaRef}
                sitekey={captchaKey}
                size='invisible'
                onChange={e => onChange(e, setCompleted)}
            />
            <form className='reset' onSubmit={(e) => {
                e.preventDefault();
                captchaRef.current.execute();
                }}>
                <label>
                    Email&nbsp;
                    <input
                        type='email'
                        required={true}
                        id='emailAddress'
                    />
                </label>
                <button
                    type='submit'
                >Request Password Reset</button>
            </form>
            <Footer/>
        </div>
    )}
}
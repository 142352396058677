import StatFromPrototype from "./StatFromPrototype.js";

/**
 * produces a nicely formatted list of thresholds by track.
 * @param {Object} doc the document being processed
 * @param {Object} format the format of the print operation
 * @param {Object} entity the entity beinf processed
 * @param {String} group the stat group being processed
 * @returns 
 */
export default function ThresholdTracks (doc, format, entity, group, namedTag = (x, y) => x) {
    let thresholdList = doc.thresholds.map(x => x._id);
    let output = '';
    // console.log(thresholdList)
    
    output += format.bold.suf;
    output += format.ul.pre;
    doc.tracks.forEach(track => {
        output += namedTag(format.li2.pre, ['track', track.name]);
        output += namedTag(format.bold.pre, 'title');
        output += track.name;
        output += ': ';
        output += format.bold.suf;
        // console.log(track.layers)
        output += track.layers.map(layer => {
            if(!layer.stat) {
                console.log("no stat")
                return ''
            };
            let prototype = doc.thresholds.find(x => x._id.toString() === layer.stat.toString());
            if(!prototype) {
                console.log("no prototype")
                return ''
            };
            // console.log(layer.stat, prototype)
            thresholdList = thresholdList.filter(x => x !== prototype._id.toString());
            return(
                namedTag(format.span.pre, 'stat')
                + StatFromPrototype(doc, prototype, entity, group, format)
                + format.span.suf
                );
            })
            .filter(x => x !== '')
            .join(namedTag(format.span.pre, 'arrow') + ' ▶ ' + format.span.suf);
        let targetPrototype = doc.pools.find(x => x._id.toString() === track.targetStat?.toString());
        if(!targetPrototype) {
            console.error('missing track', track)
            return '';
        }
        // let targetStat = entity.pools.find(x => x.name === targetPrototype.name);
        output += namedTag(format.span.pre, 'arrow') + ' ▶ ' + format.span.suf;
        output += namedTag(format.span.pre, ['stat', 'pool', 'target']);
        output += targetPrototype.name + ' pool';
        output += format.span.suf;
        output += namedTag(format.span.pre, 'period') + '.' + format.span.suf;
        output += format.li.suf;
    })
    // console.log(thresholdList)
    let leftovers = thresholdList.map(remaining => {
        let prototype = doc.thresholds.find(x => x._id.toString() === remaining);
        return(StatFromPrototype(doc, prototype, entity, group, format))
    })
    leftovers = leftovers.filter(x => x !== '');
    if(leftovers.length > 0) {
        console.log("leftovers", leftovers.length, leftovers);
        output += '\t';
        output += format.li.pre;
        output += leftovers.join(', ') + '.';
        output += format.li.suf;
    }
    output += format.ul.suf;
    return output;
}
import React from "react";
import ListTags from './ListTags.jsx';
import UserInline from './UserInline.jsx';

class TableOfUsers extends React.Component {

    render(){
        return (
        <table>
            <thead>
                <tr>
                    <th>User</th>
                    <th>Tags</th>
                </tr>
            </thead>                    
            <tbody>
            {this.props.users?.map((user) => {
                return (<tr key={user._id}>
                    <td>
                        <UserInline user={user} />
                    </td>
                    <td>
                        <ListTags tags={user.tags} inline={true}/>
                    </td>
                </tr>)
            })}
            </tbody>
        </table>
    )}
}

export default TableOfUsers
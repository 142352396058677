import Parenthetical from "./Parenthetical.js";
import UniformGroup from "./UniformGroup.js";

function d(debug, value) {
    if(debug) {
        console.log(value);
    }
}

/**
 * A class for representing and calculating a pool of dice and bonuses.
 */
class DicePool {
    /**
     * Creates a new DicePool object
     * @param {*} string the string representation of the dice pool, such as `1d6+2d8+2`.
     * @param {Boolean} debug default false. When true, dice pool construction will emit debug messages. 
     */
    constructor(string, debug = false) {
        this.node = new Parenthetical();
        // this.string = 0;
        // this.number = 0;
        // return early for blank ctor
        if(string === undefined || string === null || string === '') return;
        this.validateCtor(string);
        this.tokenize(string, debug);
        // console.log(this);
    }

    validateCtor(string) {
        // ERROR HANDLING
        if(typeof string !== 'string' && typeof string !== 'number') {
            throw 'DicePool wanted a number or a string, got `' + string + '` : typeof ' + typeof string;
        }
        if(typeof string === 'number') {
            if(isNaN(string)) {
                throw 'DicePool wanted a number or a string, got NaN';
            }
            string = '' + string;
            // console.log('ctor string was number', string);
        }
        if(string === 'none') return;
        let leftoverParens = 0;
        [...string].forEach(c => {
            if(c === '(') leftoverParens++;
            if(c === ')') leftoverParens--;
        })
        if(string.includes('none')){
            string = string.replace(/none/g, 'd0');
        }
        if(leftoverParens !== 0) {
            this.error = 'DicePool Invalid, unbalanced parens: `' + string + '`';
            console.error(this.error);
            string = '';
        }
        const invalidChars = /[^\dd+÷\-*x✕×/\s\(\)\.]/gi;
        if(!!string.match(invalidChars)) {
            this.error = 'DicePool Invalid, invalid character: `' + string + '`';
            console.error(this.error);
            string = '';
        }
        const invalidStarts = /^[\/÷\*x✕×]/im
        const invalidEndings = /[\/÷\*x✕×+-]$/im
        if(!!string.match(invalidStarts)) {
            this.error = 'DicePool cannot start with that char. `' + string + '`';
            console.error(this.error);
            string = '';
        }
        if(!!string.match(invalidEndings)) {
            // throw 'DicePool cannot end with that char. ' + string;
            this.error = 'DicePool cannot end with that char. `' + string + '`';
            console.error(this.error);
            string = '';
        }
        const divChars = /[\/÷]/;
        const multChars = /[\*x✕×]/;
        string = string.replace(divChars, '/')
        string = string.replace(multChars, '*')
    }

    tokenize(string, debug = false) {
        // d(debug, 'tokenize', string);
        const scaling = '*/';
        const arithmetic = '+-';
        const operators = arithmetic + scaling;
        const root = new Parenthetical();
        root.top = true;
        let currentNode = root;
        let currentContent = '';
        let lastOpType = '';
        for(let i = 0; i < string.length; i++) {
            const char = string[i];
            // NEW PARENTHETICAL token
            if(char === '(') {
                let n = new Parenthetical();
                n.parent = currentNode;
                currentNode.children.push(n);
                currentNode = n;
            }
            // CLOSE PARENTHETICAL token
            else if(char === ')') {
                if(!!currentContent) {
                    currentNode.children.push(parseContent(currentContent));
                }
                currentContent = '';
                currentNode = currentNode.parent;
            }
            // OP token
            else if(operators.includes(char)) {
                let opType = arithmetic.includes(char) ? 'arithmetic' : 'scaling';
                if(!!currentContent) {
                    currentNode.children.push(parseContent(currentContent));
                }
                currentNode.children.push(char);
                currentContent = '';
                lastOpType = opType;
            }
            // CHARACTER token
            else {
                currentContent += char;
            }
            // d(debug, currentContent);
        }
        if(!!currentContent) {
            // d(debug, currentContent);
            currentNode.children.push(parseContent(currentContent));
        }
        root.collapse();
        root.segment();
        root.reduce();
        root.calculate();
        root.collapse();
        // d(debug, root);
        this.node = root;
        // d(debug, this.node);
        // d(debug, string + '\n' + root.debugChildren());
        // this.string = root.toString();
        
    }
    toString() {
        return this.node.toString();
    }
    toObjectOrNumber() {
        let attempt = this.node.isNumber();
        if(attempt !== null) {
            return attempt;
        }
        return this;
    }
    toStringOrNumber() {
        let attempt = this.node.isNumber();
        if(attempt !== null) {
            return attempt;
        }
        return this.node.toString();
    }
    max() {
        return this.node.max;
    }
    min() {
        return this.node.min;
    }
    average() {
        return this.node.med;
    }
}

function parseContent(content) {
    // boxes number values into objects, so that we can uniformly use instanceof later.
    if(content.startsWith('d')) {
        return new UniformGroup('1' + content);
    }
    else if(content.includes('d')) {
        try {
            return new UniformGroup(content);
        }
        catch (e) {
            console.error(e);
            return new Number(0);
        }
    }
    let float = parseFloat(content);
    if(content.includes('.') && !isNaN(float)) {
        return new Number(float);
    }
    let int = parseInt(content);
    if(!isNaN(int)) {
        return new Number(int);
    }
    return content;
}

export default DicePool;
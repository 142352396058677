function collectTags(doc){
    if(!doc.hasOwnProperty('entityTags')) {
        doc.entityTags = [];
    }
    if(!!doc.entities && doc.entities.length > 0) {
        doc.entities.forEach((entity) => {
            if(!!entity.tags && entity.tags.length > 0) {
                entity.tags.forEach((tag) => {
                    if(!doc.entityTags.includes(tag)) {
                        doc.entityTags.push(tag);
                    }
                })
            }
        })
    }
    doc.entityTags.sort();
}

export default collectTags;
// import { ObjectId } from "bson";

export default function processCampaign (campaign) {
    campaign.encounters?.forEach(enc => {
        // combatants
        // console.log(enc)
        enc.enemyTypes.forEach(enemyType => {
            // console.log(enemyType)
            enemyType.combatants = [];
            let index = 0;
            while(enemyType.combatants.length < enemyType.quantity) {
                // let combId = new ObjectId();
                let newComb = {
                    // _id: combId,
                    index,
                };
                enemyType.combatants.push(newComb);
                index++;
            }
        })
        // xp
        let pcxp = 0;
        let enxp = 0;
        enc.percentages = {};
        enc.pcs?.forEach(pc => {
            pcxp += pc.xp * (pc.quantity || 0);
        })
        enc.pcxp = pcxp;
        enc.enemyTypes?.forEach(enemy => {
            enemy.doc = campaign.docs.find(x => x._id.toString() === enemy.docId);
            enemy.entity = enemy.doc?.entities.find(x => x._id === enemy.entityId);
            enemy.xp = (enemy.entity?.xp || 0) * (enemy.quantity || 0);
            enxp += enemy.xp;
        })
        enc.enxp = enxp;
        enc.percentages.max = Math.max(enc.enxp, enc.pcxp);
        if(enc.percentages.max > 0) {
            enc.percentages.pcs = enc.pcxp / enc.percentages.max * 100;
            enc.percentages.enemy = enc.enxp / enc.percentages.max * 100;

            let chunk = enc.pcxp / 4;
            enc.percentages.trivial = chunk * 1 / enc.percentages.max * 100;
            enc.percentages.easy = chunk * 2 / enc.percentages.max * 100;
            enc.percentages.normal = chunk * 3 / enc.percentages.max * 100;
            enc.percentages.danger = chunk * 4 / enc.percentages.max * 100;
            enc.percentages.lethal = chunk * 5 / enc.percentages.max * 100;

            if(enc.percentages.enemy >= 100) {
                enc.percentages.diff = 'Lethal';
                enc.percentages.stars = '⭐⭐⭐⭐⭐';
            }
            if(enc.percentages.enemy < 100) {
                enc.percentages.diff = 'Danger';
                enc.percentages.stars = '⭐⭐⭐⭐';
            }
            if(enc.percentages.enemy < 75) {
                enc.percentages.diff = 'Normal';
                enc.percentages.stars = '⭐⭐⭐';
            }
            if(enc.percentages.enemy < 50) {
                enc.percentages.diff = 'Easy';
                enc.percentages.stars = '⭐⭐';
            }
            if(enc.percentages.enemy < 25) {
                enc.percentages.diff = 'Trivial';
                enc.percentages.stars = '⭐';
            }
        }
        // console.log(enc.percentages);
    })
    return campaign;
}
import React from "react";
import UserDataCache from "../Components/UserDataCache.jsx";
import Sgs from "sgs";
import { Link } from "react-router-dom";
import Spinner from "../Components/Spinner.jsx";
import DocSelector from "../Components/SocketDoc/DocSelector.jsx";
import StatBlocks from "../Components/SocketDoc/PrintTab/Outputs/StatBlocks.js";
import Footer from "../Components/Footer.jsx";
import { ObjectId } from 'bson';
import HtmlFormat from "../Components/SocketDoc/PrintTab/Formats/HtmlFormat.js";
import parse from 'html-react-parser';
import './ListPage.css'
import ListPageEditors from "../Components/ListPageEditors.jsx";
import ListEntityFilter from "../Components/ListEntityFilter.jsx";
import CompareProp from "../Components/CompareProp.js";

export default class ListPage extends React.Component {
    constructor (props){
        super(props)
        this.state = {
            id: this.props.match.params.ListId,
            list: undefined,
            gettingDoc: true,
            editing: false,
            addDocModal: false,
        }
    }
    componentDidMount(){
        this.getList();
    }

    getList = () => {
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': UserDataCache.getAuthHeader(),
            },
            body: JSON.stringify({
                listid: this.state.id,
            })
        }
        fetch('/api/list/getList', options)
        .then(res => res.json())
        .then(info  => {
            if(!info) return;
            let list = this.compileRules(info);
            list = this.calculateArmyCost(list);
            // console.log(list.xp)
            this.setState({list: list});
            document.title = [info.name, 'List', 'SGS'].join(' - ');
            if(UserDataCache.getTokenObject().user._id) {
                this.api('setListValue', {
                    listxp: list.xp,
                })
            }
        })
        .catch(e => {
            console.error(e)
        })
    }

    api = (url, body) => {
        let list = this.state.list;

        fetch('/api/list/' + url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': UserDataCache.getAuthHeader()
            },
            method: "POST",
            body: JSON.stringify({
                listid: list._id,
                ...body
            })
        })
        .then(res => res.json())
        .then(info => {
            console.log('server returned');
        })
    }

    calculateArmyCost = (direct) => {
        // console.log(direct)
        let list = direct || this.state.list;
        let total = 0;
        list.units.forEach(unit => {
            // console.log("unit", unit.doc, unit.entity);
            // console.log(unit.docid, unit.entityid)
            if(!!unit.docid && !!unit.entityid && unit.qty > 0) {
                unit.doc = list.rules.find(x => x._id === unit.docid);
                unit.entity = unit.doc.entities.find( x => x._id === unit.entityid);
            }
            if(!!unit.doc && !!unit.entity && unit.qty > 0) {
                // console.log("found")
                total = total + (unit.entity.xp * unit.qty);
            }
        })
        list.xp = total;
        return list;
    }

    performLocalChange = (list) => {
        let newList = this.compileRules(list);
        this.setState({
            list: newList,
        });
    }

    compileRules = (list) => {
        let newList = list;
        newList.rules.forEach((doc, index) => {
            newList.rules[index] = Sgs.calculateAllXp(doc);
        })
        return newList;
    }

    attemptRenameList = () => {
        let newName = window.prompt('rename this list');
        if(!!newName) {
            this.renameList(newName);
        }
    }
    renameList = (newName) => {
        let list = this.state.list;
        this.api('renameList', {
            listid: list._id,
            newname: newName,
        })
        list.name = newName;
        this.performLocalChange(list);
    }
    addDoc = (doc) => {
        let list = this.state.list;
        this.api('addDoc', {
            docid: doc._id,
        })
        list.rules.push(doc);
        this.performLocalChange(list);
    }
    toggleAddDocModal = () => {
        this.setState({
            addDocModal: !this.state.addDocModal,
        })
    }
    closeModal = (e) => {
        let target = e.target.dataset.target;
        this.setState({
            [target]: false,
        })
    }
    attemptRemoveDoc = (e) => {
        let docid = e.target.dataset.docid;
        let docname = e.target.dataset.docname;
        if(window.confirm("are you sure you want to remove this doc from your army list?\n\n" + docname)) {
            this.removeDoc(docid);
        }
    }
    removeDoc = (docid) => {
        this.api('removeDoc', {
            docid,
        });
        let list = this.state.list;
        list.rules = list.rules.filter(x => x._id.toString() !== docid.toString());
        list.units = list.units.filter(x => x.doc._id.toString() !== docid.toString());
        this.performLocalChange(list);
    }

    addUnit = () => {
        console.log('add unit');
        let list = this.state.list;
        let unitid = new ObjectId();
        if(!list.units) {
            list.units = [];
        }
        list.units.push({
            _id: unitid,
            name: undefined,
            doc: undefined,
            entity: undefined,
            qty: 0,
        });
        this.performLocalChange(list);
        this.api('addUnit', {
            unitid,
        })
    }
    
    attemptRemoveUnit = (unit, index) => {
        let name = !!unit.name ? unit.name : 'Unit ' + index;
        if(window.confirm("Are you sure you want to delete this unit?\n" + name)) {
            this.removeUnit(unit._id);
        }
    }
    removeUnit = (unitid) => {
        console.log('remove unit', unitid);
        let list = this.state.list;
        list = this.calculateArmyCost(list);
        this.performLocalChange(list);
        this.api('removeUnit', {
            unitid,
            listxp: list.xp,
        })
        list.units = list.units.filter(x => x._id.toString() !== unitid.toString());
    }
    setUnitDoc = (e) => {
        let list = this.state.list;
        let unitid = e.target.dataset.unitid;
        let docid = e.target.value;
        let unit = list.units.find(x => x._id.toString() === unitid);
        unit.docid = docid;
        unit.entityid = null;
        list = this.calculateArmyCost(list);
        this.performLocalChange(list);
        this.api('setUnitDoc', {
            unitid,
            docid,
            listxp: list.xp,
        })
    }
    setUnitEntity = (e) => {
        let list = this.state.list;
        let unitid = e.target.dataset.unitid;
        let entityid = e.target.value;
        let unit = list.units.find(x => x._id.toString() === unitid);
        unit.entityid = entityid;
        list = this.calculateArmyCost(list);
        this.performLocalChange(list);
        this.api('setUnitEntity', {
            unitid,
            entityid,
            listxp: list.xp,
        })
    }
    setUnitQuantity = (e) => {
        let list = this.state.list;
        let unitid = e.target.dataset.unitid;
        let qty = e.target.value;
        let unit = list.units.find(x => x._id.toString() === unitid);
        unit.qty = qty;
        list = this.calculateArmyCost(list);
        this.performLocalChange(list);
        this.api('setUnitQty', {
            unitid,
            qty,
            listxp: list.xp,
        })
    }

    setTrashed = (e) => {
        let list = this.state.list;
        let trashed = e.target.checked;
        this.api('setTrashed', {
            trashed,
        })
        list.trashed = trashed;
        this.performLocalChange(list);
    }

    setUnlisted = (e) => {
        let list = this.state.list;
        let unlisted = e.target.checked;
        this.api('setUnlisted', {
            unlisted,
        })
        list.unlisted = unlisted;
        this.performLocalChange(list);
    }

    refreshPage = (e) => {
        this.getList();
    }

    render() {
        let list = this.state.list;
        // document.title = ['List', 'SGS'].join(' - ');
        let xp = !!list?.xp ? '(' + list.xp.toLocaleString() + "xp)" : "";
        let myId = UserDataCache.getTokenObject()?.user._id;
        let editable = !!myId && (list?.owner._id === myId || list?.editors?.map(x => x._id).includes(myId));
        if(!list) {
            return (
                <div className="container">
                <h1>No List</h1>
                <Spinner fullpage={true} />
                <Footer/>
            </div>
            )
        }
        list.units.forEach(unit => {
            unit.doc = list.rules.find(x => x._id.toString() === unit.docid);
            unit.entity = unit.doc?.entities.find(x => x._id.toString() === unit.entityid);
        });
        if(this.state.editing) {
            let tags = list.unitFilter.split(',').map(x => x.trim());
            document.title = ['Edit', list.name, 'List', 'SGS'].join(' - ');
            return (
            <div className="container ListPage">
                <h1>
                    <Link to='/lists'>List: </Link>
                    {list?.name}
                    &nbsp;
                    {xp}
                    &nbsp;
                    <button onClick={() => this.setState({editing: false})}>👁️View List</button>
                    &nbsp;
                    <button onClick={this.attemptRenameList}>📝Rename List</button>
                </h1>
                
                <h2>Editors</h2>
                <ListPageEditors
                    list={list}
                    api={this.api}
                    performLocalChange={this.performLocalChange}
                />

                <h2>Rules</h2>
                <ul>
                    {list.rules.map((doc, index) => {
                    return(<li key={index}>
                        <Link to={'/doc/'+doc._id}>
                            {doc.name}
                        </Link>
                        &nbsp;
                        <button
                            className="delete"
                            onClick={this.attemptRemoveDoc}
                            data-docid={doc._id}
                            data-docname={doc.name}
                        >
                            x
                        </button>
                    </li>)})}
                </ul>
                <button onClick={this.toggleAddDocModal}>Add Doc</button>
                <ListEntityFilter
                    list={list}
                    api={this.api}
                    performLocalChange={this.performLocalChange}
                />
                <hr />
                <h2>Units &amp; Models</h2>
                {list.units.map((unit, index) => {
                    // if(!unit.entity) return '';
                    let heading = unit.name || unit.entity?.name || '';
                    let entities = unit?.doc?.entities
                    .filter(x => {
                        let match = false;
                        for(let i = 0; i < tags.length; i++) {
                            let tag = tags[i];
                            if(x.tags.includes(tag)) {
                                match = true;
                                break;
                            }
                        }
                        return(match);
                    })
                    let sortOrder = list.unitSortOrder;
                    switch(sortOrder?.toLowerCase() || sortOrder?.toLowerCase() || '') {
                        case 'xp':
                        case 'xp ascending':
                            entities = entities?.sort(CompareProp('xp', false, true));
                            break;
                        case 'xp descending':
                            entities = entities?.sort(CompareProp('xp', true, true));
                            break;
                        case 'name, reversed':
                            entities = entities?.sort(CompareProp('name', true, true));
                            break;
                        default:
                        case 'name':
                            entities = entities?.sort(CompareProp('name', false, true));
                            break;
                    }

                    if(!!heading) heading = ': ' + heading;
                return(<div key={index} className="list-unit">
                    <h3>
                        Unit {index+1}{heading}
                        &nbsp;
                        <button
                            className="delete"
                            onClick={() => this.attemptRemoveUnit(unit, index)}
                        >x</button>
                    </h3>

                    <label>Doc<select
                        value={unit.docid || ''}
                        onChange={this.setUnitDoc}
                        data-unitid={unit._id}
                    >
                        <option value=''>none</option>
                        {list?.rules?.map(doc => {return(
                        <option
                            key={doc._id}
                            value={doc._id}
                        >
                            {doc.name}
                        </option>)})}
                    </select></label>

                    <label>Entity<select
                        value={unit.entityid || ''}
                        onChange={this.setUnitEntity}
                        data-unitid={unit._id}
                    >
                        <option value=''>none</option>
                        {entities?.map(entity => {return(
                        <option
                            key={entity._id}
                            value={entity._id}
                        >
                            {entity.name}
                            &nbsp;
                            ({entity.xp})
                        </option>)})}
                    </select></label>

                    <label>Model Xp:&nbsp;
                        {(unit.entity?.xp || 0).toLocaleString()}
                    </label>

                    <label>Quantity&nbsp;<input
                        value={unit.qty || 0}
                        type="number"
                        step={1}
                        min={0}
                        max={1000}
                        placeholder={0}
                        data-unitid={unit._id}
                        onChange={this.setUnitQuantity}
                    /></label>
                    <label>Unit Xp:&nbsp;
                        {((unit.entity?.xp || 0) * (unit.qty || 0)).toLocaleString()}
                    </label>
                    <br/>
                    <br/>
                </div>)})}
                <br />
                <button onClick={this.addUnit}>Add Unit</button>
                
                <hr />
                <h2>Edit List</h2>
                <label>
                    🗑️Trashed
                    &nbsp;
                    <input
                        type="checkbox"
                        checked={list.trashed || false}
                        onChange={this.setTrashed}
                    />
                </label>
                <label>
                    💬Unlisted
                    &nbsp;
                    <input
                        type="checkbox"
                        checked={list.unlisted || false}
                        onChange={this.setUnlisted}
                    />
                </label>

                <DocSelector
                    addParentDoc={this.addDoc}
                    state={this.state}
                    setState={this.setState}
                    closeModal={this.closeModal}
                />
                <Footer />
            </div>
        )}
        document.title = [list.name, 'List', 'SGS'].join(' - ');
        return(
            <div className="container">
                <h1>
                    <span className="printless">
                        <Link to='/lists'>List: </Link>
                    </span>
                    {list?.name}
                    &nbsp;
                    {xp}
                    &nbsp;
                    {editable && <span>
                        <button onClick={() => this.setState({editing:  true})} className="printless">📝Edit List</button>
                        &nbsp;
                        <button onClick={() => window.print()}className="printless" >🖨️Print</button>
                        &nbsp;
                        <button onClick={this.refreshPage}>🔄Refresh</button>
                    </span>}
                </h1>
                {list.units.map((unit, index) => {
                    if(!unit.entity) return '';
                    let heading = unit.name || unit.entity?.name || '';
                    if(!!heading) heading = ': ' + heading;
                    if(!unit.qty || unit.qty < 1) return '';
                    let options = {
                        hideHeader: true,
                        includeEntities: unit.entity?.name,
                    }
                    let format = HtmlFormat;
                    let content = StatBlocks(unit.doc, options, format);
                    return(<div key={index} className="list-unit">
                        <h2>Unit {index+1}{heading}</h2>
                        <p>{unit.entity.xp.toLocaleString()}xp × {unit.qty} models = {(unit.entity.xp * unit.qty).toLocaleString()}xp</p>
                        <div className="remove-heading">{parse(content)}</div>

                    </div>)
                })}
            </div>
        )
    }
}
import React from 'react';
import FormulaEditBox from './FormulaEditBox.jsx';
import StatValueType from './StatValueType.jsx';
import OffsetCell from './OffsetCell.jsx';
import StatDisplayFormatEditor from './StatDisplayFormatEditor.jsx';
import StatDisplayVisibilityEditor from './StatDisplayVisibilityEditor.jsx';
import SetStatIndex from './SetStatIndex.jsx';
import EditStatExponent from './EditStatExponent.jsx';

export default function ThresholdsTable (props) {
    const doc = props.doc;
    const renameStat = props.renameStat;
    const addStat = props.addStat;
    const removeStat = props.removeStat;
    const group = 'thresholds';
    return(<div className='panel'>
        <h4>Thresholds</h4>
        <table>
            <thead>
                <tr>
                    <th>Stat</th>
                    <th>Value Type</th>
                    <th>Formula</th>
                    <th>Value Offset</th>
                    {doc.editable && <th>Value Exponent</th>}
                    {doc.editable && <th>Display Format</th>}
                    {doc.editable && <th>Visibility</th>}
                    {doc.editable && <th>Reorder</th>}
                    {doc.editable && <th>Rename</th>}
                    {doc.editable && <th>Delete</th>}
                </tr>
            </thead>
            <tbody>
                {doc?.thresholds?.map((stat, index) => {
                    let xs = stat.externalSource;
                    let title = !!xs ? 'source ' + xs.type + ' doc: ' + xs.name : null;
                    return(
                    <tr key={stat.name}>
                        <td>
                            <span
                                title={title}
                            >
                                {title && '🔼'}
                                {stat.name}
                            </span>
                        </td>
                        <td>
                            {!stat.editable && 
                                stat.valueType
                            }
                            {stat.editable && <StatValueType
                                stat={stat}
                                statGroup='thresholds'
                                changeStatValueType={props.changeStatValueType}
                            />}
                        </td>
                        <td>
                            {stat.valueType === 'formula' &&
                            <FormulaEditBox
                                doc={doc}
                                changeFormula={props.changeFormula}
                                formula={stat.formula}
                                statname={stat.name}
                                statgroup={group}
                                editable={stat.editable}
                            />}
                        </td>
                        <OffsetCell
                            stat={stat}
                            changeTacOffset={props.changeTacOffset}
                            group={group}
                        />
                        <EditStatExponent 
                            stat={stat}
                            doc={doc}
                            sendMessage={props.sendMessage}
                            group={group}
                        />
                        {doc.editable && <td>
                            <StatDisplayFormatEditor 
                                stat={stat}
                                sendMessage={props.sendMessage}
                                group={group}
                                />
                        </td>}
                        {doc.editable && <td>
                            <StatDisplayVisibilityEditor 
                                stat={stat}
                                sendMessage={props.sendMessage}
                                group={group}
                            />
                        </td>}
                        {doc.editable && 
                            <SetStatIndex
                                doc={doc}
                                groupName={group}
                                stat={stat}
                                startingIndex={index}
                                sendMessage={props.sendMessage}
                            />
                        }
                        {doc.editable && <td>
                            {stat.editable && <button
                            onClick={() => 
                                renameStat(
                                    group,
                                    stat.name
                                )
                            }
                        >
                            rename
                        </button>}</td>}
                        {doc.editable && <td>
                            {stat.editable && <button
                            className='delete'
                            onClick={() => {
                                removeStat(
                                    group,
                                    stat.name
                                )
                            }}
                        >x</button>}</td>}
                    </tr>
                )})}
                {doc.editable && <tr><td colSpan='99'>
                    <button
                        onClick={() => {
                            addStat(
                                group,
                                'Threshold'
                            )
                        }}
                    >
                        Add New
                    </button>
                </td></tr>}
            </tbody>
        </table>
    </div>)
}
import { capitalize } from "../../TextUtils.js";

function addAspect (e, sendMessage) {
    // console.log('add aspect')
    let entityid = e.target.dataset.entityid;
    let type = e.target.dataset.type;
    let name = e.target.dataset.name;
    sendMessage({
        operation: 'add entity aspect',
        entityid,
        type,
        name,
    })
    
}
function setAspect (e, sendMessage) {
    console.log('set aspect')
    let entityid = e.target.dataset.entityid;
    let type = e.target.dataset.type;
    let oldname = e.target.dataset.oldname;
    let name = e.target.value.replace(/\n|\r/g, '');
    sendMessage({
        operation: 'set entity aspect',
        entityid,
        type,
        oldname,
        name,
        source: 'aspects panel',
    })
}
function removeAspect (e, sendMessage) {
    // console.log('remove aspect')
    let entityid = e.target.dataset.entityid;
    let type = e.target.dataset.type;
    let name = e.target.dataset.name;
    sendMessage({
        operation: 'remove entity aspect',
        entityid,
        type,
        name,
    })
}

export default function AspectPanel(props) {
    let entity = props.entity;
    let editable = entity.editable;
    let singular = props.singular;
    let group = props.group;
    let sendMessage = props.sendMessage;
    let hasAspects = entity[group].length > 0;
    // console.log(entity[group])
    let hide = !hasAspects ? ' noprint' : '';
    // console.log(entity);
    return(
        <div className={'' + hide}>
            <h3>{capitalize(group)} ({entity.xpGroups[group].toLocaleString()} xp)</h3>
            <table>
                <thead>
                    <tr>
                        <td>{singular}</td>
                        <td>Xp</td>
                        {editable && <td>Delete</td>}
                    </tr>
                </thead>
                <tbody>
                    {entity[group].map((aspect, index) => {return(
                        <tr key={index}>
                            <td>
                                {editable && <textarea
                                    defaultValue={aspect}
                                    onBlur={e => setAspect(e, sendMessage)}
                                    data-entityid={entity._id}
                                    data-type={group}
                                    data-oldname={aspect}
                                    key={index}
                                />}
                                {!editable &&
                                    <span>{aspect}</span>
                                }
                            </td>
                            <td>10</td>
                            {editable && <td>
                                <button
                                    className='delete'
                                    onClick={e => removeAspect(e, sendMessage)}
                                    data-entityid={entity._id}
                                    data-type={group}
                                    data-name={aspect}
                                >
                                    x
                                </button>
                            </td>}
                        </tr>
                    )})}
                    {editable && <tr>
                        <td colSpan='3'>
                            <button
                                onClick={e => addAspect(e, sendMessage)}
                                data-entityid={entity._id}
                                data-type={group}
                                data-name={'new ' + singular}
                            >
                                ➕Add New Aspect
                            </button>
                        </td>
                    </tr>}
                </tbody>
            </table>
        </div>
    )
}
import React from "react";
import Footer from "../Components/Footer.jsx";
import Spinner from "../Components/Spinner.jsx";
import TableOfDocs from '../Components/TableOfDocs.jsx';
import { capitalize } from '../Components/TextUtils.js';
import UserDataCache from '../Components/UserDataCache.jsx';

class DocListPage extends React.Component{
    constructor (props){
        super(props)
        this.state = {
            docs: [],
            gettingDocs: true,
        }
    }
    // initializer
    componentDidMount() {
        this.listDocs()
    }
    
    // class method
    listDocs = () => {
        fetch('/api/listDocs', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': UserDataCache.getAuthHeader()
            },
            body: JSON.stringify({
                type: this.props.type
            })
        })
        .then(res => res.json())
        .then(docs  => {
            if(!docs) return;
            this.setState({
                docs,
                gettingDocs: false,
            })
        })
    }

    render() {
        let header = "Docs";
        if(this.props.type) header = capitalize(this.props.type) + "s";
        document.title = [header, 'SGS'].join(' - ');
        return (
            <div className="container">
                <h1>{header}</h1>
                <TableOfDocs docs={this.state.docs} />
                {this.state.gettingDocs && <Spinner />}
                <Footer/>
            </div>
        );
    }
};

export default DocListPage;
export default function StatDisplayFormatEditor(props) {
    const changeDisplayFormat = (e) => {
        let newFormat = e.target.value;
        let oldFormat = e.target.dataset.originalvalue;
        // console.log(newFormat);
        if(newFormat === oldFormat) return;
        props.sendMessage({
            operation: 'change stat display format',
            statId: props.stat._id,
            statGroup: props.group,
            displayFormat: newFormat,
        })
    }
    return(
        <input
            type="text"
            defaultValue={props.stat.displayFormat || ""}
            placeholder="+X"
            data-originalvalue={props.stat.displayFormat}
            title={"enter any string containing `X`\n`X` will be replaced with the stat value"}
            onBlur={changeDisplayFormat}
        />
    );   
}
import React from "react";
import SocketDocEdit from '../Components/SocketDocEdit.jsx';
import UserDataCache from "../Components/UserDataCache.jsx";
import Sgs from 'sgs';
import Spinner from "../Components/Spinner.jsx";
// import Footer from "../Components/Footer";

class DocPage extends React.Component {
    constructor (props){
        super(props)
        this.id = this.props.match.params.DocId;
        this.state = {
            doc: undefined,
            campaigns: [],
            children: [],
            gettingDoc: true,
        }
        this._isMounted = false;
    }
    componentDidMount(){
        this._isMounted = true;
        if(this._isMounted) {
            this.getDoc();
            this.getCampaigns();
            this.getChildDocs();
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    getDoc = () => {
        // console.log("client > get session")
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': UserDataCache.getAuthHeader(),
            },
            body: JSON.stringify({
                gameid: this.id
            })
        }
        fetch('/api/getDoc', options)
        .then(res => res.json())
        .then(info  => {
            if(!info) return;
            let d = Sgs.calculateAllXp(info, false)
            this.setState({
                doc: d
            }, this.determineType);
        })
        .catch(e => {
            console.error(e)
        })
    }
    getCampaigns = () => {
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': UserDataCache.getAuthHeader(),
            },
            body: JSON.stringify({
                docid: this.id
            })
        }
        fetch('/api/getCampaignsUsingDoc', options)
        .then(res => res.json())
        .then(info  => {
            if(!info) return;
            this.setState({
                campaigns: info
            })
        });
    }
    getChildDocs = () => {
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': UserDataCache.getAuthHeader(),
            },
            body: JSON.stringify({
                docid: this.id
            })
        }
        fetch('/api/getChildDocs', options)
        .then(res => res.json())
        .then(info => {
            if(!info) return;
            this.setState({
                children: info
            })
        });
    }

    render () {
        const doc = this.state.doc;
        if (doc) {
            return (
            <div className="container DocPage">
                <SocketDocEdit
                    docId={this.id}
                    initialName={doc.name}
                    children={this.state.children}
                    campaigns={this.state.campaigns}
                    location={this.props.location}
                    determineType={this.determineType}
                />
                {/* <Footer/> */}
            </div>
        )}
        else {return (
            <div className="container">
                <h1>No Doc</h1>
                <Spinner fullpage={true} />
                {/* <Footer/> */}
            </div>
        )}
    }
};

export default DocPage;
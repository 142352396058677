import { calcAll, calcSingle } from './calcAll.js';
import prototypeFormulas from './prototypeFormulas.js';
import collectTags from './collectTags.js';
import operations from './Operations.js';
import groups from './groups.js';
import setEditable from './setEditable.js';
import mergeParents from './mergeParents.js';
import DicePool from './DicePool/DicePool.js';
import Tnl from './Tnl.js';

// TODO: Fix `calcSingle` for optimization.
// When overrideSingleEntity is false, new entities do not immediately trigger a save and refresh.
function calculateAllXp (doc, editable = false, oldDoc = null, overrideSingleEntity = true) {
    // console.log('calculateAllXp')
    if(!doc) return null;
    // console.log("oldDoc", oldDoc)
    // console.log("activeEntity", doc.activeEntity)
    if(!overrideSingleEntity && doc.activeEntity && !!oldDoc && oldDoc != {} ) {
        // console.log("Update Single Entity");
        var newDoc = doc;
        doc = calcSingle(oldDoc, newDoc);
        collectTags(doc);
        setEditable(doc, editable);
    }
    else {
        mergeParents(doc, doc);
        // console.log("update entire doc");
        doc = prototypeFormulas(doc);
        doc = calcAll(doc);
        // console.log(doc.total);
        collectTags(doc);
        setEditable(doc, editable);
    }
    return doc;
}

export const Sgs = {
    calculateAllXp,
    operations,
    groups,
    DicePool,
    Tnl,
}

// ESM
export default Sgs;
import React from "react";
import TableOfCampaigns from '../Components/TableOfCampaigns.jsx';
import ListTags from '../Components/ListTags.jsx';
import UserDataCache from "../Components/UserDataCache.jsx";
import TableOfDocs from "../Components/TableOfDocs.jsx";
import { CreateConsole } from "../Components/CreateConsole.jsx";
import Modal from "../Components/Modal.jsx";
import Valid from "../Components/Valid.jsx"; 
import Spinner from "../Components/Spinner.jsx";
import Footer from '../Components/Footer.jsx';
import TableOfLists from "../Components/TableOfLists.jsx";

class UserPage extends React.Component {
    constructor (props){
        super(props)
        this.id = this.props.match.params.UserId
        // console.log(this.id)
        this.state = {
            user: {},
            gmCampaigns: false,
            bannedCampaigns: false,
            participatingCampaigns: false,
            mylists: false,
            mydocs: false,
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
            oldPassword_valid: null,
            newPassword_valid: null,
            confirmPassword_valid: null,
        }
    }
    
    componentDidMount(){
        this.getUser(() => {
            this.getGMCampaigns();
            this.getParticipatingCampaigns();
            this.getBannedCampaigns();
            this.getMyDocs();
            this.getMyLists();
        });
    }
    
    getUser = (cb) => {
        // console.log("client > get session")
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': UserDataCache.getAuthHeader(),
            },
            body: JSON.stringify({
                userId: this.id
            })
        }
        fetch('/api/getUser', options)
        .then(res => res.json())
        .then(info  => this.setState({user: info}, () => cb()))
    }
    
    getGMCampaigns = () => {
        if(!this.state.user) return;
        fetch('/api/getGmingCampaigns', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': UserDataCache.getAuthHeader(),
            },
            body: JSON.stringify({
                userId: this.id,
                requesterId: UserDataCache.getTokenObject()?.user._id,
            })
        })
        .then(res => res.json())
        .then(results => {
            // console.log(results);
            this.setState({gmCampaigns: results})
        })
    }
    
    getParticipatingCampaigns = () => {
        if(!this.state.user) return;
        fetch('/api/getParticipatingCampaigns', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': UserDataCache.getAuthHeader(),
            },
            body: JSON.stringify({
                userId: this.id,
                requesterId: UserDataCache.getTokenObject()?.user._id,
            })
        })
        .then(res => res.json())
        .then(results => {
            // console.log(results);
            this.setState({participatingCampaigns: results})
        })
    }
    
    getBannedCampaigns = () => {
        if(!this.state.user) return;
        fetch('/api/getBannedCampaigns', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': UserDataCache.getAuthHeader(),
            },
            body: JSON.stringify({
                userId: this.id,
                requesterId: UserDataCache.getTokenObject()?.user._id,
            }),
        })
        .then(res => res.json())
        .then(results => {
            // console.log(results);
            this.setState({bannedCampaigns: results})
        })
    }

    getMyLists = () => {
        if(!this.state.user) return;
        fetch('/api/getMyLists', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': UserDataCache.getAuthHeader(),
            },
            body: JSON.stringify({
                userId: this.state.user._id,
                requesterId: UserDataCache.getTokenObject()?.user._id,
            }),
        })
        .then(res => res.json())
        .then(results => {
            // console.log(results);
            this.setState({
                mylists: results,
                gettingLists: false,
            })
        })
    }

    updatePassword = (oldpassword, newpassword) => {
        if(!this.state.user) return;
        fetch('/api/updatePassword', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': UserDataCache.getAuthHeader(),
            },
            body: JSON.stringify({
                userId: this.id,
                newpassword,
                oldpassword,
            }),
        })
        .then(res => res.json())
        .then(results => {
            console.log(results);
        });
    };

    banUser = () => {
        if(!this.state.user) return;
        fetch('/api/updateUserStatus', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': UserDataCache.getAuthHeader(),
            },
            body: JSON.stringify({
                userId: this.id,
                newstatus: 'BANNED'
            }),
        })
        .then(res => res.json())
        .then(results => {
            this.setState({ user: results });
        });
    }

    shadowbanUser = () => {
        if(!this.state.user) return;
        fetch('/api/updateUserStatus', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': UserDataCache.getAuthHeader(),
            },
            body: JSON.stringify({
                userId: this.id,
                newstatus: 'SHADOWBANNED'
            }),
        })
        .then(res => res.json())
        .then(results => {
            this.setState({ user: results });
        });
    }

    unbanUser = () => {
        if(!this.state.user) return;
        fetch('/api/updateUserStatus', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': UserDataCache.getAuthHeader(),
            },
            body: JSON.stringify({
                userId: this.id,
                newstatus: 'USER'
            }),
        })
        .then(res => res.json())
        .then(results => {
            this.setState({ user: results });
        });
    }

    getMyDocs = () => {
        if(!this.state.user) return;
        fetch('/api/getMyDocs', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': UserDataCache.getAuthHeader(),
            },
            body: JSON.stringify({
                userId: this.state.user._id,
                requesterId: UserDataCache.getTokenObject()?.user._id,
            }),
        })
        .then(res => res.json())
        .then(results => {
            // console.log(results);
            this.setState({mydocs: results})
        })
    }

    openModal = (e) => {
        let target = e.target.dataset.target;
        this.setState({
            [target]: true,
        })
    }
    closeModal = (e) => {
        let target = e.target.dataset.target;
        this.setState({
            [target]: false,
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
            oldPassword_valid: null,
            newPassword_valid: null,
            confirmPassword_valid: null,
        })
    }
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const validation = name + '_valid';
        const valid = this.isValid(name, value);
        this.setState({
            [name]: value,
            [validation]: valid,
        });
    }
    isValid = (name, value) => {
        if(value === undefined || value === null) {
            return null;
        }
        if(name === 'oldPassword' && !!value) {return true;}
        if(name === 'newPassword'
            && value.length > 7) {
            if(this.state.confirmPassword !== value) {
                this.setState({
                    confirmPassword_valid: false,
                })
            }
            return true;
        }
        if(name === 'confirmPassword'
            && value === this.state.newPassword) {return true;}
        return false;
    }
    handlePasswordSubmit = (e) => {
        e.preventDefault();
        this.updatePassword(this.state.oldPassword,
            this.state.newPassword);
        this.setState({
            changePassword: false,
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
            oldPassword_valid: null,
            newPassword_valid: null,
            confirmPassword_valid: null,
        });
    }

    render () {
        const token = UserDataCache.getTokenObject();
        const user = this.state.user;
        // console.log(user);
        let emailhash = user?.emailhash;
        // determine editing privelages
        const isUser = !!user && user._id === token?.user._id;
        const isAdmin = token?.user.userLevel === 'ADMIN';
        const canDemote = (token?.user.userLevel === 'MOD'
        || token?.user.userLevel === 'ADMIN')
        && user.userLevel !== 'MOD'
        && user.userLevel !== 'ADMIN';
        const canEdit = isUser || canDemote || isAdmin;
        // render
        if (user){
            document.title = [user.username, 'SGS'].join(' - ');
            return (
            <div className="container">
                <h1><a href="/users">User</a>: {user.username}</h1>
                <img
                    src={'https://www.gravatar.com/avatar/'
                    + emailhash
                    + '?d=retro'}
                    alt={user.username + ' avatar'}
                />
                <p>
                    user id:
                    &nbsp;
                    {this.id}
                    &nbsp;
                    {user.userLevel}
                </p>
                
                {!!user.tags && <div><h2>Tags</h2>
                    <ListTags tags={user.tags} inline={true} />
                </div>}
                {isUser && <CreateConsole
                    getMyDocs={this.getMyDocs}
                />}
                
                {this.state.mydocs?.length > 0 &&
                    <div>
                        <h2>My Docs:</h2>
                        <TableOfDocs
                            docs={this.state.mydocs}/>
                    </div>
                }

                {this.state.gmCampaigns?.length > 0 ?
                <div>
                    <h2>Game Master In:</h2> 
                    <TableOfCampaigns
                        campaigns={this.state.gmCampaigns}/>
                </div>
                : ''}
                
                {this.state.participatingCampaigns?.length > 0 ? 
                    <div>
                        <h2>Participating In:</h2> 
                        <TableOfCampaigns
                            campaigns={this.state.participatingCampaigns}/>
                    </div>
                : '' }  
                
                {this.state.bannedCampaigns?.length > 0 ?
                    <div>
                        <h2>Banned In:</h2>
                        <TableOfCampaigns
                            campaigns={this.state.bannedCampaigns}/>
                    </div>
                : '' }
                {this.state.mylists?.length > 0 && <div>
                    <h2>My Army Lists</h2>
                    <TableOfLists 
                        lists={this.state.mylists}
                    />
                </div>}
                {canEdit && 
                    <div>
                        <h2>Edit User</h2>
                        <ul>
                            <li>
                                <button
                                    onClick={this.openModal}
                                    data-target='changePassword'
                                >
                                    Change Password
                                </button>
                            </li>
                            {canDemote && <li><button
                                onClick={this.shadowbanUser}>
                                Shadowban User
                            </button></li>}
                            {canDemote && <li><button
                                onClick={this.banUser}>
                                Ban User
                            </button></li>}
                            {canDemote && <li><button
                                onClick={this.unbanUser}>
                                Unban User
                            </button></li>}
                        </ul>
                    </div>
                }
                <Modal
                    state={this.state}
                    closeModal={this.closeModal}
                    target='changePassword'
                    title='Change Password'
                >
                    <form onSubmit={this.handlePasswordSubmit}>
                        <label>
                            Old Password
                            &nbsp;
                            <input
                                type='password'
                                value={this.state.oldPassword}
                                name='oldPassword'
                                onChange={this.handleInputChange}
                                />
                            <Valid value={this.state.oldPassword_valid}/>
                        </label>
                        <label>
                            New Password
                            &nbsp;
                            <input
                                type='password'
                                value={this.state.newPassword}
                                name='newPassword'
                                onChange={this.handleInputChange}
                            />
                            <Valid value={this.state.newPassword_valid}/>
                        </label>
                        <label>
                            Confirm New Password
                            &nbsp;
                            <input
                                type='password'
                                value={this.state.confirmPassword}
                                name='confirmPassword'
                                onChange={this.handleInputChange}
                            />
                            <Valid value={this.state.confirmPassword_valid}/>
                        </label>
                        <input
                            type='submit'
                            disabled={
                                !(this.state.oldPassword_valid
                                && this.state.newPassword_valid
                                && this.state.confirmPassword_valid
                                )
                            }
                        />
                    </form>
                </Modal>
                <Footer/>
            </div>
        )}
        else { return (
            <div className="container">
                <h1>User: none</h1>
                <p>user id: {this.id}</p>
                <Spinner fullpage={true} />
                <Footer/>
            </div>
        )}
    }
};

export default UserPage;
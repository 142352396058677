import React from 'react'
import ListTags from '../ListTags.jsx';

class TagsTab extends React.Component{
    addTag = (tag) => {
        let id = this.props.doc._id;
        // console.log(tag);
        this.props.sendMessage({
            operation: 'add doc tag',
            id,
            tag,
        })
    }
    removeTag = (tag) => {
        let id = this.props.doc._id;
        console.log(tag);
        this.props.sendMessage({
            operation: 'remove doc tag',
            id,
            tag,
        })
    }

    render() {
        const doc = this.props.doc;
        return(<div>
            <h3>Doc Tags</h3>
            <ListTags
                tags={doc?.tags}
                addTag={this.addTag}
                removeTag={this.removeTag}
                doc={this.props.doc}
            />
            <h3>Entity Tags</h3>
            <ul>
                {doc?.entityTags?.map(tag => {return(
                    <li key={tag}>#{tag}</li>
                )})}
                {(!doc?.entityTags || doc?.entityTags.length === 0)
                    && <li>none</li>}
            </ul>
        </div>)
    }
}

export default TagsTab;
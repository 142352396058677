export default function PcRow (props) {
    let pc = props.pc;
    let enc = props.enc;
    // let exists = !!pc && (!!pc.name || !!pc.xp);
    return(
    <tr>
        <td>
            <input
                placeholder="PC Name"
                defaultValue={pc.name}
                data-encounterid={enc._id}
                data-pcid={pc._id}
                onBlur={props.setPcName}
            />
        </td>
        <td>
            <input
                type='number'
                step={1}
                placeholder="XP value"
                defaultValue={pc.xp}
                data-encounterid={enc._id}
                data-pcid={pc._id}
                onBlur={props.setPcXp}
            />
        </td>
        <td>
            <input
                type='number'
                step={1}
                placeholder="Quantity"
                defaultValue={pc.quantity}
                data-encounterid={enc._id}
                data-pcid={pc._id}
                onChange={props.setPcQty}
            />
        </td>
        <td>
            <button
                className="delete"
                data-encounterid={enc._id}
                data-pcid={pc._id}
                onClick={props.deletePc}
            >
                x
            </button>
        </td>
    </tr>
)}
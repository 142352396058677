import operations from "./Operations.js";

/**
 * Calculates the value of a node
 * @param {Doc} doc the document to use when calculating 
 * @param {*} entity the owner of the stat
 * @param {*} node the node to calculate
 * @returns A number or DicePool
 */
function calcNode(doc, entity, node) {
    // console.log(node.op);
    let op = operations[node.op];
    // console.log(node, op)
    let calced = op.calc(doc, entity, node);
    if(calced === undefined) return 0;
    return calced;
}

export default calcNode;
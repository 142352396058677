import "./PortraitPanel.css";
import AttemptChangePortrait from "./AttemptPortraitChange.js";

export default function PortraitPanel(props) {
    let entity = props.entity;
    let editable = entity.editable;
    let sendMessage = props.sendMessage;
    let url = entity.imageurl;
    let hasImage = !url ? ' noprint' : '';
    // url = 'https://imgur.com/WpMjj3S.png';
    const imgurPattern = /^https:\/\/imgur.com\/\w+$/gm;
    if(url && url.match(imgurPattern)) {
        url += '.png';
    }
    if(!editable && !url) return '';
    if(!editable && url) return (
        <div className="panel">
            <img
                src={url}
                alt='entity portrait'
                className="portrait"
            />
        </div>
    )
    return (
        <div className={"panel portraitpanel" + hasImage}>
            <button
                onClick={() => AttemptChangePortrait(entity, sendMessage)}
            >
                {url && <img
                    alt='entity portrait'
                    src={url}
                    className="portrait"
                />}
                {!url && <span
                    title='Change Image Url'
                >
                    📸
                </span>}<br/>
                Change Image Url
            </button>
        </div>
    )
}
import { capitalize } from '../../../TextUtils.js';
import Sgs from 'sgs';
import addPlus from '../addPlus.js';
import SortEntities from '../SortEntities.js';
import FilterEntities from '../FilterEntities.js';
import LimMultFormat from '../LimMultFormat.js';
import GetPrototypes from '../GetPrototypes.js';
import ThresholdTracks from './ThresholdTracks.js';
import ItemStatList from './ItemStatList.js';

export default function EntityCards (doc, options, format) {
    let entities = SortEntities(options, doc.entities);
    entities = FilterEntities(options, entities);
    let prototypes = GetPrototypes(doc);

    if(!prototypes) return '';
    if(!entities) return '';

    let output = '';
    entities.forEach(entity => {
        output += entityCard(entity, doc, prototypes, format);
    })
    return output;
}

/**
 * Adds the name of the tag and the title of the content of the tag to the tag's class names,
 * so that they can be targetted in the CSS for later formatting in print.
 * @param {string} tag the tag that is being specified
 * @param {(string|Array.<string>)} content the class names to apply to the tag,
 * including the type and content, in either a string or an array of strings.
 * @returns {string} a formatted starting html tag: `<span class="limit no-actions" >`
 */
const namedTag = (tag, content) => {
    if(Array.isArray(content)){
        // console.log(content)
        content = content.map(x => x.replaceAll(' ', '-').toLowerCase());
        // console.log('replaced all ', content)
        content = content.join(' ');
    }
    else {
        content = content.replaceAll(' ', '-').toLowerCase();
    }
    if(tag.includes('class')){
        const pattern = /(?:class=["'])(.*)(?:['"])/;
        let matches = tag.match(pattern);
        let newTag = tag;
        if(matches.length > 1) {
            newTag = tag.replace(matches[0], 'class="' + matches[1] + " " + content + '"');
        }
        else {
            newTag = tag.replace(matches[0], 'class="' + content + '"');
        }
        // console.log(matches);
        return newTag;
    }
    else {
        return tag.replace('>', ' class="' + content + '" >');
    }
}

const sep = (format, char) => {
    return namedTag(format.span.pre, 'comma') + char + ' ' + format.span.suf;
}

const entityCard = (entity, doc, prototypes, format) => {
    // console.log(entity.name);
    let output = '';
    output += namedTag(format.card.pre, [entity.name, ...entity.tags]);
    output += namedTag(format.h5.pre, 'name');
    output += namedTag(format.span.pre, 'name');
    output += entity.name;
    output += format.span.suf;
    output += namedTag(format.span.pre, 'xp');
    output += ' (' + entity.xp.toLocaleString() + 'xp)';
    output += format.span.suf;
    output += format.h5.suf;
    if(entity.limitations.length > 0) {
        output += namedTag(format.p.pre, 'limits');
        output += namedTag(format.span.pre, 'title');
        output += 'Limits: ';
        output += format.span.suf;
        output += entity.limitations.map(limit => {
            let o = '';
            o += limit.name;
            o += ' (' + LimMultFormat(limit.multiplier) + ')';
            if(!!limit.notes) {
                o += ' ' + limit.notes;
            }
            return namedTag(format.span.pre, 'limit') + o + format.span.suf;
        })
        .join(namedTag(format.span.pre, 'comma') + ', ' + format.span.suf);
        output += format.p.suf;
    }
    if(entity.tags.length > 0) {
        output += namedTag(format.p.pre, 'tags');
        output += namedTag(format.span.pre, 'title');
        output += namedTag(format.span.pre, 'tag-header')
        output += format.bold.pre;
        output += 'Tags: ';
        output += format.bold.suf;
        output += format.span.suf;
        output += format.span.suf;
        output += entity.tags.map(x => namedTag(format.span.pre, ['tag', x]) + x  + format.span.suf).join(sep(format, ','));
        output += format.p.suf;
    }
    if(entity.aspects.length > 0) {
        output += namedTag(format.p.pre, 'aspects');
        output += namedTag(format.span.pre, 'title');
        output += format.bold.pre;
        output += 'Aspects: ';
        output += format.bold.suf;
        output += format.span.suf;
        output += entity.aspects.map(x => namedTag(format.span.pre, 'aspect') + x  + format.span.suf).join(sep(format, ','));
        output += format.p.suf;
    }
    if(entity.flaws.length > 0) {
        output += namedTag(format.p.pre, 'flaws');
        output += namedTag(format.span.pre, 'title');
        output += format.bold.pre;
        output += 'Flaws: ';
        output += format.bold.suf;
        output += format.span.suf;
        output += entity.flaws.map(x => namedTag(format.span.pre, 'aspect') + x  + format.span.suf).join(sep(format, ','));
        output += format.p.suf;
    }
    // output += format.line.pre;
    Sgs.groups.forEach(group => {
        if(entity[group].length === 0) return;
        // entity[group].forEach(x => {if(!x.prototype) {
        //     console.log(group, x)
        // }});
        let shown = entity[group].filter(x => !!x.prototype && (!x.prototype.displayVisibility || x.prototype.displayVisibility === 'owners'));
        if(shown.length === 0 && entity.items.length > 0) return;
        output += namedTag(format.p.pre, [group, 'section']);
        output += format.bold.pre;
        output += namedTag(format.span.pre, 'title');
        output += capitalize(group);
        output += namedTag(format.span.pre, 'colon');
        output += ':';
        output += format.span.suf;
        output += format.span.suf;
        if(group === 'thresholds' && !!doc.tracks && doc.tracks.length > 0) {
            output += ThresholdTracks(doc, format, entity, group, namedTag);
        }
        else {
            output += format.bold.suf;
            output += ' ';
            let prototypes = doc[group];
            output += prototypes
            .filter(proto => entity[group].map(x => x.name).includes(proto.name))
            .map(prototype => {
                let stat = entity[group].find(x => x.name === prototype.name);
                let o = '';
                o += stat.name + ' ';
                if(!!stat.skillGroup) {
                    o += '(' + stat.skillGroup + ') ';
                }
                if(stat.dieSize !== 'none') {
                    o += '1' + stat.dieSize;
                }
                if(!!stat.dicePool) {
                    o += stat.dicePool;
                }
                if(stat.valueType === 'formula') {
                    o += stat.calculated;
                }
                if(stat.valueType === 'number') {
                    let skillStatName = prototype?.skillStat;
                    if(!!skillStatName) {
                        let skillStat = entity.primaries.find(primary => 
                            primary.name === skillStatName)
                            if(!!skillStat) {
                                o += addPlus(stat.number + skillStat.number, group, stat);
                            }
                            else {
                                o += addPlus(stat.number, group, stat);
                            }
                    }
                    else {
                        o += addPlus(stat.number, group, stat);
                    }
                }
                o = namedTag(format.span.pre, ['stat', prototype.name]) + o + format.span.suf;
                return o;
            })
            .join(sep(format, ','));
            output += sep(format, '.');
        }
        output += format.p.suf;
    })
    if(entity.items.length !== 0) {
        // output += 'Equipment\n';
        entity.items.forEach(item => {
            output += namedTag(format.p.pre, ['item section', item.name]);
            output += namedTag(format.span.pre, 'title');
            output += format.bold.pre;
            output += item.name;
            output += format.bold.suf;
            output += format.span.suf;
            output += namedTag(format.span.pre, 'xp');
            output += ' (' + item.xp + 'xp) ';
            output += format.span.suf;
            if(item.limitations.length > 0) {
                output += namedTag(format.span.pre, 'limits');
                output += 'Limits: ';
                output += item.limitations.map(limit => {
                    let o = limit.name;
                    if(limit.note) {
                        o += ' ' + limit.note;
                    }
                    return o;
                })
                .join(sep(format, ','));
                output += sep(format, '.');
                output += format.span.suf;
            }
            if(item.aspects.length > 0) {
                output += namedTag(format.span.pre, 'aspects');
                output += 'Aspects: ' + item.aspects.map(x => namedTag(format.span.pre, 'aspect') + x + format.span.suf).join(sep(format, ','));
                output += format.span.suf;
                output += sep(format, '.');
            }
            if(item.flaws.length > 0) {
                output += namedTag(format.span.pre, 'flaws');
                output += 'Flaws: ' + item.flaws.map(x => namedTag(format.span.pre, 'flaw') + x + format.span.suf).join(sep(format, ','));
                output += format.span.suf;
                output += sep(format, '.');
            }
            Sgs.groups.forEach(group => {
                if(item[group].length === 0) return;
                // output += capitalize(group) + ': ';
                output += namedTag(format.span.pre, 'stat');
                output += ItemStatList(doc, entity, group, item, true).join(sep(format, ','));
                output += format.span.suf;
                output += sep(format, '.');
            })
            output += format.p.suf;
        })
    }
    output += format.card.suf;
    return output;
}
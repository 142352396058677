const maxDepth = 50;
function mergeParents (original, doc, depth = 0) {
    // console.log(original, doc);
    if(!original || !doc) return;
    // console.log('depth', depth);
    if(depth > maxDepth) return console.error('max depth reached!');
    // apply elements
    let groups = [
        'primaries',
        'tacticals',
        'thresholds',
        'pools',
        'skills',
        'limitations',
        'entities',
        'tracks',
    ]
    groups.forEach(gname => {
        let sourceGroup = doc[gname];
        let targetGroup = original[gname];
        // console.log(gname, sourceGroup.length)
        if(!!sourceGroup && !!targetGroup) {
            sourceGroup.forEach(sourceElement => {
                let foundElement = null;
                targetGroup.forEach(targetElement => {
                    if((targetElement.name === sourceElement.name)
                        || targetElement._id === sourceElement._id) {
                        foundElement = targetElement;
                    }
                })
                if(!!foundElement) return;
                sourceElement.external = true;
                sourceElement.externalSource = doc;
                targetGroup.push(sourceElement);
            })
        }
    });
    // recurse on children
    if(!!doc.dependencies) {
        doc.dependencies.forEach(dep => {
            mergeParents(original, dep, depth + 1);
        })
    }
}

export default mergeParents;
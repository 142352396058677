import {getCheckboxValues, deltaCheckboxValues, setCheckboxValues, poolStatus, poolStatusValue, getKey} from './PoolBoxes.js'
import { Fragment } from 'react';

function attemptClearCheckboxes (entity, stat, encounterid, index) {
    return <button
    // key={99999}
    onClick={() => {
        if(window.confirm('Are you sure you want to clear this pool?')) {
            clearCheckboxes(entity, stat, encounterid, index);
        }}}
        className='delete clearboxes'
        >
        clear
    </button>
}

export function clearCheckboxes (entity, stat, encounterid, index) {
    let sid = stat._id;
    let eid = entity._id;
    for(let key in localStorage) {
        // console.log(key, eid, sid);
        let targetKey = getKey(entity, stat, encounterid, index)
        if(key === targetKey) {
            localStorage.removeItem(key);
        }
    }
    let boxes = document.getElementsByClassName('stressbox');
    for(let i = 0; i < boxes.length; i++) {
        let box = boxes.item(i);
        if(box.dataset.statid === sid) {
            box.checked = false;
        }
    }
    let spinners = document.getElementsByClassName('stressspinner');
    for(let i = 0; i < spinners.length; i++) {
        let spinner = spinners.item(i);
        if(spinner.dataset.statid === sid) {
            let boxes = 0;
            if(stat.valueType === 'number') boxes = stat.number;
            if(stat.valueType === 'formula') {
                if(typeof stat.calculated === 'number') {
                    boxes = stat.calculated;
                }
                let num = parseInt(stat.calculated);
                if(!isNaN(num)) {
                    boxes = num;
                }
            }
            spinner.value = boxes;
        }
    }
    let lskey = getKey(entity, stat, encounterid, index);
    let status = document.getElementById(lskey + 'poolStatus');
    // console.log(lskey, status)
    status.textContent = poolStatusValue(entity, stat);
    let changespinner = document.getElementById(lskey + 'changespinner');
    if(changespinner) {
        changespinner.value = 0;
    }
}

function applyDamage(event, entity, stat, lskey, encounterid, index) {
    let mod = parseInt(event.target.dataset.mod);
    let changespinner = document.getElementById(lskey + 'changespinner');
    let damage = changespinner.value * mod;
    let currentspinner = document.getElementById(lskey + 'currentspinner');
    let remaining = currentspinner.value - damage;
    currentspinner.value = remaining;
    // console.log(lskey, damage);
    deltaCheckboxValues(entity, stat, damage, encounterid, index);
    document.getElementById(lskey + 'changespinner').value = 0;
}

export default function PoolCheckboxes (entity, stat, encounterid, index) {
    if(!stat) return('');
    // console.log(stat)
    let boxes = 0;
    if(stat.valueType === 'number') boxes = stat.number;
    if(stat.valueType === 'formula') {
        if(typeof stat.calculated === 'number') {
            boxes = stat.calculated;
        }
        let num = parseInt(stat.calculated);
        if(!isNaN(num)) {
            boxes = num;
        }
    }
    if(boxes < 1) return('');
    if(boxes < 10) {
        let damage = getCheckboxValues(entity, stat, encounterid, index);
        let output = [];
        for(let i = 0; i < boxes; i++) {
            if(i%5 === 0 && i != 0) {
                output.push(<br key={'break'+i} />)
            }
            output.push(
                <input
                    type='checkbox'
                    key={i}
                    className='stressbox'
                    data-statid={stat._id}
                    onChange={(e) => {
                        damage = e.target.checked ? 1 : -1;
                        // console.log(damage)
                        deltaCheckboxValues(entity, stat, damage, encounterid, index);
                    }}
                    defaultChecked={i < damage}
                />
            );
        }
        return <>
            {output}
            {<br />}
            {poolStatus(entity, stat, encounterid, index)}
            {attemptClearCheckboxes(entity, stat, encounterid, index)}
        </>;
    }
    else {
        let damage = getCheckboxValues(entity, stat, encounterid, index);
        let lskey = getKey(entity, stat, encounterid, index);
        let output = <Fragment>
            <label>
                <span>Current:</span>
                <input
                type='number'
                data-statid={stat._id}
                id={lskey + 'currentspinner'}
                key={lskey}
                className='stressspinner'
                onChange={(e) => {
                    // console.log(e)
                    let newValue = e.target.value;
                    let change = boxes - newValue;
                    // console.log(newValue, change);
                    setCheckboxValues(entity, stat, change, encounterid, index);
                }}
                defaultValue={boxes - damage}
                />
                {poolStatus(entity, stat, encounterid, index)}
            </label>
            <label>
                <span>Modify:</span>
                <input
                    type='number'
                    key={lskey}
                    id={lskey + 'changespinner'}
                    data-statid={stat._id}
                    className='changespinner'
                    onChange={(e) => {
                        // console.log(e.target.value)
                        // deltaCheckboxValues(entity, stat, boxes - e)
                    }}
                    defaultValue={0}
                    min={0}
                    step={1}
                />
                <button
                    className='heal'
                    data-mod={-1}
                    onClick={(e) => applyDamage(e, entity, stat, lskey, encounterid, index)}
                >heal</button>
                <button
                    className='harm'
                    data-mod={1}
                    onClick={(e) => applyDamage(e, entity, stat, lskey, encounterid, index)}
                >harm</button>
            </label>
            {attemptClearCheckboxes(entity, stat, encounterid, index)}
        </Fragment>
        return output;
    }
}
import React from 'react';
/**
 * A modal that shows child content.
 * 
 * Implementers will need two support functions.
 * ```
 *  openModal = (e) => {
 *      let target = e.target.dataset.target;
 *      this.setState({
 *          [target]: true,
 *      })
 *  }
 *  closeModal = (e) => {
 *      let target = e.target.dataset.target;
 *      this.setState({
 *          [target]: false,
 *      })
 *  }
 * ```
 * @param {object} props.state the state object of the parent to check for status
 * @param {string} props.title the heading of the modal
 * @param {string} props.target the name of the modal in owner's state
 * @param {string} props.closeButtonText the text shown on the close button
 * @param {Function} props.closeModal A function that closes the modal
 */
export default function Modal (props) {
    let close = props.closeButtonText || 'close';
    if(!props.state[props.target]) return ('');
    return(
        <div className='modal'>
            <div
                className='bg-button'
                onClick={props.closeModal}
                data-target={props.target}
            >
            </div>
            <div className='pane'>
                <h3>{props.title}</h3>
                <div className='scroll'>
                    {props.children}
                </div>
                <div className="spacer"></div>
                <button
                    onClick={props.closeModal}
                    data-target={props.target}
                >
                    {close}
                </button>
            </div>
        </div>
    )
}
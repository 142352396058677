import { Link } from "react-router-dom";
import UserInline from "./UserInline.jsx";

export default function TableOfLists(props) {
    if(!props) return ('');
    if(!props.lists) return ('');
    return (<table>
        <thead><tr>
            <th>Name</th>
            <th>Docs</th>
            <th>Owner</th>
            <th>XP</th>
        </tr></thead>
        <tbody>
            {props.lists?.map(list => {return(
            <tr key={list._id}>
                <td>
                    {list.trashed && '🗑️'}
                    {list.unlisted && '💬'}
                    <Link to={'/list/' + list._id}>
                        {list.name}
                    </Link>
                </td>
                <td>{list.rules?.map(x => {return(
                    <span className="UserInline" key={x._id}>
                        <Link to={'/doc/' + x._id} key={x._id}>
                            {x.name}
                        </Link>
                    </span>
                )})}</td>
                <td><UserInline user={list.owner} /></td>
                <td>{list.xp}</td>
            </tr>)})}
        </tbody>
    </table>)
}
import React from "react";
import '../Components/TextUtils';
import DatabaseInfo from '../Components/DatabaseInfo.jsx';
import Footer from '../Components/Footer.jsx';

class StatusPage extends React.Component {
    constructor (props){
        super(props)
        this.id = this.props.match.params.DocId
        this.state = {
            doc: {},
            campaigns: []
        }
    }

    render() {
        return(
            <div>
                <h1>Status</h1>
                <DatabaseInfo />
                <Footer />
            </div>
        )
    }
}

export default StatusPage;
import PowerGraph from "./PowerGraph.jsx";
import PcRow from "./PcRow.jsx";
import EnemyListItem from "./EnemyListItem.jsx";
import CombatantList from "./CombatantList.jsx";
import { useEffect } from "react";
import StatBlocks from "./StatBlocks.jsx";

export default function MobileList (props) {
    let campaign = props.campaign;
    let enc = props.enc;

    let attemptRenameEncounter = props.attemptRenameEncounter;
    let attemptDeleteEncounter = props.attemptDeleteEncounter;
    let addPc = props.addPc;
    let addEnemy = props.addEnemy;
    let setEncounterNote = props.setEncounterNote;
    let setEncounterLink = props.setEncounterLink;

    useEffect(() => {
        let noteFields = document.getElementsByClassName('notes');
        Array.from(noteFields).forEach(field => {
            field.style.height = 'auto';
            field.style.height = field.scrollHeight + 3 +"px";
            field.addEventListener('input', e => {
                e.target.style.height = 'auto';
                e.target.style.height = e.target.scrollHeight + 3 +"px";
            })
        })
    })

    return(
        <div className="mobile encounter">
            <h3>
                {!!enc.percentages.diff &&
                <span title={enc.percentages.diff}>
                    {enc.percentages.stars}
                </span>}
                {enc.name}
                <span className="hidden">
                    &nbsp;
                    <button
                        data-oldname={enc.name}
                        data-encounterid={enc._id}
                        onClick={attemptRenameEncounter}
                    >
                        ✏️ Rename
                    </button>
                    &nbsp;
                    <button
                        className='delete'
                        data-encounterid={enc._id}
                        onClick={attemptDeleteEncounter}
                    >
                        x
                    </button>
                </span>
            </h3>
            {!!enc.link && <>
                <a href={enc.link}>🔗{enc.link}
                </a>
                 - <button
                    onClick={setEncounterLink}
                    data-encounterid={enc._id}
                >
                    ✏️ edit notes link
                </button>
            </>}
            {!enc.link && 
                <button
                    onClick={setEncounterLink}
                    data-encounterid={enc._id}
                >
                    🔗 add notes link
                </button>
            }
            <textarea 
                placeholder="encounter notes"
                className="notes"
                data-encounterid={enc._id}
                defaultValue={enc.notes || ""}
                onBlur={setEncounterNote}
                // onInput='this.style.height = "";this.style.height = this.scrollHeight + "px"'
            />
            <PowerGraph
                enc={enc}
            />
            <table>
                <thead>
                    <tr>
                        <td>PC Name</td>
                        <td>XP: {enc.pcxp.toLocaleString()}</td>
                        <td>Qty</td>
                        <td>❌</td>
                    </tr>
                </thead>
                <tbody>
                    {enc.pcs.map(pc => {return(
                        <PcRow
                            key={pc._id}
                            pc={pc}
                            enc={enc}
                            setPcName={props.setPcName}
                            setPcXp={props.setPcXp}
                            setPcQty={props.setPcQty}
                            deletePc={props.deletePc}
                        />
                    )})}
                    <tr>
                        <td colSpan={4}>
                            <button
                                onClick={addPc}
                                data-encounterid={enc._id}
                            >
                                Add new PC
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <ul>
                <li>
                    <h4>
                        Enemy XP: {enc.enxp.toLocaleString()}
                    </h4>
                </li>
                {enc.enemyTypes.map(enemy => {
                    
                return(
                    <EnemyListItem
                        key={enemy._id}
                        enemy={enemy}
                        enc={enc}
                        campaign={campaign}
                        setEnemyDoc={props.setEnemyDoc}
                        setEnemyEntity={props.setEnemyEntity}
                        setEnemyQuantity={props.setEnemyQuantity}
                        deleteEnemy={props.deleteEnemy}
                    />
                )})}
                <li>
                    <button
                        onClick={addEnemy}
                        data-encounterid={enc._id}
                    >
                        Add new enemy
                    </button>
                </li>
            </ul>
            <StatBlocks
                enc={enc}
            />
            <CombatantList
                enc={enc}
                toggleBox={props.toggleBox}
            />
        </div>
)} 
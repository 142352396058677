/**
 * formats a stat number for display/output.
 * @param {*} num 
 * @param {*} groupName name of the group the stat is in
 * @param {Object} stat the stat object itself, containing the prototype reference
 * @returns formatted string
 */
export default function addPlus (num, groupName = '', stat = null) {
    if(!stat) console.error("no stat");
    if(!stat?.prototype) console.error("no prototype");
    if(!groupName) console.error("no group name");
    if(!!stat?.prototype?.displayFormat) {
        let str = stat.prototype.displayFormat;
        return str.replace("X", num);
    }
    groupName = groupName.toLocaleLowerCase();
    // guard against non-bonuses
    if(groupName === 'tacticals') return num;
    if(groupName === 'pools') return num;
    if(groupName === 'thresholds') return num;

    // check for negative
    num = num.toString();
    if(num.startsWith('-')) return num;
    
    // return with plus
    return '+' + num;
}

import { Link } from 'react-router-dom';
import {getCheckboxValues, deltaCheckboxValues, setCheckboxValues, poolStatus, getKey} from '../PoolBoxes.js';
import PoolCheckboxes, {clearCheckboxes } from '../PoolCheckboxes.jsx';

function combatantPool (entity, com, props, type, index) {
    // console.log(index)
    return(entity.pools.map(pool => {
        let encounter = props.enc;
        let boxes = pool.calculated;
        let filled = getCheckboxValues(entity, pool, props.enc._id, index)
        // console.log(encounter)

        return(
        <div
            className="stresstrack"
            key={pool._id}
        >
            <span>
                {pool.name}
                &nbsp;
                {filled > 0 && 
                    poolStatus(entity, pool, encounter._id, index)
                }
            </span>
            <span className='track'>
                {PoolCheckboxes(entity, pool, encounter._id, index)}
            </span>
        </div>
    )}))
}

function attemptClear(props) {
    if(window.confirm('Are you sure you want to clear all the pools in this encounter?')) {
        // console.log(props);
        let encounter = props.enc;
        let encounterid = encounter._id;
        encounter.enemyTypes.forEach(enemyType => {
            let entity = enemyType.entity;
            for(let i = 0; i < enemyType.quantity; i++) {
                let index = i;
                enemyType.entity.pools.forEach(pool => {
                    let stat = pool;
                    // console.log(entity, stat, encounterid, index);
                    clearCheckboxes(entity, stat, encounterid, index);
                })
            }
        })
    }
}

export default function CombatantList (props) {
    return(
    <ul>
        {props.enc.enemyTypes.map((type, ti) => {
            let entity = type.entity;
            let typeKey = type._id + ti;
            // console.log(typeKey, ti)
            return(
                <li key={typeKey}>
                    <ul>
                        {type.combatants.map((com, ci) => {
                            // console.log(ci)
                            if(!entity) return(null);
                            let url = '/doc/' + type.doc._id + '/Entity/' + type.entity._id;
                            return(
                            <li
                                key={ci}
                                className="combatant"
                            >
                                <h5>
                                    <Link
                                        to={url}
                                        // target='_blank'
                                        // rel='noopener noreferrer'
                                    >
                                        {entity.name} #{com.index + 1}
                                    </Link>
                                </h5>
                                {combatantPool(entity, com, props, type, ci)}
                            </li>
                        )})}
                    </ul>
                </li>
        )})}
        <li>
            <button
                onClick={(e) => attemptClear(props)}
            >
                Clear All Enemy Pools
            </button>
        </li>
    </ul>
)}
import React from "react";
import Footer from '../Components/Footer.jsx';

class TagPage extends React.Component {
    constructor (props){
        super(props)
        this.name = this.props.match.params.TagName
        this.state = {}
    }
    render () {
        if (this.name) {
            document.title = ['#' + this.name, 'SGS'].join(' - ');
            return (
            <div className="container">
                <h1><span className="tag">{this.name}</span></h1>
            </div>
        )}
        else {return (
            <div className="container">
                <h1>Tag: ?</h1>
                <Footer/>
            </div>
        )}
    }
};

export default TagPage;
const jwt = require('jsonwebtoken');
const tokenName = 'auth-token'

class UserDataCache  {
    /**
     * Saves a token sent from the server to the user's local machine
     * @param {JWT} token 
     * @returns 
     */
    static saveToken(token) {
        if(!token) return;
        window.localStorage.setItem(tokenName, JSON.stringify(token));
    }

    /**
     * decodes the token so it can be used locally in react.
     * Also removes the token if it's out of date.
     * @returns an object 
     */
    static getTokenObject() {
        let candidate = window.localStorage.getItem(tokenName);
        if(!candidate || candidate === 'null') {
            this.removeToken();
            return null;
        }
        let token = JSON.parse(candidate);
        let decoded = jwt.decode(token);
        if(!!decoded.exp && decoded.exp * 1000 >= Date.now()) {
            return decoded;
        }
        else {
            window.localStorage.removeItem(tokenName);
            return null;
        }
    }

    /**
     * returns the encoded JWT
     * @returns encoded JWT
     */
    static getTokenText() {
        let candidate = window.localStorage.getItem(tokenName);
        if(!candidate) {
            return null
        }
        return candidate;
    }
    
    /**
     * gives a whole auth string, including the user's JWT, in the format of `bearer xxx.yyy.zzz`
     * plug this into the `authorization` header of http requests.
     * @returns authorization header value
     */
     static getAuthHeader(){
        let token = this.getTokenText();
        //console.log(token);
        if(!token) return '';
        return 'bearer ' + token;
    }

    /**
     * removes the token from the user's machine
     */
    static removeToken() {
        window.localStorage.removeItem(tokenName);
        // console.log('removed token');
    }
}

export default UserDataCache;
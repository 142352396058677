function findEntityById(doc, id){
    if(!doc.entities) {
        return null;
    }
    let ids = doc.entities.map(x => x._id);
    // console.log(id, ids, ids.includes(id), ids.indexOf(id))
    let found = doc.entities[ids.indexOf(id)];
    // let found = doc.entities.find(entity => entity._id === id); // This doesn't work in the Build API context for some god-forksaken reason.
    // console.log("FOUND", found, f2);
    return found;
}

export default findEntityById;
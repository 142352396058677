import EquipSkillStack from "../../EquipSkillStack.mjs";
import addPlus from "../addPlus.js";

/**
 * Concatenates and formats a group of stats of an item for display in a parent entity's stat block
 * @param {Object} doc the rules doc used
 * @param {Object} owner the parent entity owning the item
 * @param {String} group the group of stats on the item to show
 * @param {Object} item the item itself
 * @returns A string with the item stats
 */
export default function ItemStatList(doc, owner, group, item, hideHeader = false) {
    return item[group]
    .filter(x => !x.prototype.displayVisibility || x.prototype.displayVisibility === 'items')
    .sort((a, b) => {return a.skillGroup > b.skillGroup })
    .map(stat => {
        let skillString = '';
        skillString += stat.name + " ";
        let skillValue = 0;
        if(stat.dieSize !== 'none') {
            skillValue = '1' + stat.dieSize;
        }
        if(!!stat.dicePool) {
            skillValue = stat.dicePool;
        }
        if(stat.valueType === 'number') {
            skillValue = stat.number;
        }
        if(stat.valueType === 'formula') {
            skillValue = stat.calculated;
        }
        if(group === 'skills') {
            skillValue = EquipSkillStack(doc, owner, stat, group);
        }
        skillString += addPlus(skillValue, group, stat);
        return skillString;
    })
}
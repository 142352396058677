import React from 'react';
import SGS from 'sgs';
// import { capitalize } from '../TextUtils';
// import EditStat from './EntityListTab/EditStat';
import './EntityEditTab.css';
// import Modal from '../Modal';
import NamePanel from './EntityEditTab/NamePanel.jsx';
import AspectPanel from './EntityEditTab/AspectsPanel.jsx';
import StatsPanel from './EntityEditTab/StatsPanel.jsx';
import EquipmentPanel from './EntityEditTab/EquipmentPanel.jsx';
import OwnersPanel from './EntityEditTab/OwnersPanel.jsx';
import PortraitPanel from './EntityEditTab/PortraitPanel.jsx';
import InfoPanel from './EntityEditTab/InfoPanel.jsx';

class EntityEditTab extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.EntityId,
        }
    }
    openModal = (e) => {
        let target = e.target.dataset.target;
        this.setState({
            [target]: true,
        })
    }
    closeModal = (e) => {
        let target = e.target.dataset.target;
        this.setState({
            [target]: false,
        })
    }
    changeName = (id, name) => {
        let entityid = id;
        let value = name;
        this.props.sendMessage({
            operation: 'change entity name',
            entityid,
            value,
        });
    }

    render() {
        // console.log('render')
        const entity = this.props.doc.entities?.find(x => x._id === this.state.id);
        if(!entity) return('Searching for entity: ' + this.state.id);
        document.title = [entity.name, this.props.doc.name, 'SGS'].join(' - ');
        // console.log('aspects', entity.aspects)
        return(<div className='EntityEditTab' key={entity._id}>
            <NamePanel 
                doc={this.props.doc}
                entity={entity}
                openModal={this.openModal}
                closeModal={this.closeModal}
                changeName={this.changeName}
                state={this.state}
                sendMessage={this.props.sendMessage}
            />
            <PortraitPanel 
                entity={entity}
                sendMessage={this.props.sendMessage}
            />
            {!this.props.doc?.options?.disableAspects &&
                <div className='panel aspectspanel' >
                    <AspectPanel
                    doc={this.props.doc}
                    entity={entity}
                    group='aspects'
                    singular='aspect'
                    openModal={this.openModal}
                    closeModal={this.closeModal}
                    sendMessage={this.props.sendMessage}
                    />
                    <AspectPanel
                    doc={this.props.doc}
                    entity={entity}
                    group='flaws'
                    singular='flaw'
                    openModal={this.openModal}
                    closeModal={this.closeModal}
                    sendMessage={this.props.sendMessage}
                    />
                </div>
            }
            {SGS.groups.map(groupName => {
                if(this.props.doc[groupName]?.length === 0) return '';
                
                return(
                <StatsPanel
                    doc={this.props.doc}
                    entity={entity}
                    groupName={groupName}
                    key={groupName}
                    sendMessage={this.props.sendMessage}
                />
            )})}
            <EquipmentPanel
                doc={this.props.doc}
                entity={entity}
                openModal={this.openModal}
                closeModal={this.closeModal}
                sendMessage={this.props.sendMessage}
                state={this.state}
            />
            <OwnersPanel
                doc={this.props.doc}
                entity={entity}
            />
            <InfoPanel
                doc={this.props.doc}
                entity={entity}
                // openModal={this.openModal}
                // closeModal={this.closeModal}
                sendMessage={this.props.sendMessage}
                // state={this.state}
            />
        </div>)
    }
}

export default EntityEditTab;
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

import EditStat from './EditStat.jsx';
import NameField from './NameField.jsx';
import TagList from './TagList.jsx';
import ItemList from './ItemList.jsx';
import LimitList from './LimitList.jsx';
import AspectList from './AspectList.jsx';
import EntityIcon from './EntityIcon.jsx';

export default function EntityTable (props) {
    let doc = props.doc;
    let entities = props.entities;
    let skillCount = props.skillCount;
    let sortEntities = props.sortEntities;
    let skillGroups = props.skillGroups;
    let openModal = props.openModal;
    let addNewEntity = props.addNewEntity;
    let changeName = props.changeName;
    let removeTag = props.removeTag;
    let addTag = props.addTag;
    let removeLimit = props.removeLimit;
    let addLimit = props.addLimit;
    // let modifyLimit = props.modifyLimit;
    let setAspects = props.setAspects;
    let setFlaws = props.setFlaws;
    let changeStat = props.changeStat;
    let sendMessage = props.sendMessage;
    let addItem = props.addItem;
    let confirmRemoveEntity = props.confirmRemoveEntity;
    let removeItem = props.removeItem;

    let editable = doc.editable;

    useEffect(() => {
        let table = document.getElementById('entity-table');
        // console.log(table.scrollWidth)
        table.style.width = table.scrollWidth + 'px';
    })

    return(
    <table className='entity-table desktop' id='entity-table'>
        <thead>
            <tr className='meta'>
                {/* <td className='freeze-col' rowSpan='2'>Page</td> */}
                <td className='freeze-col' rowSpan='2'>
                    <button
                        onClick={sortEntities}
                        data-prop='name'
                        >
                        Name
                    </button>
                    &nbsp;
                    <button
                        onClick={sortEntities}
                        data-prop='xp'
                    >
                        Xp
                    </button>
                </td>
                <td colSpan='2'>Vitals</td>
                <td colSpan='2'>Aspects</td>
                {doc.primaries?.length > 0 && <td colSpan={doc.primaries?.length}>Primaries</td>}
                {doc.tacticals?.length > 0 && <td colSpan={doc.tacticals?.length}>Tactical</td>}
                {doc.thresholds?.length > 0 && <td colSpan={doc.thresholds?.length}>Thresholds</td>}
                {doc.pools?.length > 0 && <td colSpan={doc.pools?.length}>Pools</td>}
                {skillGroups.filter(x => skillCount[x] > 0)
                    .map(skillGroup => {
                    let count = skillCount[skillGroup];
                    return(
                        <td
                            colSpan={count}
                            key={skillGroup}
                        >
                            {skillGroup} Skills
                        </td>
                    )
                })}
                <td rowSpan='2' className='listHead'>Equip</td>
                <td rowSpan='2'>Remove</td>
            </tr>
            <tr>
                <td
                    className='listHead'
                    style={{minWidth: '25ch'}}
                >
                    <button
                        onClick={openModal}
                        data-target='tagModal'
                    >
                        Tags
                    </button>
                </td>
                <td
                    className='listHead'
                    style={{minWidth: '30ch'}}
                >
                    Limits
                </td>
                <td>Aspects</td>
                <td>Flaws</td>
                {doc.primaries?.map(x => {return(
                    <td key={x.name}>{x.name}</td>
                )})}
                {doc.tacticals?.map(x => {return(
                    <td key={x.name}>{x.name}</td>
                )})}
                {doc.thresholds?.map(x => {return(
                    <td key={x.name}>{x.name}</td>
                )})}
                {doc.pools?.map(x => {return(
                    <td key={x.name}>{x.name}</td>
                )})}
                {skillGroups.map(skillGroup => {return(
                    doc.skills?.filter(x => x.skillGroup === skillGroup)
                        .map(x => {
                        return(
                            <td key={x.name}>{x.name}</td>
                        )
                    })
                )})}
            </tr>
        </thead>
        <tbody>
            {entities.map(entity => {
                let path = '/doc/'
                    + doc._id
                    + '/Entity/'
                    + entity._id;
                return(
                <tr key={entity._id}>
                    <td
                        className='freeze-col keycell'
                        style={{minWidth: (entity.name.length + entity.xp.toLocaleString().length) * .8 + 15+ 'ch'}}
                    >
                        <div>
                            <EntityIcon 
                                entity={entity}
                                sendMessage={sendMessage}
                            />
                            <Link
                                to={path} 
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                🔗
                            </Link>
                            <NameField
                                entity={entity}
                                changeName={changeName}
                            />
                            <span>{entity.xp.toLocaleString()}</span>
                        </div>
                    </td>
                    <TagList
                        entity={entity}
                        removeTag={removeTag}
                        addTag={addTag}
                        doc={doc}
                    />
                    <LimitList
                        entity={entity}
                        removeLimit={removeLimit}
                        addLimit={addLimit}
                        // modifyLimit={modifyLimit}
                        doc={doc}
                    />
                    <AspectList 
                        entity={entity}
                        aspects={entity.aspects}
                        doc={doc}
                        aspectType='aspects'
                        setAspects={setAspects}
                        />
                    <AspectList
                        entity={entity}
                        aspects={entity.flaws}
                        doc={doc}
                        aspectType='flaws'
                        setAspects={setFlaws}
                    />
                    {doc.primaries.map(prototype => {
                        let stat = entity.primaries.find(x =>
                            x.name === prototype.name);
                        return(
                        <EditStat
                            key={prototype.name}
                            prototype={prototype}
                            stat={stat}
                            entity={entity}
                            statgroup={'primaries'}
                            changeStat={changeStat}
                            sendMessage={sendMessage}
                        />
                    )})}
                    {doc.tacticals.map(prototype => {
                        let stat = entity.tacticals.find(x =>
                            x.name === prototype.name);
                        return(
                        <EditStat
                            key={prototype.name}
                            prototype={prototype}
                            stat={stat}
                            entity={entity}
                            statgroup={'tacticals'}
                            changeStat={changeStat}
                            sendMessage={sendMessage}
                        />
                    )})}
                    {doc.thresholds.map(prototype => {
                        let stat = entity.thresholds.find(x =>
                            x.name === prototype.name);
                        return(
                        <EditStat
                            key={prototype.name}
                            prototype={prototype}
                            stat={stat}
                            entity={entity}
                            statgroup={'thresholds'}
                            changeStat={changeStat}
                            sendMessage={sendMessage}
                        />

                    )})}
                    {doc.pools.map(prototype => {
                        let stat = entity.pools.find(x =>
                            x.name === prototype.name);
                        return(
                        <EditStat
                            key={prototype.name}
                            prototype={prototype}
                            stat={stat}
                            entity={entity}
                            statgroup={'pools'}
                            changeStat={changeStat}
                            sendMessage={sendMessage}
                        />
                    )})}
                    {skillGroups.map(skillGroup => {return(
                        doc.skills.filter(x => x.skillGroup === skillGroup)
                            .map(prototype => {
                            let stat = entity.skills.find(x =>
                                x.name === prototype.name);                                 
                            return(
                            <EditStat
                                key={prototype.name}
                                prototype={prototype}
                                stat={stat}
                                entity={entity}
                                statgroup={'skills'}
                                changeStat={changeStat}
                                sendMessage={sendMessage}
                            />
                            )
                        })
                    )})}
                    <ItemList
                        entity={entity}
                        removeItem={removeItem}
                        addItem={addItem}
                        doc={doc}
                        sendMessage={sendMessage}
                    />
                    <td>
                        <button
                            onClick={confirmRemoveEntity}
                            data-id={entity._id}
                            data-name={entity.name}
                            className='delete'
                        >
                            x
                        </button>
                    </td>
                </tr>
            )})}
            {editable && <tr>
                <td colSpan='99' className='end-row'>
                    <button
                        onClick={addNewEntity}
                        className='add-entity'>
                        add new
                    </button>
                </td>
            </tr>}
        </tbody>
    </table>
)}
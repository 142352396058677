import React from "react";
import LoginBox from '../Components/LoginBox.jsx';
import Footer from '../Components/Footer.jsx';
import PatronList from "../Components/PatronList.jsx";

class Login extends React.Component{
    constructor (props){
        super(props)
        //console.log(props)
        this.outcome = this.props.match.params.Message
        //console.log(this.props.match.params.Message)
        this.state = {
            sessions: []
        }
    }
    render() {
        //const message = this.outcome === 'no' ? 'incorrect login or password.' : ''
        //console.log(this.outcome)
        return (
            <div className="container">
                <h1>Login</h1>
                <LoginBox></LoginBox>
                <a href='/ResetRequest'>Forgot Password</a>
                <PatronList />
                <Footer/>
            </div>
        );
    }
}

export default Login;
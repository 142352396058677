import React from 'react';
import './NameField.css'

class NameField extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            value: this.props.entity.name
        }
    }
    
    handleKeyPress = (e) => {
        // console.log(e.key);
        if(e.key === 'Enter') {
            e.preventDefault();
            // console.log('submit');
            this.props.changeName(this.props.id, this.state.value);
        }
        else {
            this.setState({ value: e.target.value});
        }
    }

    handleChange = (e) => {
        this.setState({ value: e.target.value});
    }

    handleBlur = (e) => {
        // console.log(this.state.value, this.props.entity.name);
        if(this.state.value !== this.props.entity.name) {
            this.props.changeName(this.props.entity._id, this.state.value);
        }
    }
    renderStatic () {
        let xs = this.props.entity.externalSource;
        let title = !!xs ? 'source ' + xs.type + ' doc: ' + xs.name : null;
        return(
            <span
                title={title}
            >
                {title && '🔼'}
                {this.props.entity.name}
            </span>
        )
    }
    renderEditable () {
        return(
            <input
                defaultValue={this.state.value}
                data-entityid={this.props.id}
                title={this.props.id}
                onKeyPress={this.handleKeyPress}
                onChange={(e) => this.handleChange(e)}
                onBlur={this.handleBlur}
                // wrap='soft'
                style={{flexBasis: (this.state.value.length * .8) + 5 + 'ch'}}
                size={this.state.value.length * .8}
            />
        )
    }
    render () {
        let entity = this.props.entity;
        if(entity.editable) return this.renderEditable();
        return this.renderStatic();
    }
}

export default NameField
import React from "react";
import UserDataCache from "./UserDataCache.jsx";
import UserInline from "./UserInline.jsx";
import UserSelector from "./UserSelector.jsx";

/**
 * UI for adding and removing list editors.
 * @param {Object} list the list that we're editing.
 * @param {Function} api the list api function.
 * @param {Function} performLocalChange the function for editing the local list to keep up with the server.
 */
export default class ListPageEditors extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            addModal: false,
            removeModal: false,
        }
    }

    confirmRemoveEditor = (editor) => {
        if(!window.confirm('Do you really want to remove '
            + editor.username + ' from this doc?')) return;
        this.removeEditor(editor._id);
    }

    removeEditor = (editorid) => {
        this.props.api('removeListEditor', {
            listid: this.props.list._id,
            editorid: editorid,
        })
        let list = this.props.list
        list.editors = list.editors.filter(x => x._id.toString() !== editorid);
        console.log(list);
        this.props.performLocalChange(list);
    }
    
    openModal = () => {
        this.setState({
            addModal: true
        });
    }
    
    addEditor = (user) => {
        // console.log('add ' + user._id);
        this.props.api('addListEditor', {
            listid: this.props.list._id,
            editorid: user._id,
        });
        let list = this.props.list
        list.editors.push(user);
        this.props.performLocalChange(list);
    }

    closeModal = () => {
        this.setState({
            addModal: false,
        });
    }

    render() {
        let list = this.props.list;
        // console.log(doc.editors);
        let canEditUsers = !!list.owner
            && !!UserDataCache.getTokenObject()?.user._id
            && list.owner?._id
            === UserDataCache.getTokenObject()?.user._id;
        // console.log(doc?.owner);
        return(<div>
        <h3>Doc Owner</h3>
        {!!list?.owner
            && <UserInline user={list?.owner} />}
        <h3>Doc Editors</h3>
        <ul>
            {list?.editors?.map((editor) => {
                return(
                    <li key={editor._id}>
                        <UserInline user={editor} key={editor._id}/>
                        {canEditUsers
                            && this.state.removeModal
                            && <button 
                            className="delete"
                            onClick={() => this.confirmRemoveEditor(editor)}>x</button>}
                    </li>
            )})}
            {canEditUsers && <li>
                <button onClick={this.openModal}>Add an Editor</button>
            </li>}
            {canEditUsers && <li>
                <button
                    onClick={() => this.setState({
                        removeModal: !this.state.removeModal
                    })}
                >
                    Remove an Editor
                </button>
            </li>}
        </ul>
        {this.state.addModal && 
        <UserSelector 
            close={this.closeModal}
            addUser={this.addEditor}
            doc={list}
        />}
        </div>)
    }
}
import { Link } from 'react-router-dom';
import SGS from 'sgs';
import { capitalize } from '../../TextUtils.js';

export default function OwnersPanel(props) {
    let entity = props.entity;
    let doc = props.doc;
    // let editable = entity.editable;
    let owners = doc.entities.filter(x => x.equipment.includes(entity._id));
    if(owners.length === 0) {
        return '';
    }

    return (
        <div className='panel ownerspanel'>
            <h3>Owners</h3>
            <ul className='equipment'>
                {owners.map(owner => {
                    let path = '/doc/'
                    + doc._id
                    + '/Entity/'
                    + owner._id;
                    return(
                    <li key={owner._id}>
                        <Link to={path}>
                            {owner.name}
                        </Link>
                        &nbsp;
                        ({owner.xp.toLocaleString()}xp)
                        <ul>
                            {owner.limitations.length > 0 && <li>
                                Limits: {owner.limitations.map(x => x.name).join(', ')}
                            </li>}
                            {owner.aspects.length > 0 && <li>
                                Aspects: {owner.aspects.join(', ')}
                            </li>}
                            {owner.flaws.length > 0 && <li>
                                Flaws: {owner.flaws.join(', ')}
                            </li>}
                            {SGS.groups.map(groupName => {
                                let group = owner[groupName];
                                if(group.length === 0) return('');
                                return(
                                    <li key={groupName}>
                                        {capitalize(groupName)}:
                                        {group.map(stat => {return(
                                            <span key={stat.name}>
                                                &nbsp;
                                                {stat.valueType === 'number' && stat.number?.toString()}
                                                {stat.valueType === 'dieSize' && '1' + stat.dieSize?.toString()}
                                                {stat.valueType === 'dicePool' && stat.dicePool?.toString()}
                                                {stat.valueType === 'formula' && stat.calculated?.toString()}
                                                &nbsp;
                                                {stat.name}
                                            </span>
                                        )})}
                                    </li>
                            )})}
                        </ul>
                        <br />
                    </li>
                )})}
            </ul>
        </div>
    )
}
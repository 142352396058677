import { useEffect } from "react";

function setDescription(e, sendMessage) {
    let entityid = e.target.dataset.entityid;
    console.log(entityid)
    let description = e.target.value;
    sendMessage({
        operation: 'set entity description',
        entityid,
        description,
    })
}

function setNotes(e, sendMessage) {
    let entityid = e.target.dataset.entityid;
    let notes = e.target.value;
    sendMessage({
        operation: 'set entity notes',
        entityid,
        notes,
    })
}

export default function InfoPanel(props) {
    // let [checkedTag, setCheckedTag] = useState('');
    let entity = props.entity;
    let editable = entity.editable;
    let sendMessage = props.sendMessage;
    // let doc = props.doc;
    // let userNotesId = doc._id + "_" + entity._id;
    // let openModal = props.openModal;
    // let closeModal = props.closeModal;
    // let state = props.state;
    let noprint = !entity.notes && !entity.description ? ' noprint' : '';

    useEffect(() => {
        let noteFields = document.getElementsByClassName('notes');
        Array.from(noteFields).forEach(field => {
            field.style.height = 'auto';
            field.style.height = field.scrollHeight + 3 +"px";
            field.addEventListener('input', e => {
                e.target.style.height = 'auto';
                e.target.style.height = e.target.scrollHeight + 3 +"px";
            })
        })
    })

    return (
        <div className={'panel infopanel' + noprint}>
            <h3>Description</h3>
            {editable && <textarea
                key="description"
                className="notes"
                defaultValue={entity.description}
                onBlur={e => setDescription(e, sendMessage)}
                data-entityid={entity._id}
            />}
            {!editable && 
                <p>{entity.description}</p>
            }
            <h3>Entity Notes</h3>
            {editable && <textarea
                key="notes"
                className="notes"
                defaultValue={entity.notes}
                onBlur={e => setNotes(e, sendMessage)}
                data-entityid={entity._id}
            />}
            {!editable && 
                <p>{entity.notes}</p>
            }
        </div>
    )
}


/**
 * UI cell for editing the Experience Value Exponent of a Stat
 * @param {Object} stat the stat we want to edit
 * @param {Object} doc the doc containing the stat
 * @param {String} group the name of the stat group
 * @param {Function} sendMessage the sendMessage function for interacting with the SocketDoc
 */
export default function EditStatExponent (props) {
    let current = props.stat?.exponent || 2;
    const setExponent = (e) => {
        let newExponent = e.target.value;
        props.sendMessage({
            operation: 'set stat exponent',
            statName: props.stat.name,
            statGroup: props.group,
            statId: props.stat._id,
            newExponent,
        })
    }
    if(!props.doc?.editable || !props.stat?.editable) {
        return (
        <td>
            <input
                value={current}
                type='number'
                min='0'
                max='5'
                disabled='true'
            />
        </td>
        );
    }
    return (
        <td>
            <input
                value={current}
                type='number'
                min='0'
                max='5'
                onChange={setExponent}
            />
        </td>
    )
}
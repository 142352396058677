import React from 'react';
import './TagList.css';
import ItemSelector from '../../ItemSelector.jsx';

class ItemList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            itempicker: false,
            doctags: [],
        }
    }
    closeItemPicker = () => {
        this.setState({ itempicker: false })
    }

    openModal = (e) => {
        let target = e.target.dataset.target;
        this.setState({
            [target]: true,
        })
    }
    closeModal = (e) => {
        let target = e.target.dataset.target;
        this.setState({
            [target]: false,
        })
    }
    renderStatic () {
        let entity = this.props.entity;
        let items = entity?.items;
        let itemList = items.map(x => x.name).join(', ') || '+';
        let max = itemList.length / 3;
        return(
            <td className='listCell' style={{minWidth: max + 'ch'}}>                
                {itemList}
            </td>
        )
    }
    renderEditable () {
        let entity = this.props.entity;
        let doc = this.props.doc;
        let items = entity?.items;
        let itemList = items.map(x => x.name).join(', ') || '+';
        let max = itemList.length / 3;
        return(
            <td className='listCell' style={{minWidth: max + 'ch'}}>
                <button
                    onClick={this.openModal}
                    data-target='itemModal'
                    className='tagList'
                >
                    {itemList}
                </button>
                <ItemSelector
                    parentState={this.state}
                    sendMessage={this.props.sendMessage}
                    closeModal={this.closeModal}
                    doc={doc}
                    entity={entity}
                />
            </td>
        )
    }
    render () {
        let entity = this.props.entity;
        if(entity.editable) return this.renderEditable();
        return this.renderStatic();
    }
}

export default ItemList;
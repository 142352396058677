import React from "react";
import UserInline from './UserInline.jsx';
import ListTags from './ListTags.jsx';
import { Link } from "react-router-dom";
import CompareProp from "./CompareProp.js";

class TableOfCampaigns extends React.Component {

    render(){
        if(!this.props) return ('')
        if(!this.props.campaigns) return ('')

        return (
        <div>
            <ul className='mobile'>
                {this.props.campaigns?.sort(CompareProp('name', false, true))
                .map(campaign => {
                    let classes = [
                        campaign.unlisted ? 'unlisted' : '',
                        campaign.trashed ? 'trashed' : ''
                    ]
                    return (
                        <li key={campaign._id} className={classes.join(' ')}>
                            <Link to={'/campaign/' + campaign._id}>
                                🧭 {campaign.name}
                                &nbsp;
                                {campaign.trashed ? <span title="trashed">❌</span> : ''}
                                {campaign.unlisted ? <span title="unlisted">💬</span> : ''}
                                {!!campaign.gm && ' - GM: ' + campaign.gm.username}
                            </Link>
                        </li>
                    
                )})}
            </ul>

        <table className='desktop'>
            <thead>
                <tr>
                    <th>Campaign</th>
                    <th>Game Rules</th>
                    <th>GM</th>
                    <th>Players</th>
                    <th>Tags</th>
                </tr>
            </thead>                    
            <tbody>
            {this.props.campaigns?.map((campaign) => {
                let classes = [
                    campaign.unlisted ? 'unlisted' : '',
                    campaign.trashed ? 'trashed' : ''
                ]
                return (<tr
                    key={campaign._id}
                    className={classes.join(' ')}
                >
                    <td>
                        {campaign.trashed ? <span title="trashed">❌</span> : ''}
                        {campaign.unlisted ? <span title="unlisted">💬</span> : ''}
                        <a href={'/campaign/' + campaign._id} className={campaign.unlisted ? 'unlisted' : ''}>{campaign.name}</a>
                    </td>
                    <td>
                        {campaign.games?.map( x => <a href={'/game/' + x._id} key={x._id}>{x.name}</a>)}
                    </td>
                    <td><UserInline user={campaign.gm} /></td>
                    <td>
                        {campaign.allPlayers.map(
                            (x) => <UserInline user={x} key={x._id} />
                        )}
                    </td>
                    <td>
                        <ListTags tags={campaign.tags} inline={true}/>
                    </td>
                </tr>)
            })}
            </tbody>
        </table>
        </div>
    )}
}

export default TableOfCampaigns
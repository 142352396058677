import React from "react";
import './loginbox.css';
import UserDataCache from "./UserDataCache.jsx";
// import {Redirect} from 'react-router-dom';
import {withRouter} from 'react-router-dom';

class LoginBox extends React.Component {
    // class ctor
    constructor(props){
        super(props)
        this.state = {
            email: '',
            password: '',
            error: '',
            token: {}
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name
        this.setState({
            [name]: value
        });
    }

    attemptLogin = (event) => {
        event.preventDefault();
        fetch('/api/login', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json'
            },
            body: JSON.stringify({
                email: this.state.email,
                password: this.state.password
            })
        })
        .then(res => res.json())
        .then((res) => {
            UserDataCache.saveToken(res.token);
            this.props.history.push("/");
            window.location.reload();
        })
        .catch(e =>
            console.log(e)
        );
    }

    // render class instance
    render() {
        let err = this.state.error ? <label>{this.state.error}</label> : '';
        let token = UserDataCache.getTokenObject();
        if(!!token) {
            this.props.history.push("/");
        }
        else {
            return (
                <form name="loginbox" className="loginbox" onSubmit={this.attemptLogin}>
                    <label><span>Email</span> 
                        <input
                        type="text"
                        name="email"
                        value={this.state.email}
                        onChange={this.handleInputChange} />
                    </label>
                    <label><span>Password</span>
                        <input
                        type="password"
                        name="password"
                        value={this.state.password}
                        onChange={this.handleInputChange} />
                    </label>
                    <input type="submit" value="log in" />
                    {err}
                </form>
            );
        }
    }
}

export default withRouter(LoginBox);
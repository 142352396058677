import React from "react";
import UserDataCache from "../UserDataCache.jsx";
import './EncounterList.css';
import DesktopTable from "./DesktopTable.jsx";
import MobileList from "./MobileList.jsx";
import { ObjectId } from 'bson';

const id = (arr, id) => {
    return arr.find(x => x._id.toString() === id);
}
const filter = (arr, id) => {
    return arr.filter(x => x._id.toString() !== id);
}

export default class EncounterPage extends React.Component {

    api = (url, body) => {
        let campaign = this.props.campaign;
        
        fetch('/api/' + url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': UserDataCache.getAuthHeader()
            },
            method: "POST",
            body: JSON.stringify({
                id: campaign._id,
                ...body
            })
        })
        .then(res => res.json())
        .then(info  => {
            console.log('server returned.')
            // console.log(info);
            // setStateOnParent(info);
        })
    }

    performLocalChange = (campaign) => {
        let setStateOnParent = this.props.setStateFromChild;
        setStateOnParent(campaign);
    }
    
    attemptRenameEncounter = (e) => {
        let oldName = e.target.dataset.oldname;
        let encounterid = e.target.dataset.encounterid;
        let newName = window.prompt('Rename encounter `' + oldName + '` to ...', oldName);
        if(newName) {
            this.renameEncounter(newName, encounterid)
        }
    }

    renameEncounter = (newName, encounterid) => {
        this.api('renameEncounter', {
            encounterid,
            newName,
        });
        let campaign = this.props.campaign;
        let encounter = id(campaign.encounters, encounterid);
        encounter.name = newName;
        this.performLocalChange(campaign)
    }

    attemptDeleteEncounter = (e) => {
        if(window.confirm('Are you sure you want to delete this encounter?')) {
            this.deleteEncounter(e.target.dataset.encounterid);
        }
    }

    deleteEncounter = (encounterid) => {
        this.api('deleteEncounter', {
            encounterid,
        });
        let campaign = this.props.campaign;
        campaign.encounters = filter(campaign.encounters, encounterid);
        this.performLocalChange(campaign)
    }

    addPc = (e) => {
        let encounterid = e.target.dataset.encounterid;
        let pcid = new ObjectId();
        this.api('addPc', {
            encounterid,
            pcid,
        });
        let campaign = this.props.campaign;
        let encounter = id(campaign.encounters, encounterid);
        let pc = {
            _id: pcid,
            xp: 0,
            quantity: 0,
        };
        encounter.pcs.push(pc);
        this.performLocalChange(campaign)
    }

    setPcName = (e) => {
        let encounterid = e.target.dataset.encounterid;
        let pcid = e.target.dataset.pcid;
        let pcname = e.target.value;
        this.api('setPcName', {
            encounterid,
            pcid,
            pcname,
        })
        let campaign = this.props.campaign;
        let encounter = id(campaign.encounters, encounterid);
        let pc = id(encounter.pcs, pcid);
        pc.name = pcname;
        this.performLocalChange(campaign)
    }

    setPcXp = (e) => {
        let encounterid = e.target.dataset.encounterid;
        let pcid = e.target.dataset.pcid;
        let pcxp = e.target.value;
        this.api('setPcXp', {
            encounterid,
            pcid,
            pcxp,
        })
        let campaign = this.props.campaign;
        let encounter = id(campaign.encounters, encounterid);
        let pc = id(encounter.pcs, pcid);
        pc.xp = pcxp;
        this.performLocalChange(campaign)
    }
    setPcQty = (e) => {
        let encounterid = e.target.dataset.encounterid;
        let pcid = e.target.dataset.pcid;
        let qty = e.target.value;
        this.api('setPcQty', {
            encounterid,
            pcid,
            qty,
        })
        let campaign = this.props.campaign;
        let encounter = id(campaign.encounters, encounterid);
        let pc = id(encounter.pcs, pcid);
        pc.quantity = qty;
        this.performLocalChange(campaign)
    }

    deletePc = (e) => {
        let encounterid = e.target.dataset.encounterid;
        let pcid = e.target.dataset.pcid;
        this.api('deletePc', {
            encounterid,
            pcid,
        })
        let campaign = this.props.campaign;
        let encounter = id(campaign.encounters, encounterid);
        encounter.pcs = filter(encounter.pcs, pcid);
        this.performLocalChange(campaign)
    }

    addEnemy = (e) => {
        let encounterid = e.target.dataset.encounterid;
        let enemyid = new ObjectId();
        this.api('addEnemy', {
            encounterid,
            enemyid,
        });
        let campaign = this.props.campaign;
        let encounter = id(campaign.encounters, encounterid);
        let enemy = {
            _id: enemyid,
            docId: undefined,
            entityId: undefined,
            quantity: 0,
            combatants: [],
        };
        encounter.enemyTypes.push(enemy);
        this.performLocalChange(campaign)
    }
    
    setEnemyDoc = (e) => {
        let encounterid = e.target.dataset.encounterid;
        let enemyid = e.target.dataset.enemyid;
        let enemydoc = e.target.value;
        this.api('setEnemyDoc', {
            encounterid,
            enemyid,
            enemydoc,
        });
        let campaign = this.props.campaign;
        let encounter = id(campaign.encounters, encounterid);
        let enemy = id(encounter.enemyTypes, enemyid);
        enemy.docId = enemydoc;
        enemy.entityId = undefined;
        this.performLocalChange(campaign)
    }

    setEnemyEntity = (e) => {
        let encounterid = e.target.dataset.encounterid;
        let enemyid = e.target.dataset.enemyid;
        let enemyentity = e.target.value;
        this.api('setEnemyEntity', {
            encounterid,
            enemyid,
            enemyentity,
        });
        let campaign = this.props.campaign;
        let encounter = id(campaign.encounters, encounterid);
        let enemy = id(encounter.enemyTypes, enemyid);
        enemy.entityId = enemyentity;
        this.performLocalChange(campaign)
    }

    setEnemyQuantity = (e) => {
        let encounterid = e.target.dataset.encounterid;
        let enemyid = e.target.dataset.enemyid;
        let enemyqty = e.target.value;
        this.api('setEnemyQuantity', {
            encounterid,
            enemyid,
            enemyqty,
        });
        let campaign = this.props.campaign;
        let encounter = id(campaign.encounters, encounterid);
        let enemy = id(encounter.enemyTypes, enemyid);
        enemy.quantity = enemyqty;
        this.performLocalChange(campaign)
    }

    deleteEnemy = (e) => {
        let encounterid = e.target.dataset.encounterid;
        let enemyid = e.target.dataset.enemyid;
        this.api('deleteEnemy', {
            encounterid,
            enemyid,
        });
        let campaign = this.props.campaign;
        let encounter = id(campaign.encounters, encounterid);
        encounter.enemyTypes = filter(encounter.enemytypes, enemyid);
        this.performLocalChange(campaign);
    }

    toggleBox = (e) => {
        let encounterid = e.target.dataset.encounterid;
        let enemyid = e.target.dataset.enemyid;
        let combid = e.target.dataset.combid;
        let poolname = e.target.dataset.pool;
        let checked = e.target.checked;
        this.api('toggleBox', {
            encounterid,
            enemyid,
            combid,
            poolname,
            checked,
        });
    }

    setEncounterNote = (e) => {
        let encounterid = e.target.dataset.encounterid;
        let notes = e.target.value;
        let def = e.target.defaultValue;
        if(notes === def) return;
        this.api('setEncounterNote', {
            encounterid,
            notes,
        });
        let campaign = this.props.campaign;
        let encounter = id(campaign.encounters, encounterid);
        encounter.notes = notes;
        this.performLocalChange(campaign);
    }

    attemptSetEncounterLink = (e) => {
        let url = prompt('Enter a new URL');
        let encounterid = e.target.dataset.encounterid;
        if(url) {
            this.setEncounterLink(encounterid, url);
        }
    }

    setEncounterLink = (encounterid, url) => {
        this.api('setEncounterLink', {
            encounterid,
            url,
        })
        let campaign = this.props.campaign;
        let encounter = id(campaign.encounters, encounterid);
        encounter.link = url;
        this.performLocalChange(campaign);
    }

    render () {
        let campaign = this.props.campaign;
        let encounterId = this.props.match.params.EncounterId;
        let enc = campaign.encounters.find(x => x._id.toString() === encounterId);

        if(!enc) {this.props.history.push('../' + campaign._id)}
        
        return (
        <div>
            <DesktopTable
                campaign={campaign}
                enc={enc}
                // key={enc._id}
                attemptDeleteEncounter={this.attemptDeleteEncounter}
                attemptRenameEncounter={this.attemptRenameEncounter}
                addPc={this.addPc}
                setPcName={this.setPcName}
                deletePc={this.deletePc}
                setPcXp={this.setPcXp}
                setPcQty={this.setPcQty}
                addEnemy={this.addEnemy}
                setEnemyDoc={this.setEnemyDoc}
                setEnemyEntity={this.setEnemyEntity}
                setEnemyQuantity={this.setEnemyQuantity}
                deleteEnemy={this.deleteEnemy}
                toggleBox={this.toggleBox}
                setEncounterNote={this.setEncounterNote}
                setEncounterLink={this.attemptSetEncounterLink}
            />

            <MobileList
                campaign={campaign}
                enc={enc}
                // key={enc._id}
                attemptDeleteEncounter={this.attemptDeleteEncounter}
                attemptRenameEncounter={this.attemptRenameEncounter}
                addPc={this.addPc}
                deletePc={this.deletePc}
                setPcName={this.setPcName}
                setPcXp={this.setPcXp}
                setPcQty={this.setPcQty}
                addEnemy={this.addEnemy}
                setEnemyDoc={this.setEnemyDoc}
                setEnemyEntity={this.setEnemyEntity}
                setEnemyQuantity={this.setEnemyQuantity}
                deleteEnemy={this.deleteEnemy}
                toggleBox={this.toggleBox}
                setEncounterNote={this.setEncounterNote}
                setEncounterLink={this.attemptSetEncounterLink}
            />
        </div>
    )}
}
import React from 'react';
import { Link } from 'react-router-dom';
import './ApiTab.css';

export default class AppTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
        }
    }

    render() {
        let doc = this.props.doc;
        let apiKey = this.props.apiKey;
        let RegenerateApiKey = this.props.RegenerateApiKey;
        let RemoveApiKey = this.props.RemoveApiKey;
        return(
            <div className='api'>
                <h3>{!doc.unlisted ? 'Public' : 'Private'} Api</h3>
                <p>This is your document's API key. Use it when creating API calls for build processes.</p>
                <p>The API key allows you to download any set of filtered data from the server, already processed through the SGS calculator. Read below for usage instructions.</p>
                <p>Be aware that API access to this document is <strong>{!doc.unlisted ? 'public' : 'private'}</strong>. To change the privacy of the API, change the doc's listing status above.</p>
                <blockquote>
                    <p>Private Api Key: the key is hidden on this page to everyone who is not a document editor or owner.</p>
                    <p>Public Api Key: the key is not hidden. Anyone can see the key and use it to get the entire document.</p>
                </blockquote>
                {!apiKey && 
                    <>
                        <p>This doc doesn't have an API Key. Press the button to generate one.</p>
                        {doc.editable && <button onClick={RegenerateApiKey}>Generate Api Key</button>}
                    </>
                }
                {apiKey && <>
                    {!this.state.show && <input
                        type='password'
                        value={apiKey}
                        readOnly
                        />}
                    {this.state.show && <input
                        type='text'
                        value={apiKey}
                        onBlur={() => {this.setState({show: false})}}
                        readOnly
                    />}
                    <button onClick={() => {
                        this.setState({show: !this.state.show});
                    }}>👁</button>
                    <button onClick={() => {
                        navigator.clipboard.writeText(apiKey);
                    }}>Copy</button>
                    <h3>Edit Key</h3>
                    <p>If your API key has been compromised, hit regenerate to get a new one and invalidate the old one.</p>
                    <p>You will need to update any build scripts or environment variables that use the old API key.</p>
                    {doc.editable && <button onClick={RegenerateApiKey}>Regenerate</button>}
                    <br />
                    <p>You may also remove your API key, thus preventing anyone from accessing this doc through the Build API system.</p>
                    {doc.editable && <button onClick={RemoveApiKey}>Remove API Key</button>}
                    <br />
                </>}
                <h3>API Usage</h3>
                <p>The build API allows you to sort, filter, and format the data of your document from a single HTML POST call over HTTPS.</p>
                <p>Here's an example API call:</p>
                <code className='block'>
                    https://sgs-web.herokuapp.com/api/build?docid=60f33bc010dfb3517c9db6ce&amp;key=b5a8a7ec-a824-4b23-af13-2c2285279de9&amp;content=statblocks&amp;format=json&amp;sort=xp&amp;whitelisttags=monster,item&amp;blacklisttags=incomplete,pc&amp;whitelistentities=cloud,squall&amp;blacklistentities=kevin,bob
                </code>
                <h4>Fields</h4>
                <ul>
                    <li>request type: <code>POST</code></li>
                    <li>docId: the ID of this doc, <code>{doc._id}</code></li>
                    <li>key: the above API Key</li>
                    <li>contentFormat: <code>json | markdown | html</code></li>
                    <li>contentType: <code>statBlocks | entityCards | itemTable | rules</code>.<br/>The content type is only used with the markdown and html formats.<br/>With json, you're expected to do the processing/formatting yourself when you consume the data.</li>
                    <li>whitelistTags: only include entities with these tags. Comma separated, no spaces.</li>
                    <li>blacklistTags: exclude entities with these tags. Comma separated, no spaces.</li>
                    <li>includeEntities: only include these entities. Comma separated, no spaces.</li>
                    <li>excludeEntities: exclude these entitites. Comma separated, no spaces.</li>
                    <li>sortBy: <code>xp | xpReversed | name | tags</code>. Changes the order of output entities.</li>
                </ul>
                <p><strong>Note:</strong> You can construct API calls in the <Link to={'/doc/' + doc._id + '/Print'}>Print Tab</Link> and then click the button to copy the API call. This will let you preview the content before you make the call.</p>
                <br />
                <br />
                <br />
            </div>
        )
    }
}
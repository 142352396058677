import React from 'react';
import './AspectList.css';
import Modal from '../../Modal.jsx';

class AspectList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tagpicker: false,
            aspects: [],
        }
    }

    openModal = (e) => {
        let target = e.target.dataset.target;
        this.setState({
            [target]: true,
            aspects: this.props.aspects,
        })
    }
    closeModal = (e) => {
        let target = e.target.dataset.target;
        this.setState({
            [target]: false,
        })
        this.checkUpdate();
    }
    checkUpdate = () => {
        let inputs = document.querySelectorAll(".pane ol li input.aspect");
        console.log(inputs);
        inputs = Array.from(inputs);
        let aspects = inputs.map(x => x.value);
        console.log(this.props.aspects, aspects);
        if(this.props.aspects !== aspects) {
            this.props.setAspects(this.props.entity._id, aspects);
        }
    }
    addAspect = () => {
        this.setState({
            aspects: [...this.state.aspects, ''],
        })
    }
    removeAspect = (e) => {
        let target = e.target.dataset.aspect;
        let arr = this.state.aspects.filter(x => x !== target);
        this.setState({
            aspects: arr,
        })
    }
    renderStatic () {
        let aspectList = this.props.aspects?.join(', ');
        let contents = aspectList || '-';
        return(
            <td className='listCell'>
                {contents}
            </td>
        )
    }

    renderEditable () {
        // let entity = this.props.entity;
        // let doc = this.props.doc;
        let type = this.props.aspectType.slice(0,-1);
        let aspects = this.state.aspects;
        let aspectList = this.props.aspects?.join(', ');
        let contents = aspectList || '+';
        return(
            <td
                className='listCell'
                style={{
                    minWidth: aspectList.length / 4 + 'ch',
                    // maxWidth: '300px',
                }}
            >
                {/* <div className='tagList'>{contents}</div> */}
                <button
                    className='tagList'
                    onClick={this.openModal}
                    data-target='tagModal'
                >
                    {contents}
                </button>
                <Modal
                    state={this.state}
                    title={'Assign ' + type + 's'}
                    target='tagModal'
                    closeModal={this.closeModal}
                    closeButtonText='close'
                >
                    <ol>
                        {aspects?.map((aspect, index) => {return(
                            <li key={aspect}>
                                <input
                                    defaultValue={aspect}
                                    placeholder={type}
                                    // onChange={this.handleChange}
                                    className='aspect'
                                />
                                <button
                                    className='delete'
                                    data-aspect={aspect}
                                    onClick={this.removeAspect}
                                >x</button>
                            </li>
                        )})}
                    </ol>
                    <button onClick={this.addAspect}>➕Add {type}</button>
                </Modal>
            </td>
        )
    }
    render () {
        let entity = this.props.entity;
        if(entity.editable) return this.renderEditable();
        return this.renderStatic();
    }
}

export default AspectList;
import CompareProp from '../../CompareProp.js';
import { Link } from 'react-router-dom';
import SGS from 'sgs';
import { capitalize } from '../../TextUtils.js';

export default function EntityList (props) {
    let doc = props.doc;
    let entities = props.entities;
    // let skillCount = props.skillCount;
    
    return(
    <ul className='entity-list mobile'>
        {entities
            ?.sort(CompareProp('name', false, true))
            .map(entity => {
            let path = '/doc/'
            + doc._id
            + '/Entity/'
            + entity._id;
            let a = entity.aspects?.length;
            let as = entity.aspects?.length > 1 ? 's' : '';
            let f = entity.flaws?.length;
            let fs = entity.flaws?.length > 1 ? 's' : '';
            return(
            <li key={entity._id}>
                <Link to={path} className="button">
                    ▶ {entity.name} ({entity.xp.toLocaleString()} xp)
                </Link>
                <ul>
                    {entity.tags?.length > 0 &&
                        <li>
                            Tags: {entity.tags.map(tag => '#'+tag).join(', ')}    
                        </li>}
                    {(!!a || !!f) &&
                        <li>
                            {!!a && a + ' aspect'}{as}
                            {!!a && !!f && ', '}
                            {!!f && f + ' flaw'}{fs}
                        </li>}
                    {SGS.groups.map(groupName => {
                        let group = entity[groupName];
                        if(!group) return '';
                        if(group.length < 1) return '';
                        return(
                            <li key={groupName}>
                                {group.length} {capitalize(groupName)}
                            </li>
                    )})}
                    {entity.items?.length > 1 &&
                        <li>{entity.items.length} Items</li>
                    }
                </ul>
            </li>
        )})}
    </ul>
)}
import React, { Component } from "react";

class DatabaseInfo extends Component {
    // class ctor
    constructor(props){
        //call base constructor with props
        super(props)
        // initialize blank state var
        this.state = {
            info: {
                count: 0
            }
        }
    }

    // initializer
    componentDidMount() {
        this.getStats()
    }
    
    // class method
    getStats = () => {
        fetch('/api/getStats')
        .then(res => res.json())
        .then(info  => {
            if(!info) return;
            this.setState({ info })
        })
    }

    // render class instance
    render() {
        let i = this.state.info;
        return (
            <div>
                <ul>
                    <li>{i.users} users</li>
                    <li>{i.docs} docs</li>
                    <li>{i.campaigns} campaigns</li>
                </ul>
            </div>
        )
    }
}

export default DatabaseInfo
export default function SortEntities (options, entities) {
    if(!entities) return null;
    let sortBy = options.sortBy || options.sortby;
    if(!sortBy) return entities;

    sortBy = sortBy.replace(' ', '').toLowerCase();
    if(sortBy === 'xp') {
        entities.sort((a, b) => {
            if(a.xp < b.xp) return -1
            if(a.xp > b.xp) return 1
            if(a.name < b.name) return -1
            if(a.name > b.name) return 1
            return 0
        });
    }
    else if(sortBy === 'xpreversed') {
        entities.sort((a, b) => {
            if(a.xp < b.xp) return 1
            if(a.xp > b.xp) return -1
            if(a.name < b.name) return -1
            if(a.name > b.name) return 1
            return 0
        });
    }
    else if(sortBy === 'name') {
        entities.sort((a, b) => {
            if(a.name < b.name) return -1
            if(a.name > b.name) return 1
            return 0
        });
    }
    else if(sortBy === 'tags') {
        entities.sort((a, b) => {
            let at = a.tags.sort().join(' ');
            let bt = b.tags.sort().join(' ');
            if(at < bt) return -1
            if(at > bt) return 1
            if(a.name < b.name) return -1
            if(a.name > b.name) return 1
            return 0
        });
    }
    return entities;
}
import React from 'react';
import Footer from '../Footer.jsx';
import Spinner from '../Spinner.jsx';
import UserInline from '../UserInline.jsx';

class SummaryTab extends React.Component{
    render() {
        let doc = this.props.doc;
        // console.log(doc.total);
        if(!doc) return '';
        let rows = {
            'Owner': <UserInline user={doc.owner} />,
            'Editors': doc.editors?.length,
            'Parent Docs': doc.dependencies?.length,
            'Child Docs': this.props.children?.length,
            'Campaigns': this.props.campaigns?.length,
            'Primaries': doc.primaries?.length,
            'Tacticals': doc.tacticals?.length,
            'Skills': doc.skills?.length,
            'Thresholds': doc.thresholds?.length,
            'Pools': doc.pools?.length,
            'Rules': <a href="https://gitlab.com/voidspiral/sgs-web/-/issues/181">task #181</a>,
            'Entities': doc.entities?.length,
            'Tags': doc.tags?.length,
            'Xp': doc.total?.toLocaleString(),
        }
        document.title = ['Summary', doc.name, 'SGS'].join(' - ');
        return(<div>
            <h2>Document Summary</h2>
            <table><tbody>
                {Object.keys(rows).map((key) => {return(
                    <tr key={key}>
                        <th>{key}</th>
                        <td>{rows[key] !== undefined ? rows[key] : <Spinner small={true} />}</td>
                    </tr>
                )})}
            </tbody></table>
            <Footer/>
        </div>)
    }
}

export default SummaryTab;
import React from 'react';

export default function StatValueType(props) {
    const types = {
        number:
        {
            examples: '3, +2, +1',
            display: 'Number Only',
        },
        dieSize:
        {
            examples: 'd8, d4, d20',
            display: 'Die Size',
        },
        dicePool:
        {
            examples: '1d6, 4d8, 3d6+6',
            display: 'Dice Pool',
        },
        formula:
        {
            examples: 'max(statref(agility), statref(creativity))',
            display: 'Formula',
        },
    }
    return (
        <select
            title={types[props.stat.valueType].examples}
            value={props.stat.valueType}
            onChange={props.changeStatValueType}
            data-statid={props.stat._id}
            data-statname={props.stat.name}
            data-statgroup={props.statGroup}
        >
            {Object.entries(types).map(([typeName, type]) => {
                return (
                <option
                    value={typeName}
                    title={type.examples}
                    key={typeName}
                >
                    {type.display}
                </option>
            )})}
        </select>
    )
}
import React from 'react';

export default function LimitationsTable (props) {
    const doc = props.doc;
    const renameStat = props.renameStat;
    const addStat = props.addStat;
    const removeStat = props.removeStat;
    const changeLimitMult = props.changeLimitMult;
    const multipliers = [
        'none',
        '10',
        '5',
        '3',
        '2',
        '1.5',
        '1',
        '3/4',
        '2/3',
        '1/2',
        '1/3',
        '1/4',
        '1/10',
    ]

    return(<div className='panel'>
        <h4>Limitations</h4>
        <table>
            <thead>
                <tr>
                    <th>Limitation</th>
                    <th>Multiplier</th>
                    {doc.editable && <th>Rename</th>}
                    {doc.editable && <th>Delete</th>}
                </tr>
            </thead>
            <tbody>
                {doc?.limitations?.map((limit) => {
                    let xs = limit.externalSource;
                    let title = !!xs ? 'source ' + xs.type + ' doc: ' + xs.name : null;
                    return(
                    <tr key={limit.name}>
                        <td>
                            <span
                                title={title}
                            >
                                {title && '🔼'}
                                {limit.name}
                            </span>
                        </td>
                        <td className='input-cell'>
                            {!limit.editable && limit.multiplier}
                            {limit.editable && <select
                                data-skillname={limit.name}
                                value={limit.multiplier}
                                onChange={changeLimitMult}
                            >
                                {multipliers.map((mult) => {return(
                                    <option key={mult}>{mult}</option>
                                )})}
                            </select>}
                        
                        </td>
                        {doc.editable && <td>
                            {limit.editable && <button
                            onClick={() => 
                                renameStat(
                                    'limitations',
                                    limit.name
                                )
                            }
                        >
                            rename
                        </button>}</td>}
                        {doc.editable && <td>
                            {limit.editable && <button
                            className='delete'
                            onClick={() => {
                                removeStat(
                                    'limitations',
                                    limit.name
                                )
                            }}
                        >x</button>}</td>}
                    </tr>
                )})}
                {doc.editable && <tr><td colSpan='99'>
                    <button
                        onClick={() => {
                            addStat(
                                'limitations',
                                'Limitation'
                            )
                        }}
                    >
                        Add New
                    </button>
                </td></tr>}
            </tbody>
        </table>
    </div>)
}
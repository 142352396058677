export default function FilterEntities (options, entities) {
    if(!entities) return null;
    if(!!options.includeEntities || !!options.includeentities){
        // Name whitelist
        let nw = (options.includeEntities || options.includeentities)
        .split(',')
        .map(x => x.toLowerCase())
        .map(x => x.trim());
        entities = entities.filter(entity => {
            let match = false;
            nw.forEach(inclusion => {
                match = match || entity.name.toLowerCase() === inclusion;
            });
            return match;
        });
    }
    if(!!options.whitelistTags || !!options.whitelisttags){
        // tag whitelist
        let wl = (options.whitelistTags || options.whitelisttags)
        .split(',')
        .map(x => x.toLowerCase())
        .map(x => x.replace(/#/g, ''))
        .map(x => x.trim());
        entities = entities.filter(entity => {
            let match = false;
            wl.forEach(tag => {
                match = match || entity.tags.includes(tag);
            });
            return match;
        });
    }
    if(!!options.blacklistTags || !!options.blacklisttags){
        // tag blacklist
        let bl = options.blacklistTags || options.blacklisttags
        .split(',')
        .map(x => x.toLowerCase())
        .map(x => x.replace(/#/g, ''))
        .map(x => x.trim());
        entities = entities.filter(entity => {
            let match = false;
            bl.forEach(tag => {
                match = match || entity.tags.includes(tag);
            });
            return !match;
        });
    }
    if(!!options.excludeEntities || !!options.excludeentities){
        // name exclusions
        let xl = (options.excludeEntities || options.excludeentities)
            .split(',')
            .map(x => x.toLowerCase())
            .map(x => x.trim());
        entities = entities.filter(entity => {
            let match = false;
            xl.forEach(exclusion => {
                match = match || entity.name.toLowerCase() === exclusion;
            });
            return !match;
        });
    }
    return entities;
}
import React from "react";
import './loginbox.css';
import UserDataCache from "./UserDataCache.jsx";
// import {Redirect} from 'react-router-dom';
// import {useHistory} from 'react-router';
import {withRouter} from 'react-router-dom';

class LoginBox extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            email: '',
            username: '',
            password: '',
            error: '',
            token: {}
        }
    }
    

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name
        this.setState({
            [name]: value
        });
    }
    attemptLogin = (event) => {
        event.preventDefault();
        fetch('/api/register', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json'
            },
            body: JSON.stringify({
                email: this.state.email,
                username: this.state.username,
                password: this.state.password
            })
        })
        .then(res => res.json())
        .then((res) => {
            if(!res) {
                return;
            }
            if(res.error){
                console.log(res.error);
                let e = JSON.stringify(res.error.keyValue) + ' already in use.';
                console.log(e);
                return this.setState({error: e});
            }
            console.log(res);
            UserDataCache.saveToken(res.token);
            this.props.history.push("/");
        })
        .catch(function(e) {this.setState({error: e})
        });
    }

    render() {
        let err = this.state.error ? <label>{this.state.error}</label> : '';
        let token = UserDataCache.getTokenObject();
        if(!!token) {
            this.props.history.push("/");
            return (
                ''
            );
        }
        return (
            <form
            name="loginbox"
            className="loginbox"
            onSubmit={this.attemptLogin}>
                <label><span>Email</span> 
                    <input
                    type="text"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleInputChange} />
                </label>
                <label><span>Username</span> 
                    <input
                    type="text"
                    name="username"
                    value={this.state.username}
                    onChange={this.handleInputChange} />
                </label>
                <label><span>Password</span>
                    <input
                    type="password"
                    name="password"
                    value={this.state.password}
                    onChange={this.handleInputChange} />
                </label>
                <input type="submit" value="register" />
                {err}
            </form>
        );
    }
}

export default withRouter(LoginBox);
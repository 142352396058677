import React from 'react';
import CompareProp from '../../CompareProp.js';
import StatValueType from './StatValueType.jsx';
import FormulaEditBox from './FormulaEditBox.jsx';
import OffsetCell from './OffsetCell.jsx';
import StatDisplayFormatEditor from './StatDisplayFormatEditor.jsx';
import StatDisplayVisibilityEditor from './StatDisplayVisibilityEditor.jsx';
import SetStatIndex from './SetStatIndex.jsx';
import EditStatExponent from './EditStatExponent.jsx';

export default function SkillsTable (props) {
    const doc = props.doc;
    const renameStat = props.renameStat;
    const addStat = props.addStat;
    const removeStat = props.removeStat;
    const changeSkillGroup = props.changeSkillGroup;
    const changeSkillStat = props.changeSkillStat;
    const sortSkills = props.sortSkills;
    const skillSortProp = props.skillSortProp;
    const groups = [
        'none',
        'Academic',
        'Combat',
        'Espionage',
        'Social',
        'Special',
    ];
    const skills = doc?.skills || [];
    skills.forEach((x, i) => x.index = i);
    const group = 'skills';
    skills.sort(CompareProp(skillSortProp));
    return(<div className='panel'>
        <h4>Skills</h4>
        <table>
            <thead>
                <tr>
                    <th>
                        <button
                            onClick={sortSkills}
                            data-prop='name'
                            title='sort by name'
                        >Skill▼</button>
                    </th>
                    <th>
                        <button
                            onClick={sortSkills}
                            data-prop='skillGroup'
                            title='sort by group'
                        >Group ▼</button>
                    </th>
                    <th>
                        <button
                            onClick={sortSkills}
                            data-prop='skillStat'
                            title='sort by stat'
                        >Stat ▼</button>
                    </th>
                    <th>Value Type</th>
                    <th>Formula</th>
                    <th>Value Offset</th>
                    {doc.editable && <th>Value Exponent</th>}
                    {doc.editable && <th>Display Format</th>}
                    {doc.editable && <th>Visibility</th>}
                    {doc.editable && <th>Reorder</th>}
                    {doc.editable && <th>Rename</th>}
                    {doc.editable && <th>Delete</th>}
                </tr>
            </thead>
            <tbody>
                {skills.map((skill, index) => {
                    let xs = skill.externalSource;
                    let title = !!xs ? 'source ' + xs.type + ' doc: ' + xs.name : null;
                    return(
                    <tr key={skill.name}>
                        <td>
                            <span
                                title={title}
                            >
                                {title && '🔼'}
                                {skill.name}
                            </span>
                        </td>
                        <td className='input-cell'>
                            {!skill.editable && <span>
                                {skill.skillGroup}    
                            </span>}
                            {skill.editable && <select
                                data-skillname={skill.name}
                                value={skill.skillGroup}
                                onChange={changeSkillGroup}
                            >
                                {groups.map(skillGroup => {return(
                                    <option key={skillGroup}>{skillGroup}</option>
                                )})
                                }
                            </select>}
                        </td>
                        <td className='input-cell'>
                            {!skill.editable && <span>
                                {skill.skillStat}    
                            </span>}
                            {skill.editable && <select
                                data-skillname={skill.name}
                                value={skill.skillStat}
                                onChange={changeSkillStat}
                            >
                                <option>none</option>
                                {doc.primaries.map((primary) => {return(
                                    <option key={primary._id}>{primary.name}</option>
                                )})}
                            </select>}
                        
                        </td>
                        <td>
                            {!skill.editable && <span>
                                {skill.valueType}    
                            </span>}
                            {skill.editable && <StatValueType
                                stat={skill}
                                statGroup={group}
                                changeStatValueType={props.changeStatValueType}
                            />}
                        </td>
                        <td>
                            {skill.valueType === 'formula' &&
                            <FormulaEditBox
                                doc={doc}
                                changeFormula={props.changeFormula}
                                formula={skill.formula}
                                statname={skill.name}
                                statgroup={group}
                                editable={skill.editable}
                            />}
                        </td>
                        <OffsetCell
                            stat={skill}
                            changeTacOffset={props.changeTacOffset}
                            group={group}
                        />
                        <EditStatExponent 
                            stat={skill}
                            doc={doc}
                            sendMessage={props.sendMessage}
                            group={group}
                        />
                        {doc.editable && <td>
                            <StatDisplayFormatEditor 
                                stat={skill}
                                sendMessage={props.sendMessage}
                                group={group}
                                />
                        </td>}
                        {doc.editable && <td>
                            <StatDisplayVisibilityEditor 
                                stat={skill}
                                sendMessage={props.sendMessage}
                                group={group}
                            />
                        </td>}
                        {doc.editable && 
                            <SetStatIndex
                                doc={doc}
                                groupName={group}
                                stat={skill}
                                startingIndex={skill.index}
                                sendMessage={props.sendMessage}
                            />
                        }
                        {doc.editable && <td>
                            {skill.editable && <button
                                onClick={() => 
                                    renameStat(
                                        group,
                                        skill.name
                                    )
                                }
                            >
                                rename
                            </button>}
                        </td>}
                        {doc.editable && <td>
                            {skill.editable && <button
                                className='delete'
                                onClick={() => {
                                    removeStat(
                                        group,
                                        skill.name
                                    )
                                }}
                            >
                                x
                            </button>}
                        </td>}
                    </tr>
                )})}
                {doc.editable && <tr><td colSpan='99'>
                    <button
                        onClick={() => {
                            addStat(
                                group,
                                'Skill'
                            )
                        }}
                    >
                        Add New
                    </button>
                </td></tr>}
            </tbody>
        </table>
    </div>)
}
import Sgs from 'sgs';

export default function GetPrototypes (doc) {
    let prototypes = {};
    let bail = false;
    Sgs.groups.forEach(group => {
        if(!doc[group]) return bail = true;
        doc[group].forEach(stat => {
            prototypes[stat.name] = stat;
        })
    })
    if(bail) return null;
    // console.log(prototypes);
    return prototypes;
}
import React, { useState, useEffect } from 'react';
import './EditStat.css';

const addStat = (sendMessage, entityid, statname, statgroup, valueType) => {
    sendMessage({
        operation: 'add entity stat',
        entityid,
        statgroup,
        statname,
        valueType
    });
}

const removeStat = (sendMessage, entityid, statname, statgroup) => {
    sendMessage({
        operation: 'remove entity stat',
        entityid,
        statgroup,
        statname,
    });
}

const DeleteButton = (props) => {
    if(props.static === true) return '';
    return (
        <button
        className='side bottom delete'
        onClick={event => removeStat(
            props.sendMessage,
            props.entityid,
            props.statname,
            props.statgroup,
            )}
            >
            x
        </button>
    )
}

const changeDicePool = (e, poolText, setPoolText) => {
    // console.log('changeDicePool event')
    // console.log(e.target);
    let value = e.target.value;
    // console.log(value);
    let regex = /[^\ddDfF+\- ]/gmi;
    if(!!value) {
        value = value?.replaceAll(regex, '');
        // console.log("original", e.target.dataset.original, '->', value);
    }
    setPoolText(value);
}

const changeStatNumber = (e, poolText, setStatNumber, changeStat) => {
    // console.log('changeStatNumber');
    // console.log(e.target);
    let value = e.target.value;
    // console.log(value);
    setStatNumber(value);
    changeStat(e);
}

const wasChanged = (e, changeStat) => {
    let n = e.target.value;
    let o = e.target.dataset.original;
    // console.log(o, '->', n);
    if(n !== o) changeStat(e);
}

const handleKeyPress = (e, dicePool, changeStat) => {
    let key = e.key;
    // console.log(key);
    if(key === 'Enter') {
        wasChanged(e, changeStat);
        e.target.blur();
    }
    if(key === 'Escape') {
        e.target.blur();
    }
}

const focused = (e) => {
    // console.log('focused');
    // let target = e.target;
    // target.type = 'text';
    // target.setSelectionRange(0,100);
    // target.type = 'number';
}

export default function EditStat(props){
    let statname = props.prototype.name;
    let type = props.prototype.valueType;
    let dicePool = props.stat?.dicePool || '';
    const [poolText, setPoolText] = useState(dicePool);
    const [statNumber, setStatNumber] = useState(props.stat?.number || 0);
    useEffect(() => {
        if(!props.stat) return;
        let element = document.getElementById(props.stat._id);
        if(!element) return;
        // console.log(element);
        // let active = document.activeElement;
        // console.log(active);
        // if(element === active) return console.log(statname, 'element is active');
        // console.log(statname, type, props.stat)
        if(type === 'number') {element.value = parseInt(props.stat.number)}
        else if(type === 'dieSize') {element.value = props.stat.dieSize}
        else if(type === 'dicePool') {element.value = props.stat.dicePool}
        else if(type === 'formula') {element.value = props.stat.calculated?.toString()}
    })
    if(!props.entity.editable) {return (
        <td>
            {props.stat && type === 'number' && props.stat.number}
            {props.stat && type === 'dieSize' && props.stat.dieSize}
            {props.stat && type === 'dicePool' && props.stat.dicePool}
            {props.stat && type === 'formula' && props.stat.calculated?.toString()}
        </td>
    )}
    // console.log(type);
    // return(<td>no</td>);
    if(!props.stat) {return(
        <td className='edit-stat-box'>
            <button onClick={event => addStat(
                props.sendMessage,
                props.entity._id,
                statname,
                props.statgroup,
                type
            )} className='whole'>+</button>
        </td>
    )}
    if(type === 'number') {return(
        <td
            className='edit-stat-box'
            // title={props.stat?.formulaText}
        >
            <input
                id={props.stat._id}
                key={props.stat._id}
                type='number'
                step='1'
                // style={{width: width}}
                // value={parseInt(props.stat.number)}
                // defaultValue={parseInt(props.stat.number)}
                value={statNumber}
                data-entityid={props.entity._id}
                data-statgroup={props.statgroup}
                data-statid={props.stat._id}
                data-valuetype={type}
                data-original={parseInt(props.stat.number)}
                onBlur={e => wasChanged(e, props.changeStat)}
                onKeyDown={e => handleKeyPress(e, statNumber, props.changeStat)}
                onChange={(event) =>
                    changeStatNumber(event, statNumber, setStatNumber, props.changeStat)}
                onFocus={focused}
                // title={props.stat.formulaText}
                // readOnly={!!props.prototype.formula}
            />
            <DeleteButton
                sendMessage={props.sendMessage}
                entityid={props.entity._id}
                statname={statname}
                statgroup={props.statgroup}
                static={props.static}
            />
        </td>
    )}
    if(type === 'dieSize') {return(
        <td
            className='edit-stat-box'
            style={{minWidth: '8ch'}}
        >
            <select
                id={props.stat._id}
                key={props.stat._id}
                value={props.stat.dieSize}
                onChange={props.changeStat}
                data-entityid={props.entity._id}
                data-statgroup={props.statgroup}
                data-statid={props.stat._id}
                data-valuetype={type}
                data-original={props.stat.dieSize}
                onKeyDown={e => handleKeyPress(e, dicePool, props.changeStat)}
            >
                <option>none</option>
                <option>d4</option>
                <option>d6</option>
                <option>d8</option>
                <option>d10</option>
                <option>d12</option>
                <option>d20</option>
                <option>d100</option>
            </select>
            <DeleteButton
                sendMessage={props.sendMessage}
                entityid={props.entity._id}
                statname={statname}
                statgroup={props.statgroup}
                static={props.static}
            />
        </td>
    )}
    if(type === 'dicePool') {return(
        <td
            className='edit-stat-box'
            style={{minWidth: props.stat.dicePool.toString().length + 1 + 'ch'}}
        >
            <input
                id={props.stat._id}
                key={props.stat._id}
                type='text'
                // pattern={pattern}
                // title='xdy+z'
                placeholder='XdY+Z'
                value={poolText}
                data-entityid={props.entity._id}
                data-statgroup={props.statgroup}
                data-statid={props.stat._id}
                data-valuetype={type}
                data-original={props.stat.dicePool.toString()}
                onBlur={e => wasChanged(e, props.changeStat)}
                onKeyDown={e => handleKeyPress(e, dicePool, props.changeStat)}
                onChange={(event) =>
                    changeDicePool(event, poolText, setPoolText)}
            />
            <DeleteButton
                sendMessage={props.sendMessage}
                entityid={props.entity._id}
                statname={statname}
                statgroup={props.statgroup}
                static={props.static}
            />
        </td>
    )}
    if(type === 'formula' && props.static) {return(
        <td
            className='edit-stat-box'
            style={{minWidth: props.stat.calculated?.toString().length + 1 + 'ch'}}
        >
            <span title={props.stat.formulaText}>
                {props.stat.calculated?.toString()}
            </span>
        </td>
    )}
    if(type === 'formula' && !props.static) {
        let errorClass = !!props.stat.error ? ' error' : '';
        // console.log(props.stat)
        return(
        <td
            className={'edit-stat-box' + errorClass}
            style={{minWidth: props.stat.calculated?.toString().length + 1 + 'ch'}}
        >
            <input
                id={props.stat._id}
                key={props.stat._id}
                // type='number'
                // style={{width: width}}
                value={props.stat.calculated?.toString()}
                data-entityid={props.entity._id}
                data-statgroup={props.statgroup}
                data-statid={props.stat._id}
                data-valuetype={props.stat.valueType}
                data-original={props.stat.calculated?.toString()}
                // onChange={props.changeStat}
                title={props.stat.error || props.stat.formulaText}
                readOnly={true}
            />
            <DeleteButton
                sendMessage={props.sendMessage}
                entityid={props.entity._id}
                statname={statname}
                statgroup={props.statgroup}
                static={props.static}
            />
        </td>
    )}
    return (
        <td>
            none
        </td>
    )
}
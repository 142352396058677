export default function XpValueCalc(stat, format, group) {
    let output = ''
    if(stat.valueType !== 'formula') {
        output += format.li.pre;
        output += 'XP Cost: ';
        output += format.code.pre;
        if(stat.valueType === 'number') {
            output += '(stat value';
            if(stat.offset !== 0) {
                output += ' ';
                output += stat.offset > 0 ? '+' : '';
                output += stat.offset;
            }
            output += ')²';
        }
        else if(stat.valueType === 'dieSize') {
            output += '(die size / 2, rounded up)²'
        }
        else if(stat.valueType === 'dicePool') {
            output += '(pool average, rounded up)²'
        }
        if(group === 'primaries') {
            output += ' × 2'
        }
        output += format.code.suf;
        output += format.li.suf;
    }
    if(stat.valueType === 'formula') {
        output += format.li.pre;
        output += 'XP Cost: '
        output += format.code.pre;
        output += 'free'
        output += format.code.suf;
        output += format.li.suf;
    }
    return output;
}
// import addPlus from '../addPlus.js';
import SortEntities from '../SortEntities.js';
import FilterEntities from '../FilterEntities.js';
// import LimMultFormat from '../LimMultFormat.js';
import GetPrototypes from '../GetPrototypes.js';
// import Sgs from 'sgs';

export default function jsonOutput(doc, options) {
    let entities = SortEntities(options, doc.entities);
    entities = FilterEntities(options, entities);
    let prototypes = GetPrototypes(doc);
    doc.entities = entities;
    // let groups = Sgs.groups;
    // groups.forEach(group => {
    //     doc[group]?.forEach(stat => {
    //         if(!!stat.formula) {
    //             stat.formula = null;
    //         }
    //     })
    // })
    doc.entities?.forEach(entity => {
        delete entity.editable;
        // groups.forEach(group => {
        //     entity[group]?.forEach(stat => {
        //         if(!!stat.formula) {
        //             stat.formula = null;
        //         }
        //     })
        // })
        // if(entity.items.length > 0) {
        //     entity.items = entity.items
        //     .filter(x => !!x)
        //     .map(x => x._id);
        // }
    })
    doc.prototypes = prototypes;
    return doc;
}
import React from 'react';

/**
 * shows a symbol to give users feedback about the validity of a form
 * @param {Boolean} props.value the value to base the symbol on.
 * @param {string} props.noValueMsg the message to display when there's no value
 * @param {string} props.falseMsg the message to display when the validation fails 
 */
export default function Valid (props) {
    let content = ' ';
    let title = '';
    if(props.value === undefined || props.value === null) {
        content = ' ';
        title = props.noValueMsg || 'enter a value';
    }
    if(props.value === true) {
        content = '✔';
        title = 'valid';
    }
    if(props.value === false) {
        content = '❌';
        title = props.falseMsg || 'invalid'
    }
    return (
        <span title={title} className='validation'>{content}</span>
    )
}
function setEditable (doc, editable) {
    // console.log(editable);
    let groups = [
        'primaries',
        'tacticals',
        'thresholds',
        'pools',
        'skills',
        'limitations',
        'entities',
    ]
    setObjectEditable(doc, editable);
    groups.forEach(group => {
        // console.log(group, doc[group]);
        if(!doc[group]) return;
        doc[group].forEach(element => {
            // console.log(element.name);
            setObjectEditable(element, editable);
        });
    })
}

function setObjectEditable(object, editable) {
    // console.log(object);
    if(!!object
        && !object.external
        && editable === true) {
            object.editable = true;
        }
}

export default setEditable;
import Sgs from 'sgs';
import getStatValue from 'sgs/src/getStatValue.js';

/**
 * Adds together the skill of an item, the skill of the user,
 * and the primary associated with the skill.
 * @param {*} doc 
 * @param {*} owner 
 * @param {*} stat 
 * @param {*} groupName 
 * @returns 
 */
export default function EquipSkillStack (doc, owner, stat, groupName) {
    let value = getStatValue(stat);
    if(groupName.toLowerCase() === 'skills') {
        let prototypeSkill = doc.skills.find(x => x.name === stat.name);
        let ownerStat = owner.primaries?.find(x => x.name === prototypeSkill.skillStat);
        // console.log(ownerStat)
        let ownerSkill = owner.skills?.find(x => x.name === stat.name);
        // console.log(ownerSkill)
        let poolString = getStatValue(stat);
        if(ownerStat) poolString += '+' + getStatValue(ownerStat);
        if(ownerSkill) poolString += '+' + getStatValue(ownerSkill);
        // console.log(poolString)
        let pool = new Sgs.DicePool(poolString);
        // console.log(pool)
        // console.log(prototypeSkill.displayFormat)
        value = pool.toStringOrNumber();
    }
    return value;
}
const MdFormat = {
    h1:     {pre: "\n\n# ", suf: "\n"},
    h2:     {pre: "\n\n## ", suf: "\n"},
    h3:     {pre: "\n\n### ", suf: "\n"},
    h4:     {pre: "\n\n#### ", suf: "\n"},
    h5:     {pre: "\n\n##### ", suf: "\n"},
    h6:     {pre: "\n\n###### ", suf: "\n"},
    ul:     {pre: "", suf: ""},
    li:     {pre: "\n- ", suf: ""},
    li2:     {pre: "\n\t- ", suf: ""},
    table:  {pre: "\n", suf: "\n"},
    thead:  {pre: "", suf: ""},
    tbody:  {pre: "", suf: ""},
    tr:     {pre: "", suf: "|\n"},
    td:     {pre: "|", suf: ""},
    th:     {pre: "|", suf: ""},
    p:      {pre: "\n", suf: ""},
    card:   {pre: "\n<span class='card'>\n", suf: "\n\n</span>\n"},
    code:   {pre: "`", suf: "`"},
    bold:   {pre: "**", suf: "**"},
    italic:   {pre: "*", suf: "*"},
    break:  {pre: "\n\n", suf: ""},
    line:   {pre: "\n---\n", suf: ""},
    wrap:   {pre: "<wrap class='name'>", suf: "</wrap>"},
    span:   {pre: "<span>", suf: "</span>"},
}

export default MdFormat;
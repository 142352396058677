import CompareProp from "../CompareProp.js";

/**
 * sorts and filters a dropdown selector for entities
 * @param {Object} props.campaign the campaign the encounter belongs to
 * @param {Object} props.enc the encounter object this is operating on
 * @param {Object} props.enemy the enemy this is operating on
 * @param {Function} props.setEnemyEntity the function to set the enemy entity
 */
export default function SortAndFilterEnemies (props) {
    let enc = props.enc;
    let enemy = props.enemy;
    let setEnemyEntity = props.setEnemyEntity;
    let filter = props.campaign.enemyfilter;
    let sortOrder = props.campaign.enemysortorder;
    let includeParent = props.campaign.includeparententities;
    // console.log(filter, sortOrder, includeParent);

    let list = enemy.doc?.entities
    .filter(x => {
        let tags = filter.split(',').map(x => x.trim());
        let match = false;
        for(let i = 0; i < tags.length; i++) {
            let tag = tags[i];
            if(x.tags.includes(tag)) {
                match  = true;
                break;
            }
        }
        if(includeParent) {
            return(match);
        }
        else {
            return(match && !x.internal);
        }
    })

    switch(sortOrder?.toLowerCase() || sortOrder?.toLowerCase() || '') {
        case 'xp':
        case 'xp ascending':
            list = list?.sort(CompareProp('xp', false, true));
            break;
        case 'xp descending':
            list = list?.sort(CompareProp('xp', true, true));
            break;
        case 'name, reversed':
            list = list?.sort(CompareProp('name', true, true));
            break;
        default:
        case 'name':
            list = list?.sort(CompareProp('name', false, true));
            break;
    }

    return(
    <select
        data-encounterid={enc._id}
        data-enemyid={enemy._id}
        value={enemy.entityId || ""}
        onChange={setEnemyEntity}
    >
        <option value="">none</option>
        {list
        ?.map(entity => {return(
            <option
                value={entity._id}
                key={entity._id}
            >
                {entity.name} ({entity.xp?.toLocaleString()})
            </option>
        )})}
    </select>
    )
}
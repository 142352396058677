import React from 'react';
import './TagList.css';
import Modal from '../../Modal.jsx';

class TagList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tagpicker: false,
            doctags: [],
            newTag: '',
        }
    }
    addNewTag = (e) => {
        e.preventDefault();
        let id = this.props.entity._id;
        // console.log(e.target);
        let tag = this.state.newTag;
        this.props.addTag(id, tag);
        this.setState({ newTag: '' })
    }
    changeNewTag = (e) => {
        this.setState({ newTag: e.target.value.toLowerCase() })
    }
    addTag = (tag) => {
        let id = this.props.entity._id;
        // let tag = e.target.dataset.tag;
        this.props.addTag(id, tag);
        // this.setState({ tagpicker: false })
    }
    removeTag = (tag) => {
        let id = this.props.entity._id;
        // let tag = e.target.dataset.tag;
        this.props.removeTag(id, tag);
    }
    toggleTag = (e) => {
        let checked = e.target.checked;
        let tag = e.target.name;
        if(checked) {
            this.addTag(tag);
        }
        else {
            this.removeTag(tag);
        }
    }

    openModal = (e) => {
        let target = e.target.dataset.target;
        this.setState({
            [target]: true,
        })
    }
    closeModal = (e) => {
        let target = e.target.dataset.target;
        this.setState({
            [target]: false,
        })
    }
    renderStatic () {
        let entity = this.props.entity;
        let etags = entity?.tags;
        let taglist = etags?.map(tag => '#'+tag).join(', ');
        let contents = taglist || '-';
        return(
            <td className='listCell'>
                {contents}
            </td>
        )
    }
    renderEditable () {
        let entity = this.props.entity;
        let doc = this.props.doc;
        let dtags = doc.entityTags;
        let etags = entity?.tags;
        let taglist = etags?.map(tag => '#'+tag).join(', ');
        let contents = taglist || '+';
        return(
            <td className='listCell'>
                {/* <div className='tagList'>{contents}</div> */}
                <button
                    className='tagList'
                    onClick={this.openModal}
                    data-target='tagModal'
                >
                    {contents}
                </button>
                <Modal
                    state={this.state}
                    title='Select Tags'
                    target='tagModal'
                    closeModal={this.closeModal}
                    closeButtonText='close'
                >
                    <table><tbody>
                        {dtags.map(dt => {
                            let eHasTag = entity.tags?.includes(dt);
                            return(
                            <tr key={dt}>
                                <td>
                                    <label
                                        htmlFor={dt}
                                        className={eHasTag ? 'checked' : ''}
                                    >
                                        {dt}
                                    </label>
                                </td>
                                <td>
                                    <input 
                                        type='checkbox'
                                        name={dt}
                                        id={dt}
                                        checked={eHasTag ? true : false}
                                        onChange={this.toggleTag}
                                    />
                                </td>
                            </tr>
                        )})}
                        <tr>
                            <td>
                                <form
                                    onSubmit={this.addNewTag}
                                    id='newTagForm'
                                >
                                    <label>Add a Tag:&nbsp;
                                        <input
                                            placeholder='new tag'
                                            value={this.state.newTag}
                                            onChange={this.changeNewTag}
                                        />
                                    </label>
                                </form>
                            </td>
                            <td>
                                    <input
                                        form='newTagForm'
                                        type='submit'
                                    />
                            </td>
                        </tr>
                    </tbody></table>
                </Modal>
            </td>
        )
    }
    render () {
        let entity = this.props.entity;
        if(entity.editable) return this.renderEditable();
        return this.renderStatic();
    }
}

export default TagList;
import React from 'react';
import './Footer.css';

export default class Footer extends React.Component {
    constructor (props){
        super(props)
        // this.id = this.props.match.params.GameId
        this.state = {
            animation: '',
        }
    }

    clickSpin = (e) => {
        let r = Math.random();
        let range = .2 + r * 4;
        this.setState({
            animation: 'spin ' + range + 's linear infinite',
        });
    }
    clear = (e) => {
        this.setState({
            // animation: 'unspin ' + .2 + 's linear 1',
            animation: '',
        });
    }

    render() {return(
        <div className="footer dink">
            <div className='foot-center'>
                <span className='small subtle'>
                    Spiral Game System
                    &copy; 2020 &ndash; {new Date().getFullYear()}&nbsp;
                    <a
                        href="https://voidspiral.com"
                        target="_blank"
                        rel='noreferrer'
                    >
                        Voidspiral Entertainment
                    </a>
                </span>
                <br />
                <img
                    id='fidget-spinner'
                    src={"/sgs-loading.svg"}
                    alt='spin me!'
                    title="spinny~!"
                    className={"foot-logo printless"}
                    onMouseDown={this.clickSpin}
                    onMouseUp={this.clear}
                    onMouseOut={this.clear}
                    style={{animation: this.state.animation}}
                />
            </div>
        </div>   
    )}
}

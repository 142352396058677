class UniformGroup {
    constructor(diceString) {
        this.dieSize = 0;
        this.numberOfDice = 0;
        this.min = 0;
        this.max = 0;
        if(!diceString) return;
        const cleanPattern = /^(?<dice>\d+)d(?<size>\d+)$/gi;
        const match = cleanPattern.exec(diceString);
        cleanPattern.lastIndex = 0;
        // console.log('match', match);
        if(!!match && !match.groups.size) {
            console.log("replaced size")
            match.groups.size = 1;
        }
        if(!match || !match.groups.size || !match.groups.dice) {
            throw 'dice string must be in format XdY. ' + diceString;
        }
        let parsed = parseInt(match.groups.size);
        this.dieSize = parsed !== NaN ? parsed : match.groups.size;
        this.numberOfDice = parseInt(match.groups.dice);
        this.min = this.numberOfDice;
        this.max = this.numberOfDice * this.dieSize;
        this.med = (this.min + this.max) / 2;
    }
    plusGroup(dice) {
        if(!dice instanceof UniformGroup) {
            throw 'UniformGroup.plusGroup only takes another UniformGroup!';
        }
        if(this.dieSize !== dice.dieSize) {
            throw 'Die sizes of UniformGroup.plusGroup must match!';
        }
        let newGroup = new UniformGroup();
        newGroup.dieSize = this.dieSize;
        newGroup.numberOfDice = this.numberOfDice + dice.numberOfDice;
        return newGroup;
    }
    minusGroup(dice) {
        if(!dice instanceof UniformGroup) {
            throw 'UniformGroup.minusGroup only takes another UniformGroup!';
        }
        if(this.dieSize !== dice.dieSize) {
            throw 'Die sizes of UniformGroup.minusGroup must match!';
        }
        let newGroup = new UniformGroup();
        newGroup.dieSize = this.dieSize;
        newGroup.numberOfDice = this.numberOfDice - dice.numberOfDice;
        return newGroup;
    }
    isCompatableWith(dice) {
        if(!dice instanceof UniformGroup) {
            throw 'UniformGroup.isCompatableWith only takes another UniformGroup!';
        }
        if(this.dieSize === dice.dieSize) {
            return true;
        }
        return false;
    }
    timesNumber(number) {
        if(!number instanceof Number && typeof number !== 'number') {
            throw 'UniformGroup.timesNumber only takes a Number!';
        }
        let newUg = new UniformGroup();
        newUg.dieSize = this.dieSize;
        newUg.numberOfDice = this.numberOfDice * number;
        return newUg;
    }
    average() {
        let a = (this.dieSize + 1) / 2;
        return a * this.numberOfDice;
    }
    toString() {
        return this.numberOfDice + 'd' + this.dieSize;
    }
}

export default UniformGroup;